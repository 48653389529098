<rag-beta-toggle *ngIf="showBetaToggle"></rag-beta-toggle>
<mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>

<!--  Old navigation  -->
<rag-header *ngIf="!showNewNavigation && (viewModeDefault$ | async)"></rag-header>
<ng-container *ngIf="!showNewNavigation">
  <mat-sidenav-container id="sidenavContainer" (backdropClick)="toggleSideNav()" autosize>
    <mat-sidenav [autoFocus]="false" [opened]="sidenavVisible$ | async" mode="over">
      <nav rag-header-sidenav></nav>
    </mat-sidenav>
    <mat-sidenav-content class="app-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- <div class="blocker" [ngClass]="{'active': (loading$ | async)}"></div>-->
</ng-container>

<!--  New navigation  -->
<rag-header-v2 *ngIf="showNewNavigation && (viewModeDefault$ | async)"></rag-header-v2>
<rag-app-content *ngIf="showNewNavigation"></rag-app-content>

<rag-bot-dialog
  *ngIf="(botURL$ | async) != undefined"
></rag-bot-dialog>

<rag-footer *ngIf="viewModeDefault$ | async"></rag-footer>
