import {
  NavigationData,
  NavItemIcon,
  NavItemMenu,
  NavItemMenuIcon,
  NavItemSpecial,
  NavItemType,
} from './navigation.types';


export const DEFAULT_NAVIGATION_GUEST: NavigationData = {
  isGuest: true,
  showContentSearch: false,
  top: [
    {
      type: NavItemType.menuIcon,
      special: NavItemSpecial.language,
      dataCY: 'NavGuestTopLanguage',
    } as NavItemMenuIcon,
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_contact:Contact`,
      icon: 'face-agent',
      routerLink: '/contact',
      dataCY: 'NavGuestTopContact',
    } as NavItemIcon,
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@global_login:Login`,
      icon: 'login',
      special: NavItemSpecial.login,
      checkPermissions: 'isGuest',
      dataCY: 'NavGuestTopLogin',
    },
  ],
  main: [
    {
      type: NavItemType.link,
      title: $localize`:@@global_login:Login`,
      routerLink: '/login',
      special: NavItemSpecial.login,
      checkPermissions: 'isGuest',
      dataCY: 'NavGuestMainLogin',
    },
    {
      type: NavItemType.link,
      title: $localize`:@@global_catalog:Catalog`,
      routerLink: '/catalog',
      special: NavItemSpecial.catalog,
      dataCY: 'NavGuestMainCatalog',
    },
  ],
};

export const DEFAULT_NAVIGATION_USER: NavigationData = {
  isGuest: false,
  showContentSearch: true,
  top: [
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_messages:Messages`,
      icon: 'email-outline',
      checkPermissions: 'navUserMessages',
      routerLink: '/mailbox/inbox',
      additionalRoutes: [ '/mailbox' ],
      special: NavItemSpecial.mailbox,
      dataCY: 'NavUserTopMessages',
    },
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_contact:Contact`,
      icon: 'face-agent',
      routerLink: '/contact',
      dataCY: 'NavUserTopContact',
    },
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_system_check:System check`,
      icon: 'shield-check-outline',
      routerLink: '/tech-check',
      dataCY: 'NavUserTopSystemCheck',
    },
    {
      type: NavItemType.menuIcon,
      title: $localize`:@@header_top_profile:Profile`,
      icon: 'account-circle-outline',
      dataCY: 'NavUserTopProfileMenu',
      children: [
        {
          type: NavItemType.text,
          title: '{userFullName}',
          special: NavItemSpecial.profileUserName,
          dataCY: 'NavUserTopProfileMenuUsername',
        },
        {
          type: NavItemType.divider,
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_top_profile:Profile`,
          routerLink: '/my-profile',
          dataCY: 'NavUserTopProfileMenuProfile',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_transactions:Transactions`,
          routerLink: '/transactions',
          checkPermissions: 'navUserTransactions',
          dataCY: 'NavUserTopProfileMenuTransactions',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_top_gamification:Awards`,
          routerLink: '/awards',
          special: NavItemSpecial.gamification,
          dataCY: 'NavUserTopProfileMenuAwards',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@header_top_linked_users:Linked users`,
          checkPermissions: 'navUserLink',
          special: NavItemSpecial.profileLinkedUser,
          additionalRoutes: [ '/linked-users' ],
          dataCY: 'NavUserTopProfileMenuLinkedUsers',
        },
      ],
    } as NavItemMenuIcon,
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@logout_button:Logout`,
      icon: 'logout',
      special: NavItemSpecial.logout,
      dataCY: 'NavUserTopLogout',
    } as NavItemIcon,
  ],
  main: [

    {
      type: NavItemType.menuIcon,
      title: $localize`:@@header_nav_my_contents:My Contents`,
      special: NavItemSpecial.myTrainings,
      additionalRoutes: [ '/content-overview' ],
      icon: 'assets/icons/svg/icon_nav_mycontent.svg',
      dataCY: 'NavUserMainMyContents',
      children: [
        {
          type: NavItemType.link,
          title: $localize`:@@assignment_mandatory:Mandatory`,
          routerLink: '/content-overview',
          queryParams: { at: 'mandatory$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnMandatory',
          dataCY: 'NavUserMainMyContentsMandatory',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@assignment_voluntary:Voluntary`,
          routerLink: '/content-overview',
          queryParams: { at: 'voluntary$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnVoluntary',
          dataCY: 'NavUserMainMyContentsVoluntary',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_virtual_conference:Virtual event`,
          routerLink: '/content-overview',
          queryParams: { ct: 'Virtual Classroom$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnOfflineContent',
          dataCY: 'NavUserMainMyContentsVirtualEvent',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_seminar:On-site event`,
          routerLink: '/content-overview',
          queryParams: { ct: 'Face-to-Face event$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnOfflineContent',
          dataCY: 'NavUserMainMyContentsOfflineContent',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_student_certificates:My certificates`,
          routerLink: '/my-certificates',
          checkPermissions: 'navUserCertificates',
          dataCY: 'NavUserMainMyContentsUserCertificates',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_controlling_trainer_dashboard:Trainer dashboard`,
          routerLink: '/trainer',
          checkPermissions: 'navCtrlTrainerDashboard',
          dataCY: 'NavUserMainMyContentsTrainerDashboard',
        },
      ],
    } as NavItemMenuIcon,

    {
      type: NavItemType.menuIcon,
      title: $localize`:@@header_nav_administrator:Administration`,
      icon: 'assets/icons/svg/icon_nav_admin.svg',
      dataCY: 'NavUserMainAdministration',
      children: [

        {
          type: NavItemType.menu,
          title: $localize`:@@admin_offline_content_header:Events`,
          checkPermissions: 'navAdminOfflineContent',
          dataCY: 'NavUserMainAdministrationEvents',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_list_view:List view`,
              routerLink: '/admin/offline/content',
              routerLinkActiveOptions: { exact: true },
              dataCY: 'NavUserMainAdministrationEventsListView',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_calendar_view:Calendar View`,
              routerLink: '/admin/offline/content/cal/{date}/month',
              special: NavItemSpecial.adminOfflineCalendar,
              dataCY: 'NavUserMainAdministrationEventsCalenderView',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_recordings:Recordings`,
              routerLink: '/admin/offline/recordings',
              dataCY: 'NavUserMainAdministrationEventsRecordings',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_venues:Venues`,
              routerLink: '/admin/offline/location',
              dataCY: 'NavUserMainAdministrationEventsLocations',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_admin_offline_trainer:Trainer`,
              routerLink: '/admin/offline/trainer',
              dataCY: 'NavUserMainAdministrationEventsTrainer',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_provider:Provider`,
              routerLink: '/admin/offline/provider',
              dataCY: 'NavUserMainAdministrationEventsProvider',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_admin_offline_notification_templates:Notification templates`,
              routerLink: '/admin/offline/notifications',
              checkPermissions: 'navAdminOfflineNotification',
              dataCY: 'NavUserMainAdministrationEventsNotificationTemplates',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@categories:Categories`,
          routerLink: '/admin/categories',
          checkPermissions: 'navAdminCategories',
          dataCY: 'NavUserMainAdministrationCategories',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_catalogs:Catalogs`,
          routerLink: '/admin/catalog',
          checkPermissions: 'navAdminCatalog',
          dataCY: 'NavUserMainAdministrationCatalog',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_lu_header:Learning content`,
          routerLink: '/admin/courses',
          checkPermissions: 'navAdminCourse',
          dataCY: 'NavUserMainAdministrationLearningContent',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@ctrl_single_user_details_nav_curricula:Curricula`,
          checkPermissions: 'navAdminCurriculum',
          dataCY: 'NavUserMainAdministrationCurricula',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_list_view:List view`,
              routerLink: '/admin/curriculum',
              queryParams: { type: '' },
              routerLinkActiveOptions: {exact: true},
              dataCY: 'NavUserMainAdministrationCurriculaListView',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_cockpit_column_type_title:Online Agenda`,
              routerLink: '/admin/curriculum',
              queryParams: { type: 'a294578e-ab2f-4576-a106-0177600b67ad$eq' },
              routerLinkActiveOptions: {exact: true},
              dataCY: 'NavUserMainAdministrationCurriculaOnlineAgenda',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_learner_cockpit:Learner Cockpit`,
              routerLink: '/admin/curriculum',
              queryParams: { type: '174b90c1-5781-4c1e-a5dd-989a3618af4e$eq' },
              routerLinkActiveOptions: {exact: true},
              dataCY: 'NavUserMainAdministrationCurriculaLearnerCockpit',
            }
          ]
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_user_administration:User Administration`,
          dataCY: 'NavUserMainAdministrationUserAdministration',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_users:Users`,
              routerLink: '/admin/user',
              checkPermissions: 'navAdminUsers',
              dataCY: 'NavUserMainAdministrationUserAdministrationUsers',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_user_imports:User imports`,
              routerLink: '/admin/user-import',
              checkPermissions: 'navAdminUsersCSV',
              dataCY: 'NavUserMainAdministrationUserAdministrationUserImports',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_groups:User groups`,
              routerLink: '/admin/user-groups',
              checkPermissions: 'navAdminUserGroups',
              dataCY: 'NavUserMainAdministrationUserAdministrationUserGroups',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_target_groups:Target groups`,
              routerLink: '/admin/target-groups',
              checkPermissions: 'navAdminTargetGroups',
              dataCY: 'NavUserMainAdministrationUserAdministrationTargetGroups',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_self_registration:Self registration`,
              routerLink: '/admin/self-registration',
              checkPermissions: 'navAdminSelfRegistration',
              dataCY: 'NavUserMainAdministrationUserAdministrationSelfRegistration',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@limited_user_administration:Limited user administration`,
              routerLink: '/admin/reduced-attributes',
              checkPermissions: 'navAdminReducedUserAdministration',
              dataCY: 'NavUserMainAdministrationUserAdministrationLimitedUserAdministration',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_data_fields:User data fields`,
              routerLink: '/admin/user-attribute',
              checkPermissions: 'navAdminUserAttribute',
              dataCY: 'NavUserMainAdministrationUserAdministrationUserDataFields',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_learner_state:Learner state import`,
              routerLink: '/admin/learner-state',
              checkPermissions: 'navAdminLearnerStateImport',
              hideForIlearn: 'apply',
              dataCY: 'NavUserMainAdministrationUserAdministrationLearnerStateImport',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_text_macros:Text macros`,
          routerLink: '/admin/textMacros',
          checkPermissions: 'navAdminTextMacros',
          dataCY: 'NavUserMainAdministrationTextMacros',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_news:News`,
          routerLink: '/admin/news',
          checkPermissions: 'navAdminNews',
          dataCY: 'NavUserMainAdministrationNews',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_content_content_notifications:Notifications`,
          dataCY: 'NavUserMainAdministrationNotifications',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_top_messages:Messages`,
              routerLink: '/admin/notifications/message',
              checkPermissions: 'navAdminNotifications',
              dataCY: 'NavUserMainAdministrationNotificationsMessages',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@general_signatures:Signatures`,
              routerLink: '/admin/notifications/signatures',
              checkPermissions: 'navAdminSignatures',
              dataCY: 'NavUserMainAdministrationNotificationsSignatures',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_url_call:URL-Call`,
              routerLink: '/admin/notifications/urlCall',
              checkPermissions: 'navAdminNotifications',
              dataCY: 'NavUserMainAdministrationNotificationsURLCall',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_qa:Q&A`,
          routerLink: '/admin/qa',
          checkPermissions: 'navAdminQA',
          dataCY: 'NavUserMainAdministrationQA',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@global_integration:Integration`,
          dataCY: 'NavUserMainAdministrationIntegration',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_login:Virtual platforms`,
              routerLink: '/admin/ext/login',
              checkPermissions: 'navAdminExternalLogin',
              dataCY: 'NavUserMainAdministrationIntegrationVirtualPlatforms',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_oidc:OpenID Connect`,
              routerLink: '/admin/ext/oauth2',
              checkPermissions: 'navAdminExternalOAuth2',
              dataCY: 'NavUserMainAdministrationIntegrationOpenIDConnect',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_dsb_gateway_settings:DSB-Gateway Settings`,
              routerLink: '/admin/ext/dsbGateway',
              checkPermissions: 'navAdminExternalDSBGateway',
              dataCY: 'NavUserMainAdministrationIntegrationDSBGatewaySettings',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_script_account_script:Account-wide JavaScript`,
              routerLink: '/admin/script',
              checkPermissions: 'navAdminJavascript',
              dataCY: 'NavUserMainAdministrationIntegrationAccountWideJavaScript',
            },
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@header_nav_development-goals:Development goals`,
          dataCY: 'NavUserMainAdministrationDevelopmentGoals',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_development_goals_distribution:Distribution`,
              routerLink: '/admin/development-goals/licence-group/assignment',
              checkPermissions: 'navAdminDistributionLimited',
              dataCY: 'NavUserMainAdministrationDevelopmentGoalsDistribution',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@nav_central_assignment:Central assignment`,
              routerLink: '/admin/dev',
              checkPermissions: 'navAdminDevGoals',
              dataCY: 'NavUserMainAdministrationDevelopmentGoalsCentralAssignment',
            }
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_permissions:Permissions`,
          dataCY: 'NavUserMainAdministrationPermissions',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_roles:Roles`,
              routerLink: '/admin/roles',
              checkPermissions: 'navAdminRoles',
              dataCY: 'NavUserMainAdministrationPermissionsRoles',
            }
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@account_design:Account design`,
          dataCY: 'NavUserMainAdministrationAccountDesign',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_general:General`,
              routerLink: '/admin/account-design/general',
              checkPermissions: 'navAdminAccountDesign',
              dataCY: 'NavUserMainAdministrationAccountDesignGeneral',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_login:Login`,
              routerLink: '/admin/account-design/login',
              checkPermissions: 'navAdminAccountDesignLogin',
              dataCY: 'NavUserMainAdministrationAccountDesignLogin',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_footer:Footer`,
              routerLink: '/admin/account-design/footer',
              checkPermissions: 'navAdminAccountDesign',
              dataCY: 'NavUserMainAdministrationAccountDesignFooter',
            },
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@global_configurations:Configurations`,
          dataCY: 'NavUserMainAdministrationConfigurations',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_widgets:Widgets`,
              routerLink: '/admin/configuration/widgets',
              checkPermissions: 'navAdminAccountDesign',
              dataCY: 'NavUserMainAdministrationConfigurationsWidgets',
            },
            {
              type: NavItemType.link,
              title: 'Gamification',
              routerLink: '/admin/configuration/gamification',
              checkPermissions: 'navAdminGamification',
              dataCY: 'NavUserMainAdministrationConfigurationsGamification',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_report_generator:Report generator`,
              routerLink: '/admin/configuration/report-gen-admin',
              checkPermissions: 'navAdminReportGenerator',
              dataCY: 'NavUserMainAdministrationConfigurationsReportGenerator',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@landing_page:Landing page`,
              routerLink: '/admin/configuration/start-page',
              checkPermissions: 'navAdminAccountDesign',
              dataCY: 'NavUserMainAdministrationConfigurationsLandingPage',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@profile_export:Profile export`,
              routerLink: '/admin/configuration/profile',
              checkPermissions: 'navAdminAccountDesign',
              dataCY: 'NavUserMainAdministrationConfigurationsProfileExport',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_permission_concept:Permission concept`,
              routerLink: '/admin/configuration/permission-concept',
              checkPermissions: 'navAdminRoles',
              dataCY: 'NavUserMainAdministrationConfigurationsPermissionExport',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_bots:Bots`,
              routerLink: '/admin/configuration/bots',
              checkPermissions: 'navAdminBots',
              dataCY: 'NavUserMainAdministrationConfigurationsBots',
            }
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_action_codes:Action Codes`,
          routerLink: '/admin/ac',
          checkPermissions: 'adminActionCodesReduced',
          dataCY: 'NavUserMainAdministrationActionCodes',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_handbook:Handbook`,
          dataCY: 'NavUserMainAdministrationHandbookMenu',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook:Handbook`,
              routerLink: '/admin/handbook',
              routerLinkActiveOptions: { exact: true },
              checkPermissions: 'navAdminHandbook',
              dataCY: 'NavUserMainAdministrationHandbookMenuHandbook',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook_interactive:Interactive (experimental)`,
              routerLink: '/admin/handbook/interactive',
              checkPermissions: 'navAdminHandbook',
              dataCY: 'NavUserMainAdministrationHandbookMenuInteractive',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook_settings:Settings`,
              routerLink: '/admin/handbook/settings',
              checkPermissions: 'navAdminHandbookSettings',
              dataCY: 'NavUserMainAdministrationHandbookMenuSettings',
            },
          ],
        },

      ],
    } as NavItemMenuIcon,

    {
      type: NavItemType.menuIcon,
      title: $localize`:@@header_nav_controlling:Controlling`,
      icon: 'assets/icons/svg/icon_nav_controlling.svg',
      dataCY: 'NavUserMainControlling',
      children: [
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_controlling_dashboard:Dashboard`,
          routerLink: '/report/dashboard',
          checkPermissions: 'navCtrlReportDashboard',
          dataCY: 'NavUserMainControllingDashboard',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@header_nav_report_generator:Report generator`,
          dataCY: 'NavUserMainControllingReportGenerator',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_curriculum:Curriculum`,
              routerLink: '/report/v2/generator/Curriculum',
              checkPermissions: 'navCtrlReportCurriculum',
              dataCY: 'NavUserMainControllingReportGeneratorCurriculum',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_curriculum_selected:Selected curricula`,
              routerLink: '/report/v2/generator/Curriculum/select',
              checkPermissions: 'navCtrlReportCurriculum',
              dataCY: 'NavUserMainControllingReportGeneratorSelectedCurricula',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_course:Course`,
              routerLink: '/report/v2/generator/Course',
              checkPermissions: 'navCtrlReportCourse',
              dataCY: 'NavUserMainControllingReportGeneratorCourse',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_event:Event`,
              routerLink: '/report/v2/generator/Offline',
              checkPermissions: 'navCtrlReportOffline',
              dataCY: 'NavUserMainControllingReportGeneratorEvent',
            },
          ],
        } as NavItemMenu,
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_controlling_report_mine:My reports`,
          routerLink: '/report/v2/saved',
          checkPermissions: 'navCtrlReportSaved',
          dataCY: 'NavUserMainControllingMyReports',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_single_user:Single user`,
          routerLink: '/ctrl/user',
          checkPermissions: 'navCtrlSingleUser',
          dataCY: 'NavUserMainControllingSingleUser',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_feedback_dashboard:Feedback dashboard`,
          routerLink: '/ctrl/quest',
          checkPermissions: 'navSuperiorFeedback',
          dataCY: 'NavUserMainControllingFeedbackDashboard',
        },
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_lists:List view`,
          routerLink: '/report/single-lists',
          checkPermissions: 'navCtrlSingleLists',
          dataCY: 'NavUserMainControllingSingleLists',
        },*/
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_zuep:Background check`,
          routerLink: '/report/zuep',
          checkPermissions: 'navCtrlZuep',
          dataCY: 'NavUserMainControllingZuep',
          external: true,
        },*/
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_fad:Officials' dashboards`,
          routerLink: '/report/fad',
          checkPermissions: 'navCtrlFad',
          dataCY: 'NavUserMainControllingFad',
          external: true,
        },*/
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_furtherboards:Further boards`,
          routerLink: '/report/furtherboards',
          checkPermissions: 'navCtrlFurtherBoards',
          dataCY: 'NavUserMainControllingFurtherBoards',
          external: true,
        },*/
      ],
    } as NavItemMenuIcon,

    {
      type: NavItemType.linkIcon,
      title: $localize`:@@global_catalog:Catalog`,
      routerLink: '/catalog',
      checkPermissions: 'navCatalog',
      icon: 'assets/icons/svg/icon_nav_catalog.svg',
      dataCY: 'NavUserMainCatalog',
    } as NavItemIcon,
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@global_qa:Q&A`,
      routerLink: '/qa',
      checkPermissions: 'navQA',
      icon: 'assets/icons/svg/icon_nav_qa.svg',
      dataCY: 'NavUserMainQA',
    } as NavItemIcon,

    {
      type: NavItemType.linkIcon,
      title: $localize`:@@switch_to_classic_frontend:Switch to classic view`,
      url: '{classicApi}/navigationAction.do?dispatch=navigateTo&navigationTarget=home&useClassic=true&headless=false',
      checkPermissions: 'navClassic',
      external: true,
      icon: 'assets/icons/svg/icon_nav_classic.svg',
      dataCY: 'NavUserMainSwitchToClassic',
    } as NavItemIcon,
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@global_doclib_link:Media library`,
      routerLink: '/library',
      checkPermissions: 'navDocLib',
      external: true,
      icon: 'assets/icons/svg/icon_nav_library.svg',
      dataCY: 'NavUserMainMediaLibrary',
    } as NavItemIcon,
    {
      type: NavItemType.menuIcon,
      title: $localize`:@@global_help:Help`,
      icon: 'assets/icons/svg/icon_nav_help.svg',
      dataCY: 'NavUserMainHelp',
      children: [
        {
          type: NavItemType.link,
          title: $localize`:@@header_top_contact:Contact`,
          icon: 'face-agent',
          routerLink: '/contact',
          dataCY: 'NavUserMainHelpContact',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_top_system_check:System check`,
          icon: 'shield-check-outline',
          routerLink: '/tech-check',
          dataCY: 'NavUserMainHelpSystemCheck',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@syllabi:Syllabi`,
          routerLink: '/admin/custom',
          routerLinkActiveOptions: { exact: true },
          checkPermissions: 'navAdminWacSyllabiRead',
          dataCY: 'NavUserMainHelpSyllabi',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_handbook:Handbook`,
          dataCY: 'NavUserMainHelpHandbookMenu',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook:Handbook`,
              routerLink: '/admin/handbook',
              routerLinkActiveOptions: { exact: true },
              checkPermissions: 'navAdminHandbookRead',
              dataCY: 'NavUserMainHelpHandbookMenuHandbook',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook_interactive:Interactive (experimental)`,
              routerLink: '/admin/handbook/interactive',
              checkPermissions: 'navAdminHandbookRead',
              dataCY: 'NavUserMainHelpHandbookMenuInteractive',
            },
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_apidocs:API Documentation`,
          checkPermissions: 'navApiDocs',
          dataCY: 'NavUserMainHelpAPIDocumentation',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@admin_apidocs_classic:Classic`,
              routerLink: '/admin/api/classic',
              routerLinkActiveOptions: { exact: true },
              dataCY: 'NavUserMainHelpDocumentationClassic',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_apidocs_lms:LMS API`,
              routerLink: '/admin/api/lms',
              routerLinkActiveOptions: { exact: true },
              dataCY: 'NavUserMainHelpDocumentationLMSAPI',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@syllabi:Syllabi`,
              routerLink: '/admin/api/syllabi',
              routerLinkActiveOptions: { exact: true },
              checkPermissions: 'navApiDocsCustom',
              dataCY: 'NavUserMainHelpDocumentationSyllabi',
            },
          ],
        }

      ]
    } as NavItemIcon,
    {
      type: NavItemType.text,
      title: '{login}',
      special: NavItemSpecial.loginName,
      dataCY: 'NavUserMainLoginName',
    },

  ],
};
