import { Core, ImageableContentReference } from '../../core/core.types';
import { DisplayStatusHelper } from '../../core/display-status-helper';
import moment from 'moment';
import { DateHelper } from '../../core/date.helper';
import { MyTrainingsChildCourseDTO, MyTrainingsParentCurriculumDTO } from '../../core/content/my-trainings-v4.types';
import { CourseTypeHelper } from '../../core/course-type-helper';
import { CmiLessonStatusHelper } from '../../core/cmi-lesson-status.helper';
import { ContentService } from '../../core/content/content.service';
import { DisplayStatus } from '../../core/display-status.enum';


export interface ImportantContentInfo {
  date: moment.Moment;
  label: string;
  isWarning: boolean;
}

export class ContentCardHelper {

  static getContentCardCourseTooltip(content: unknown): string | null {
    const cnt = content as MyTrainingsChildCourseDTO;
    if ((cnt?.courseType == null) || (cnt.objType !== Core.DistributableType.lms_course)) {
      return null;
    }

    const contentReference = content as ImageableContentReference;
    if (Object.prototype.hasOwnProperty.call(contentReference, 'tooltip')) {
      return contentReference.tooltip;
    }

    const result: string[] = [];
    const courseTypeLabel = CourseTypeHelper.getTypeLabel(contentReference);
    if (courseTypeLabel) {
      result.push($localize`:@@global_type:Type` + ': ' + courseTypeLabel);
    }

    const displayStatus = DisplayStatusHelper.toDisplayStatus(cnt.displaystatus);
    const lessonStatus = CmiLessonStatusHelper.fromDisplayStatus(displayStatus);
    const statusLabel = CmiLessonStatusHelper.getStatusLabel(lessonStatus);
    if (statusLabel) {
      result.push($localize`:@@global_status:Status` + ': ' + statusLabel);
    }

    ContentService.getMinutes(contentReference);
    const duration = ContentService.calculateDuration(contentReference);
    if (duration) {
      result.push($localize`:@@global_duration:Duration` + ': ' + duration);
    }

    return (contentReference.tooltip = result.join('\n\n'));
  }

  static getCurriculumValidUntil(content: MyTrainingsParentCurriculumDTO): ImportantContentInfo | null {
    if (!(content.lastValidUntil > 0)) {
      return null;
    }

    const oneYearInMillis = 365 * 24 * 60 * 60 * 1000;
    const today = new Date();
    const lastValidDate = new Date(content.lastValidUntil);
    if ((today.getTime() - lastValidDate.getTime()) > oneYearInMillis) {
      // @see TF-795 do not show info about items that have expired for longer than a year
      return null;
    }

    const displayStatus = DisplayStatusHelper.toDisplayStatus(content.displaystatus);
    if (displayStatus === DisplayStatus.NOT_CERTIFIED) {
      // validity expired and no more attempts allowed
      return ContentCardHelper.asInfo(
        content.lastValidUntil, $localize`:@@content_card_curr_expired:Expired:`, true
      );
    }

    const label = (displayStatus === DisplayStatus.RECERTIFICATION_TIME) ?
      $localize`:@@content_card_curr_due_by:Due by:` : $localize`:@@important_content_info_valid_until:Valid until:`;
    return ContentCardHelper.asInfo(
      content.lastValidUntil, label, ContentCardHelper.isLessThanXDays(content.lastValidUntil),
    );
  }

  static getImportantContentInfo(
    content: ImageableContentReference,
  ): ImportantContentInfo | null {

    if (content.objType === Core.DistributableType.lms_offlineCnt) {
      return ContentCardHelper.asInfo(
        content.eventDate, $localize`:@@content_card_overview_scheduled_at:Scheduled at:`,
      );
    }

    if (content.objType === Core.DistributableType.lms_curriculum) {
      if (content.viewType === 'a294578e-ab2f-4576-a106-0177600b67ad') {
        return ContentCardHelper.asInfo(
          content.agendaDate, $localize`:@@content_card_overview_scheduled_at:Scheduled at:`,
        );
      }

      if (DisplayStatusHelper.isStatusRecert(content.displaystatus)) {
        return ContentCardHelper.asInfo(
          content.startdate, $localize`:@@content_card_overview_startdate:Start date:`,
        );
      }

      if (content.dueBy) {
        //TF-10456
        let warningDate = content.dueBy || content.lastValidUntil;
        const info = ContentCardHelper.asInfo(
          content.dueBy, $localize`:@@content_card_overview_due_by:Expiration of validity:`,
          ContentCardHelper.isLessThanXDays(warningDate),
        );
        if (warningDate >= new Date().getTime()) {
          console?.log('adding expiration warning', content.objType, ':', content.id);
          return info;
        }
        console?.log('skipping expiration warning', content.objType, ':', content.id);
        return null;
      }

      if (content.lastValidSince && DisplayStatusHelper.isStatusGreen(content.displaystatus)) {
        const info = ContentCardHelper.asInfo(
          content.lastValidSince, $localize`:@@content_card_overview_startdate:Start of validity:`
        );
        return info;
      }

      if (content.startdate) {
        return ContentCardHelper.asInfo(
          content.startdate, $localize`:@@content_card_overview_available_since:Available Since:`,
        );
      }

    }
    return null;
  }

  static asInfo(date: number, label: string, isWarning?: boolean): ImportantContentInfo | null {
    if (!(date > 0)) {
      return null;
    }

    return { date: DateHelper.toMoment(date), label, isWarning: (isWarning === true) };
  }

  static isLessThanXDays(date: number, futureDays: number = 30): boolean {
    if (!(date > 0)) {
      return false;
    }

    const warningDate = new Date();
    warningDate.setDate(warningDate.getDate() + futureDays);
    const lastValid = new Date(date);
    return (warningDate.getTime() >= lastValid.getTime());
  }

}
