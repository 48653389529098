import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { DateOffsetComponent } from 'src/app/component/date-offset/date-offset.component';
import { LocationComponent } from 'src/app/component/location/location.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ComponentsModule } from 'src/app/route/admin/externals/ext-logins/components/components.module';
import { CatalogBookingTableComponent } from './catalog-booking-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [ CatalogBookingTableComponent ],
  imports: [
    CoreModule,
    MatDialogModule,
    PipesModule,
    PageHeaderComponent,
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    LocationComponent,
    DateOffsetComponent,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTabsModule,
    ComponentsModule,
  ],
  exports: [
    CatalogBookingTableComponent,
  ],
})
export class CatalogBookingTableModule {
}
