<div
  class="row table_space"
  *ngFor="let offlineContent of bookableOfflineContents; let i = index"
>
  <div *ngIf="shouldDisplayHeader(offlineContent)" class="title">
    <h4>{{offlineContent.title | localeText}}</h4>
    <div class="modules">
      <div>
        <span i18n="@@select_module">modules</span>:
      </div>

      <mat-tab-group animationDuration="0ms"
        [selectedIndex]="getSelectedTabIndex(offlineContent)"
        (selectedTabChange)="setSelectedModule(offlineContent, $event)">
        <mat-tab
          *ngFor="let module of getModulesHavingEvents(offlineContent)"
          [label]="module.adminTitle"></mat-tab>
      </mat-tab-group>
    </div>
    <div class="block-price" *ngIf="shouldDisplayPriceForBLockEvent(offlineContent)">
      <span>{{offlineContent.priceForUserInCent / 100.0 | currency: offlineContent.priceCurrency}}</span>
    </div>
  </div>

  <div
    *ngIf="dataSources[i].data.length > 0 && getDisplayColumns(offlineContent) as displayColumns; else nothingToSelectFrom"
    class="table-wrapper"
  >
    <table mat-table [dataSource]="dataSources[i]" class="no-hover">

      <!-- Checkbox -->
      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            *ngIf="isCheckBoxVisible(offlineContent)"
            (change)="onSelectAllChange($event, offlineContent)"
            [checked]="isAllChecked(offlineContent)"
            [disabled]="offlineContent.blockEvent || isSelectAllDisabled(offlineContent)"
            [indeterminate]="isSelectAllIndeterminate(offlineContent)"
          ></mat-checkbox>
        </th>
        <td *matCellDef="let eventScheduleView" mat-cell>
          <mat-checkbox
            *ngIf="isCheckBoxVisible(offlineContent)"
            (change)="onSelectedChange($event, offlineContent, eventScheduleView)"
            [checked]="isEventChecked(offlineContent, eventScheduleView)"
            [disabled]="offlineContent.blockEvent || isEventScheduleDisabled(offlineContent, eventScheduleView)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <!-- start date -->
      <ng-container matColumnDef="startDate">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_start_date">Start date</span>
        </th>
        <td *matCellDef="let eventScheduleView" mat-cell>
          <rag-date-offset [date]="eventScheduleView.eventDate"></rag-date-offset>
        </td>
      </ng-container>

      <!-- end date -->
      <ng-container matColumnDef="endDatum">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_end_date">End date</span>
        </th>
        <td *matCellDef="let eventScheduleView" mat-cell>
          <rag-date-offset [date]="eventScheduleView.eventDateUntil"></rag-date-offset>
        </td>
      </ng-container>

      <!-- title -->
      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_title">Title</span>
        </th>
        <td *matCellDef="let eventScheduleView" mat-cell>
          {{eventScheduleView.title | localeText}}
        </td>
      </ng-container>

      <!-- trainers -->
      <ng-container matColumnDef="trainers">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_trainers">Trainers</span>
        </th>
        <td *matCellDef="let eventScheduleView" mat-cell>

          <ng-template #noTrainers>
            <span class="empty_data_trainer">-</span>
          </ng-template>

          <div *ngIf="hasTrainers(eventScheduleView); else noTrainers">
            <div *ngFor="let trainer of eventScheduleView.trainers" class="trainer">
              {{(trainer.academicTitle === '-' || trainer.academicTitle == null) ? '' : trainer.academicTitle}}
              {{trainer.firstName}} {{trainer.lastName}}
            </div>
          </div>

        </td>
      </ng-container>

      <!-- location -->
      <ng-container matColumnDef="location">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_location">Location</span>
        </th>

        <td *matCellDef="let eventView" mat-cell>

          <ng-template #noLocation>
            <span>-</span>
          </ng-template>

          <rag-location
            *ngIf="eventView.location ; else noLocation"
            [location]="eventView.location"
          ></rag-location>
        </td>
      </ng-container>

      <!-- ext-login -->
      <ng-container matColumnDef="ext-login">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_ext_login">Virtual platform</span>
        </th>
        <td *matCellDef="let eventView" mat-cell>

          <ng-template #noVirtualPlatform>
            <span>-</span>
          </ng-template>

          <span *ngIf="eventView.extLogin  ; else noVirtualPlatform">
            {{eventView.extLogin.serverName}}
          </span>
        </td>
      </ng-container>

      <!-- notices -->
      <ng-container matColumnDef="notices">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_description">Description</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          <div class="center">
            <button
              *ngIf="hasInfo(eventSchedule)"
              (click)="showInfo(eventSchedule)"
              mat-icon-button>

              <mat-icon svgIcon="information-outline"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <!-- free places -->
      <ng-container matColumnDef="freePlaces">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_free_places">Free places</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell class="center"
            [ngClass]="getColorOfFreePlaces(eventSchedule)"
        >
          {{getFreePlacesPlaceholder(eventSchedule)}}
        </td>
      </ng-container>

      <!-- price -->
      <ng-container matColumnDef="price">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_price">Price</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell class="right">
          <ng-container
            *ngIf="eventSchedule.priceValue > 0"
          >{{eventSchedule.priceValue / 100.0 | currency:eventSchedule.priceCurrency}}</ng-container>
          <ng-container
            *ngIf="!(eventSchedule.priceValue > 0)"
          >
            <span *ngIf="!offlineContent.blockEvent" i18n="@@global_price_free">free</span>
            <span *ngIf="offlineContent.blockEvent" i18n="@@global_price_included">included</span>
          </ng-container>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayColumns;" mat-row [class.disabled]="isEventScheduleDisabled(offlineContent, row)"></tr>

    </table>
  </div>
</div>

<ng-template #nothingToSelectFrom>
  <div class="nothing-to-select-from">
    <div i18n="@@learner_offline_no_events">There are currently no upcoming events defined.</div>
  </div>
</ng-template>