import { NgModule } from '@angular/core';
import { CatalogCurriculumCardComponent } from './catalog-curriculum-card/catalog-curriculum-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { CurriculumCardComponent } from './curriculum-card/curriculum-card.component';
import { ContentActionModule } from '../../elearning/content-action/content-action.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { CardStatusIconComponent } from '../card-status-icon/card-status-icon.component';

@NgModule({
  imports: [
    MatCardModule,
    MatIconModule,
    NgIf,
    PipesModule,
    AsyncPipe,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
    ContentActionModule,
    MatTooltipModule,
    RouterLink,
    CardStatusIconComponent,
  ],
  declarations: [
    CatalogCurriculumCardComponent,
    CurriculumCardComponent,
  ],
  exports: [
    CatalogCurriculumCardComponent,
    CurriculumCardComponent,
  ]
})

export class CurriculumCardModule {
}
