<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@badge_templates">Badge Templates</span>
  </div>

  <div
    class="page-header-buttons"
  >
    <a
      color="primary"
      mat-flat-button
      routerLink="/admin/configuration/gamification/edit/0"
    >
      <span i18n="@@general_create">Create</span>
    </a>
  </div>
</rag-page-header>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="admin-gamification-list-columns">
</rag-table-column-menu>

<mat-menu #badgeTemplateActionsMenu xPosition="before">
  <ng-template let-badgeTemplate="badgeTemplate" matMenuContent>
    <button
      mat-menu-item
      (click)="onEditBadgeTemplate(badgeTemplate.templateUUID)"
    >
      <mat-icon svgIcon="pencil-outline"></mat-icon>
      <span i18n="@@global_edit">Edit</span>
    </button>

    <mat-divider></mat-divider>

    <button
      mat-menu-item
      (click)="onDeleteBadgeTemplate(badgeTemplate.templateUUID)"
    >
      <mat-icon color="warn" svgIcon="delete-outline"></mat-icon>
      <span i18n="@@global_delete">Delete</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<rag-table-filter
  (filterChange)="onFilterChange($event.filter, $event.column)"
  [menuData]="columnMenuData"
></rag-table-filter>

<ng-container
  *ngIf="isDataLoaded; else tplDataLoading">
  <rag-sticky-scroll [recalculate]="recalculateSticky$">
    <ng-container
      *ngIf="isPaginatorSet && isColumnContextLoaded; else tplDataLoading">
      <table
        *ngIf="!isDataEmpty; else tplDataEmpty"
        [dataSource]="dataSource"
        mat-table
        matSort>

        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              [column]="column"
              [row]="row"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button>
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let badgeTemplate" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled"
              [matMenuTriggerData]="{badgeTemplate: badgeTemplate}"
              [matMenuTriggerFor]="badgeTemplateActionsMenu"
              mat-icon-button>
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div *ngIf="isFilteredEmpty" class="no-data">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>
  <div [ngClass]="{paginatorInVisibility: isFilteredEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>
