<div
  [formGroup]="form"
>

  <mat-label>
    <mat-icon
      *ngIf="field.setByInterface === true"
      class="special-required"
      svgIcon="elevator"
    ></mat-icon>
    {{field.label | localeText}}
  </mat-label>

  <mat-radio-group
    [formControlName]="field.fieldId"
    [disabled]="isDisabled()"
  >
    <mat-radio-button
      *ngIf="!field.required"
      [value]="''"
      data-cy="profileFieldRadioEmpty"
    > - <span i18n="@@global_none_selected">None selected</span> - </mat-radio-button>

    <mat-radio-button
      *ngFor="let option of field.options"
      [value]="option.key"
      [attr.data-cy]="'profileFieldRadio'+'-'+option.key"
    >{{option.value}}</mat-radio-button>

    <mat-error
      *ngIf="getError() as error"
    >{{error}}</mat-error>

  </mat-radio-group>

</div>
