import { CardStatusIcon } from './card-status-icon.types';
import {
  MyTrainingsParentCourseDTO,
  MyTrainingsParentCurriculumDTO
} from '../../../core/content/my-trainings-v4.types';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { DisplayStatus } from '../../../core/display-status.enum';


export class CardStatusIconHelper {

  public static getCourseStatusIcon(content: unknown): CardStatusIcon | null {
    const course = content as MyTrainingsParentCourseDTO;
    if (DisplayStatusHelper.isStatusGreen(course.displaystatus)) {
      return {
        src: 'assets/icons/svg/Controls/correct.svg',
        label: $localize`:@@completed:Completed`,
        cssClass: 'status-completed',
      };
    }
    if (course.locked) {
      return {
        svgIcon: 'lock',
        label: $localize`:@@global_locked_filter_true:Locked`,
        cssClass: 'status-locked',
      };
    }
    return null;
  }

  public static getCurriculumStatusIcon(content: unknown): CardStatusIcon | null {
    const curriculum = content as MyTrainingsParentCurriculumDTO;
    const displayStatus = DisplayStatusHelper.toDisplayStatus(curriculum.displaystatus);
    if (displayStatus === DisplayStatus.CERTIFIED) {
      return {
        src: 'assets/icons/svg/Controls/correct.svg',
        label: $localize`:@@status_light_tooltip_completeCourse:Completed`,
        cssClass: 'status-completed',
      };
    }
    if (curriculum.locked) {
      return {
        svgIcon: 'lock',
        label: $localize`:@@global_locked_filter_true:Locked`,
        cssClass: 'status-locked',
      };
    }
    if (displayStatus === DisplayStatus.RECERTIFICATION_TIME) {
      return {
        svgIcon: 'calendar-sync-outline',
        label: $localize`:@@status_light_tooltip_recertificationTime:Recertification`,
        cssClass: 'status-recertification',
      };
    }
    if (displayStatus === DisplayStatus.NOT_CERTIFIED) {
      return {
        svgIcon: 'clock-remove-outline',
        label: $localize`:@@certificate_Expired:Expired`,
        cssClass: 'status-expired',
      };
    }
  }

}
