import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, delay, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { SelfRegisterInfoResponse } from './self-register-form.types';
import { UserProfileHelper } from '../../../user/user-profile/user-profile.helper';
import { ProfileSectionTypes } from '../../../../component/input/profile-section/profile-section.types';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfileAttribute, UserProfileResponse } from '../../../user/user-profile/user-profile.types';
import {
  InfoType,
  MessageConstants,
  YesButton,
  YesNoButtons,
} from '../../../../core/info/info.types';
import { InfoService } from '../../../../core/info/info.service';
import { RuntimeEnvironmentService } from '../../../../core/runtime-environment.service';
import { RegisterDataPreviewComponent } from '../register-data-preview/register-data-preview.component';
import { RegisterDataPreviewData } from '../register-data-preview/register-data-preview.types';
import { EMPTY, throwError } from 'rxjs';
import { RedirectHelper } from '../../../../core/redirect.helper';
import { RegistrationService } from '../../../../core/principal/registration.service';
import { LoginService } from '../../../../core/principal/login.service';
import { SelfRegisterFormHelper } from './self-register-form.helper';


@Component({
  selector: 'rag-self-register-form',
  templateUrl: './self-register-form.component.html',
  styleUrls: [ './self-register-form.component.scss' ],
})
export class SelfRegisterFormComponent
  implements OnDestroy, OnInit {

  formGroup: FormGroup | null;
  info: SelfRegisterInfoResponse | null;
  sections: ProfileSectionTypes.ProfileSection[];
  private _dataLoading = false;
  private _hasILearnHint = false;
  private _requestIsSent = false;
  private _sortedAttributes: UserProfileAttribute[];

  constructor(
    private infoService: InfoService,
    private loginService: LoginService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
    ) {
      this.runtimeEnvironmentService.environment$
      .pipe(tap(env => this._hasILearnHint = env.trainApi.includes('/ilearn24')))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
    }

  ngOnInit(): void {
    this.route.data
      .pipe(tap(data => this.updateRouteData(data.infoV2)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  getHintTooltip(): string | null {

    if ( !this._hasILearnHint ) {
      return null;
    }

    return $localize`:@@registration_title:Welcome to iLearn24, the learning management system of wingsacademy. You can
    create your personal account by completing and submitting the form below.

    We will then send an email to the address you have provided. Please click on the link in the email to verify your
    email address. Once your address has been verified, you will receive an email confirming that your account on
    iLearn24 has been activated.

    Then you can log in on the start page.`;
  }

  getTemplate(
    tplLoading: TemplateRef<any>,
    tplRegistrationForm: TemplateRef<any>,
    tplRequestSent: TemplateRef<any>,
  ): TemplateRef<any> {

    if ( this._dataLoading ) {
      return tplLoading;
    }

    if ( this._requestIsSent ) {
      return tplRequestSent;
    }

    return tplRegistrationForm;
  }

  hasTermsAndConditions(): boolean {
    return !!this.info?.agreementUrl && !!this.info.privacyUrl;
  }

  onCancel(): void {
    // ask for confirmation if there is at least one dirty component
    if ( !this.formGroup.dirty ) {
      // if canceled, navigate to home
      this.router.navigateByUrl('/home').then();
      return;
    }

    this.infoService.showMessage($localize`:@@validation_leave_without_save_registration:Would you like to leave the page without submitting a registration request?`, {
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
      buttons: YesNoButtons,
    })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(_ => this.router.navigateByUrl('/')))
      .pipe(take(1))
      .subscribe();
  }

  onSubmit($event?: MouseEvent): void {
    $event?.preventDefault();

    const formGroup = this.formGroup;
    formGroup?.markAsTouched();
    if ( (formGroup == null) || formGroup.pristine || formGroup.invalid ) {
      return;
    }

    this._requestIsSent = false;

    const attributes = SelfRegisterFormHelper
      .attributesFromForm(this._sortedAttributes, this.info?.extLoginField, formGroup);
    const requestBody = SelfRegisterFormHelper
      .requestBodyFromAttributes(attributes, this.info.process?.hash);
    const previewData = SelfRegisterFormHelper.previewDataFromAttributes(attributes);

    this.infoService
      .showDialog<RegisterDataPreviewComponent, RegisterDataPreviewData, boolean>(RegisterDataPreviewComponent, {
        data: previewData,
        model: attributes,
      })
      .pipe(takeWhile(confirmed => confirmed === true))
      .pipe(switchMap(() => this.registrationService.registerUser(requestBody)))
      .pipe(takeWhile(success => success))
      .pipe(switchMap(() => {

        const process = this.info.process;
        if ( process?.type !== 'doubleOptIn' ) {
          this._requestIsSent = true;
          return EMPTY;
        }

        this._dataLoading = true;
        // users are activated directly -> login automatically
        const login: string = requestBody['username'];
        const password: string = requestBody['password'];

        // store process as post-login redirect
        return RedirectHelper.setRedirect('/process/' + process.hash)

          // wait 2 seconds until the registration in train completes (hopefully)
          .pipe(delay(2000))

          // do a login (with the new credentials)
          .pipe(switchMap(_ => this.loginService.login(null, login, password)))

          .pipe(tap(() => window.location.reload()))
          .pipe(map(() => true));
      }))
      .pipe(catchError(error => {
        const trainError = error.error == null ? error : error.error;

        if ( trainError.username != null ) {
          // move username errors to email field
          trainError.email = trainError.username;
          delete trainError.username;
        }

        // server side validation has reported some errors.
        Object.keys(trainError).forEach(failedFieldId => {
          const control = formGroup.controls[failedFieldId];
          if ( control ) {
            control.setErrors({ server: trainError[failedFieldId] });
          }
        });

        const errorCode = error.errorCode ?? trainError.errorCode;
        if ( errorCode === '#SRS1' ) {
          this.infoService.showMessage($localize`:@@self_register_username_already_taken:
            (Error: #SRS1) Registration is not possible with this e-mail address. Please contact Support.`, {
            durationInSeconds: 30,
            infoType: InfoType.Error,
          });
        }
        return throwError(error);
      }))
      .pipe(take(1))
      .subscribe();
  }

  private buildForm(
    data: UserProfileResponse | null,
  ): FormGroup | null {

    const formGroup = (this.formGroup ??= new UntypedFormGroup({}));
    UserProfileHelper.mergeFormGroup(data, formGroup);
    if ( this.hasTermsAndConditions() ) {
      formGroup
        .addControl('acceptTAC', new UntypedFormControl(false, [ Validators.required ]));
    }
    return formGroup;
  }

  private updateRouteData(
    data: SelfRegisterInfoResponse | null,
  ): void {

    const processState = data.process?.state ?? {};
    // only require password if the user is not authenticated by sso
    const hideCredentials = ((processState.registrationId != null) && (processState.principalName != null));

    this.info = data;

    this._sortedAttributes = SelfRegisterFormHelper.getSortedAttributes(data);
    const userProfileData = SelfRegisterFormHelper.asUserProfileResponse(this._sortedAttributes);
    this.sections = UserProfileHelper
      .asProfileSections(userProfileData, false, hideCredentials, processState);
    this.formGroup = this.buildForm(data);

    // only require password if the user is not authenticated by sso
    if (hideCredentials) {
      this.formGroup.removeControl('login');
      this.formGroup.removeControl('pass');
    }
  }

}
