import { OfflineContent } from './admin-offline.types';
import { ControllingSingleUserTypes } from './ctrl-single-user.types';
import { AssignmentDialogTypes } from '../component/assignment-dialog/assignment-dialog.types';
import { UserNameHelper } from './user-name.helper';
import { Injectable } from '@angular/core';
import { InfoService } from './info/info.service';
import { Observable } from 'rxjs';
import { AssignmentDialogComponent } from '../component/assignment-dialog/assignment-dialog.component';
import { map, take, takeWhile } from 'rxjs/operators';
import { ReportRowData } from './report/report.types';


@Injectable({ providedIn: 'root' })
export class UserHelper {

  constructor(
    private infoService: InfoService,
  ) {
  }

  public showAssignmentDialog(
    data: AssignmentDialogTypes.AssignmentDialogData<ControllingSingleUserTypes.ControllingUser>
  ): Observable<AssignmentDialogTypes.AssignmentEntry<ControllingSingleUserTypes.ControllingUser>[] | never | null> {
    return this.infoService.showDialog<AssignmentDialogComponent<ControllingSingleUserTypes.ControllingUser>,
      AssignmentDialogTypes.AssignmentDialogData<ControllingSingleUserTypes.ControllingUser>,
      AssignmentDialogTypes.AssignmentDialogEntries<ControllingSingleUserTypes.ControllingUser>>(
      AssignmentDialogComponent, data
    )
      .pipe(take(1))
      .pipe(takeWhile(result =>
        (result != null) && (result.available != null) && (result.selected != null)))
      .pipe(map(result => result.selected));
  }

  public static reportRowToParticipant(
    extUserRow: Pick<ReportRowData,
      'userId' | 'userEmail' | 'userEmployeeID' | 'userFirstname' | 'userLastname' | 'userLocationDesc'>,
  ): OfflineContent.Participant {
    if (extUserRow === null) {
      return null;
    }
    return {
      userId: extUserRow.userId,
      assignmentMode: null,
      email: extUserRow.userEmail,
      employeeID: extUserRow.userEmployeeID,
      examinationStatus: null,
      firstname: extUserRow.userFirstname,
      invitationStatus: null,
      invitationStatusDate: null,
      lastname: extUserRow.userLastname,
      locationDesc: extUserRow.userLocationDesc,
      login: null,
      paymentStatus: null,
      merchantStatus: null,
      merchantTransactionType: null,
      participationStatus: null,
      registrationStatus: null,
      transactionStatus: null
    };
  }

  public static convertToDialogData(
    selectedUserId: number | null,
    systemUsers: ControllingSingleUserTypes.ControllingUser[],
    maxSelections?: number,
  ): AssignmentDialogTypes.AssignmentDialogData<ControllingSingleUserTypes.ControllingUser> {
    const available = (systemUsers ?? [])
      .filter(user => user.userId !== selectedUserId)
      .map(user => UserHelper.convertToDialogEntry(user, selectedUserId));
    const selected = (systemUsers ?? [])
      .filter(user => user.userId === selectedUserId)
      .map(user => UserHelper.convertToDialogEntry(user, selectedUserId));
    return ({
      data: { maxSelections, available, selected, },
      i18n: {
        available: $localize`:@@assignment_dialog_service_dlg_available:Available users and target groups`,
        search: $localize`:@@assignment_dialog_service_dlg_search:Search in users and target groups...`,
        selected: $localize`:@@assignment_dialog_service_dlg_selected:Currently assigned`,
        title: $localize`:@@global_assignment:Assignment`,
        tooManySelections: $localize`:@@assignment_dialog_service_dlg_too_many_selections:There are more entries selected than are allowed!`,
      },
    });
  }

  public static convertToDialogEntry(
    user: ControllingSingleUserTypes.ControllingUser,
    selectedUserId: number | null,
  ): AssignmentDialogTypes.AssignmentEntry<ControllingSingleUserTypes.ControllingUser> {
    const title = UserNameHelper.getFullNameWithUserId(
      user.userFirstname,
      user.userLastname,
      user.userId,
    );
    return ({
      changed: false,
      selected: user.userId == selectedUserId,
      title, value: user, type: 'user',
    });
  }

}
