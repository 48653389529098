<rag-dialog-header>
  <span i18n="@@general_warning">Warning</span>
</rag-dialog-header>

<div mat-dialog-content>

  <span id="message" *ngIf="message != null">{{message}}</span>.

  <div *ngFor="let course of courses; let first=first">

    <h4>{{(course.title | localeText) || '—'}} (ID: {{course.courseId}})</h4>

    <div *ngIf="getLicenseCount(course) as licenseCount">
      <mat-list dense>
        <h4 matSubheader i18n="@@admin_courses_usage_dialog_active_licenses">Active licenses:</h4>
        <mat-list-item>{{licenseCount}}</mat-list-item>
      </mat-list>
    </div>

    <div *ngIf="getCurricula(course) as curricula">
      <mat-list dense>
        <span matSubheader i18n="@@admin_courses_usage_dialog_used_in">Referred in contents:</span>
        <mat-list-item *ngFor="let curriculum of curricula">{{curriculum.name | localeText}}</mat-list-item>
      </mat-list>
    </div>
  </div>

</div>

<div mat-dialog-actions>

  <button [mat-dialog-close]="proceed" mat-stroked-button type="button" data-cy="buttonProceed">
    <span i18n="@@admin_courses_usage_dialog_used_proceed_anyway">Proceed anyway</span>
  </button>

  <button [mat-dialog-close] mat-stroked-button type="button" data-cy="buttonClose">
    <span i18n="@@global_close">Close</span>
  </button>

</div>
