<ng-container [ngSwitch]="appearance">

  <mat-button-toggle-group
    [disabled]="disabled"
    *ngSwitchCase="'horizontal'"
    appearance="legacy"
    name="language"
    (change)="onChange($event)"
    aria-label="Select language">
    <mat-button-toggle
      *ngFor="let lang of languages"
      [checked]="lang === selected"
      [matTooltip]="lang.title | localeText"
      [value]="lang"
      data-cy="toggleLang">
      <ng-container
        [ngTemplateOutlet]="option"
        [ngTemplateOutletContext]="{lang: lang}"
      ></ng-container>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <mat-select
    *ngSwitchCase="'vertical'"
    [compareWith]="compareWith"
    [value]="selected"
    (selectionChange)="onSelectionChange($event)"
    data-cy="selectLang"
    >
    <mat-option
      *ngFor="let lang of languages"
      [value]="lang"
      data-cy="selectLangOption">
      <ng-container
        [ngTemplateOutlet]="option"
        [ngTemplateOutletContext]="{lang: lang}"
      >
      </ng-container>
    </mat-option>
  </mat-select>

</ng-container>

<ng-template #option let-lang="lang">
  <span class="flag-icon {{getLanguageFlag(lang)}}"></span>
  <span
    class="lang-text"
    *ngIf="!flagsOnly">&nbsp;{{lang.title | localeText}}</span>
</ng-template>
