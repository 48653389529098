import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ImageableContentReference } from '../../../../core/core.types';
import { OfflineContent } from '../../../../core/admin-offline.types';
import { Observable } from 'rxjs';
import { CachedSubject } from '../../../../core/cached-subject';
import { IconHelper } from '../../../../core/icon.helper';
import { FinancialsHelper } from '../../../../core/financials/financials.helper';
import { AttrViewData, ViewHelper } from '../../../../core/view-helper';
import { ImageUrlHelper } from '../../../../core/image-url-helper';
import { DisplayStatusColors, DisplayStatusHelper } from '../../../../core/display-status-helper';
import { ContentService } from '../../../../core/content/content.service';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ContentCardHelper, ImportantContentInfo } from '../../../content/content-card.helper';
import { DistributionTypeHelper } from '../../../../core/distribution-type-helper';
import { MyTrainingsParentCurriculumDTO } from '../../../../core/content/my-trainings-v4.types';
import { CardStatusIcon } from '../../card-status-icon/card-status-icon.types';
import { CardStatusIconHelper } from '../../card-status-icon/card-status-icon.helper';

@Component({
  selector: 'rag-curriculum-card',
  templateUrl: './curriculum-card.component.html',
  styleUrls: ['./curriculum-card.component.scss']
})
export class CurriculumCardComponent implements OnChanges, OnInit {

  @Input() content!: ImageableContentReference;
  @Input() editorMode: boolean = false;
  contentLink: string;
  iconForType: string = '';
  itemsCount = 0;
  items: OfflineContent.EventSchedule[] = [];
  selectedItem: OfflineContent.EventSchedule;
  selectedItemIndex = 0;
  statusColor: DisplayStatusColors;
  progress: string;
  progressSteps: string;
  readonly cardBackgroundStyles$: Observable<any | null>;
  readonly DEFAULT_PICTURE_URL = "assets/images/card_curriculum.jpg";
  readonly imageUrl$: Observable<string | ArrayBuffer>;
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL)

  constructor(
    private router: Router,
  ) {
    this.imageUrl$ = this._imageUrl$.asObservable();
    this.cardBackgroundStyles$ = this.imageUrl$
      .pipe(filter(url => !!url))
      .pipe(map(url => ({
        'background-size': 'cover',
        'background-image': `url(${url})`
      })));
  }

  get price(): string {
    if (this.selectedItem?.priceForUserInCent > 0) {
      return FinancialsHelper.fromPriceInCent(this.selectedItem.priceForUserInCent) + ' €';
    } else {
      return $localize`:@@global_price_free:free`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'content')) {
      this.updateImageUrl();
    }

    this.iconForType = IconHelper.svgIconForObjType(this.content?.objType, this.content?.objSubType);

    this.items = this.content?.items ?? [];
    if (this.items.length > 0 && this.selectedItem == null) {
      this.selectedItem = this.items[0];
    }
    this.itemsCount = this.items.length;
  }

  ngOnInit(): void {
    this.statusColor = DisplayStatusHelper.toColor(this.content?.displaystatus ?? 0);
    this.contentLink = ContentService.getContentHref(this.content);
    this.progress = Math.round((this.content?.progress ?? 0) * 100) / 100 + '%';
    this.progressSteps = ContentService.getItemProgress(this.content);
  }

  getCurriculumValidUntil(content: unknown): ImportantContentInfo | null {
    return ContentCardHelper.getCurriculumValidUntil(content as MyTrainingsParentCurriculumDTO);
  }

  getExecutableItem(content: AttrViewData): ImageableContentReference {
    return content as ImageableContentReference;
  }

  getDetailsLink(): string | null {
    if (this.editorMode || !this.contentLink) {
      return null;
    }
    return this.contentLink;
  }

  getCardStatusIcon(content: unknown): CardStatusIcon | null {
    return CardStatusIconHelper.getCurriculumStatusIcon(content);
  }

  getTooltip(content: unknown): string | null {
    return ContentCardHelper.getContentCardCourseTooltip(content);
  }

  getTypeLabel(): string | null {
    return DistributionTypeHelper.asText(this.content?.objType ?? this.content?.objectType);
  }

  selectedScheduleChanged(mode: 'previous' | 'next', $event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (mode === 'previous') {
      const previousSchedule = this.items?.[this.selectedItemIndex - 1];
      if (previousSchedule != null) {
        this.selectedItem = previousSchedule;
        this.selectedItemIndex -= 1;
        return;
      }
      this.selectedItem = this.items[this.itemsCount - 1];
      this.selectedItemIndex = (this.itemsCount - 1);
      return;
    }

    const nextSchedule = this.items?.[this.selectedItemIndex + 1];
    if (nextSchedule != null) {
      this.selectedItem = nextSchedule;
      this.selectedItemIndex += 1;
      return;
    }
    this.selectedItem = this.items[0];
    this.selectedItemIndex = 0;
  }

  private updateImageUrl(): void {
    if (!this.content) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if (pictureFile != null) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
        this.content.pictureId,
        this.content.cardPictureUUID ??
        this.content.cardPicture?.uuid ??
        this.content.pictureUUID) ??
      this.DEFAULT_PICTURE_URL;
    this._imageUrl$.next(imageUrl);
  }
}
