<rag-dialog-header>
  <span i18n="@@select_badge_template">Select badge template</span>
</rag-dialog-header>
<div matDialogContent class="container">
  <form [formGroup]="form" >
    <div class="flex-wrapper">
      <div class="column badge-picker-wrapper">
        <mat-form-field appearance="outline" class="badge-picker">
          <mat-label i18n="@@badge_template">Badge Template</mat-label>
          <mat-select
            [disabled]="badgeTemplates?.length === 0"
            [formControlName]="'template'"
            data-cy="selectTemplate"
          >
            <mat-option
              *ngFor="let template of (badgeTemplates ?? [])"
              [value]="template?.templateUUID"
              data-cy="selectTemplateOption"
            >
              <span>{{template?.adminTitle}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <rag-badge-card
          [previewMode]="true"
          [languageForPreview]="language.key"
          [badge]="selectedBadge$ | async"
          class="badge-preview"
        ></rag-badge-card>
      </div>
      <div class="column margin-left">
        <rag-select-language
          [appearance]="'horizontal'"
          [flagsOnly]="true"
          [selected]="language"
          [languages]="languages"
          (languageChange)="onLanguageChange($event)"
        ></rag-select-language>
        <mat-form-field appearance="outline" class="title">
          <mat-label>
            <span i18n="@@global_title">Title</span>
          </mat-label>
          <input matInput [formControlName]="'title'" data-cy="inputTitle">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>
            <span i18n="@@global_description">Description</span>
          </mat-label>
          <textarea matInput [formControlName]="'description'" [rows]="6" data-cy="textDescription"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div matDialogActions>
  <button
    type="button"
    mat-stroked-button
    [matDialogClose]="null"
    data-cy="buttonCancel"
  >
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    type="button"
    mat-flat-button
    color="warn"
    [disabled]="!(currentContentConfig?.badgeConfigUUID)"
    (click)="onDeleteBadgeTemplate()"
    data-cy="buttonDelete"
  >
    <span i18n="@@global_delete">Delete</span>
  </button>

  <button
    (click)="onSaveBadgeTemplate()"
    type="button"
    mat-flat-button
    color="primary"
    [disabled]="form.invalid"
    data-cy="buttonSave"
  >
    <span i18n="@@global_save">Save</span>
  </button>
</div>
