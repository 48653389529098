import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { merge } from 'lodash';
import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { ApplicationStateService } from '../../../core/application-state.service';
import { GenericMessageDialogComponent } from '../../../component/generic-message-dialog/generic-message-dialog.component';
import { InfoService } from '../../../core/info/info.service';
import {
  DeleteButton,
  DeleteCancelButtons,
  InfoType,
  MessageKey,
} from '../../../core/info/info.types';
import { ModalDialog } from '../../../core/modal-dialog';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { destroySubscriptions, subscribeUntilDestroyed, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { ViewportState } from '../../../core/viewport-state';
import { MailService } from './mail.service';
import { MailBox, MailBoxType, MailEntry } from '../../../core/mail.types';
import { CachedSubject } from 'src/app/core/cached-subject';


@Component({
  selector: 'rag-mail',
  templateUrl: './mail.component.html',
  styleUrls: [ './mail.component.scss' ],
  animations: [
    trigger('row', [
      state('in', style({
        transform: 'translateX(0)',
        opacity: 1,
      })),
      transition('void => *', [
        style({
          transform: 'translateX(-10px)',
          opacity: 0,
        }),
        animate(200),
      ]),
      transition('* => void', [
        animate(0, style({
          opacity: 0,
        })),
      ]),
    ]),
  ],
})
export class MailComponent
  implements OnInit, OnDestroy {

  @ViewChild('mailbox_delete_multiple_confirmation')
  MailboxDeleteMultipleConfirmation: ElementRef;
  @ViewChild('mailbox_delete_single_confirmation')
  MailboxDeleteSingleConfirmation: ElementRef;
  @ViewChild('mailbox_mark_as_read', { static: true })
  transMailboxMarkAsRead: ElementRef;
  @ViewChild('mailbox_mark_as_read_multiple', { static: true })
  transMailboxMarkAsReadMultiple: ElementRef;
  @ViewChild('mailbox_mark_as_unread', { static: true })
  transMailboxMarkAsUnread: ElementRef;
  @ViewChild('mailbox_mark_as_unread_multiple', { static: true })
  transMailboxMarkAsUnreadMultiple: ElementRef;

  box: MailBoxType = 'inbox';
  canDraftMessages$: Observable<boolean>;
  composeButtonmDisabled$: Observable<boolean>;
  canPlanMessages$: Observable<boolean>;
  canSendMessages$: Observable<boolean>;
  dataSource: Observable<MailEntry[]>;
  displayedColumns: string[] = [ 'checkbox', 'from', 'subject', 'date', 'status' ];
  entries: MailEntry[] = [];
  // total emails in selected box
  msgTotalCount = 0;
  // current page index
  pageIndex = 0;
  // page size
  pageSize = 20;
  resultsState = 'unknown';
  // selected for preview email
  selectedMailEntry: MailEntry = null;
  // select all
  toggleSelectAll = false;
  viewportState: ViewportState;

  private _showAllReceivers = false;
  private _composeButtonmDisabled$ = new CachedSubject<boolean>(false);

  constructor(
    private appState: ApplicationStateService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dialog: ModalDialog,
    private mailService: MailService,
    private navigationService: NavigationService,
    private infoService: InfoService,
    private principalService: PrincipalService,
  ) {

    this.composeButtonmDisabled$ = this._composeButtonmDisabled$.asObservable();
    this.canDraftMessages$ = this.principalService.permissionStates$
      .pipe(map(s => s.userMessagesSaveDraft));
    this.canPlanMessages$ = this.principalService.permissionStates$
      .pipe(map(s => s.userMessagesPlanned));
    this.canSendMessages$ = this.principalService.permissionStates$
      .pipe(map(s => s.userMessagesSend));

    subscribeUntilDestroyed(this.appState.viewportState$.pipe(map(viewportState => {
      this.viewportState = viewportState;
    })), this);
  }

  get showAllReceivers() {
    return this._showAllReceivers;
  }

  ngOnInit() {
    this.route.data.pipe(map(data => {
      const content = data.content;
      const routeSnapshot = this.updateData(content);

      // todo remove composer access by queryParam

      const isComposing = routeSnapshot.queryParams['compose'] != null;
      if ( isComposing ) {
        const value = routeSnapshot.queryParams['compose'];
        if ( 'new' === value ) {
          setTimeout(() => {
            this.displayComposer(null, this.box);
          });
        } else {
          const messageId = parseInt(value, 10);
          if ( (messageId === 0) || (messageId > 0) ) {
            // fetch this message and open mail composer
            this.mailService.getById(messageId).subscribe(mailEntry => {
              this.displayComposer(mailEntry, this.box);
            });
          } else {
            console.error(value + ' is unknown compose value');
          }
        }
      }

      if ( this.selectedMailEntry ) {
        const index = content.messages.findIndex(entry => entry.id === this.selectedMailEntry.id);
        if ( index > -1 ) {
          merge(this.selectedMailEntry, content.messages[index]);
        }
      }
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  shouldShowMore(i: number): boolean {
    return !this._showAllReceivers && i === 3;
  }

  shouldShowLess(i: number): boolean {
    return this._showAllReceivers && i === (this.selectedMailEntry.receivers.length - 1);
  }

  clearSelected() {
    this.toggleSelectAll = false;
    this.selectedMailEntry = null;
    this.location.replaceState(this.getUrl());
  }

  // date formatter
  dateFormatted(date: Date): string {
    if ( !date && this.selectedMailEntry ) {
      const indexSelected = this.entries.findIndex(entry => entry.id === this.selectedMailEntry.id);
      const dateUser = this.entries[indexSelected].sendDate;
      return moment(dateUser).format('LLL');
    }
    if ( date != null && date.getTime ) {
      return moment(date).format('LLL');
    }
    return '';
  }

  // callback functions support for the selected mail pager
  fromIndexMail(selectedMailEntry): number {
    const index = this.entries.findIndex(entry => entry.id === selectedMailEntry.id);
    return this.pageIndex * this.pageSize + index + 1;
  }

  hasAttachments(mailEntry: MailEntry): boolean {
    return mailEntry.attachments != null && mailEntry.attachments.length > 0;
  }

  hasData() {
    return this.entries.length > 0;
  }

  hasSelected = (): boolean => {
    if ( this.selectedMailEntry ) {
      return true;
    }
    for (const entry of this.entries) {
      if (entry.selected) {
        return true;
      }
    }
    return false;
  };

  /**
   * @returns true if selectedMailEntry or all selected mail entries are not read, otherwise false
   */
  invertedReadStatusForSelected(): boolean {
    if ( this.selectedMailEntry ) {
      return !this.selectedMailEntry.read;
    }
    let read = false;
    for (const entry of this.entries) {
      if ( entry.selected ) {
        read = read || entry.read;
        if ( read ) {
          return false;
        }
      }
    }
    return true;
  }

  mailCorrespondentFor(mailEntry: MailEntry): string {
    if ( 'inbox' === this.box ) {
      return mailEntry.from;
    }
    if ( mailEntry.receivers == null || mailEntry.receivers.length === 0 ) {
      return '*';
    }
    let correspondent = mailEntry.receivers[0].name;
    if ( mailEntry.receivers.length > 1 ) {
      correspondent += ' ...';
    }
    return correspondent;
  }

  mailEntryDate(mailEntry: MailEntry): Date {
    switch ( this.box ) {
      case 'inbox':
        return mailEntry.received;
      case 'outbox':
        return mailEntry.sent;
      case 'planned':
        return mailEntry.sent || mailEntry.created;
      default:
        return mailEntry.created;
    }
  }

  mailEntryDatePlannedTitle(mailEntry: MailEntry): string {
    if ( this.box === 'planned' ) {
      return this.dateFormatted(mailEntry.created);
    }
    return '';
  }

  onToggleAllReceivers() {
    this._showAllReceivers = !this._showAllReceivers;
  }

  onClick(mailEntry: MailEntry) {

    const shouldEditWithComposer = this.box === 'drafts' || this.box === 'planned';

    this.mailService.getById(mailEntry.id).subscribe(mailEntryWithContent => {
      mailEntry.body = mailEntryWithContent.body;
      mailEntry.draft = mailEntryWithContent.draft;
      mailEntry.workContextMode = mailEntryWithContent.workContextMode;
      // mailEntry.attachments = mailEntryWithContent.attachments;
      if ( shouldEditWithComposer ) {
        this.displayComposer(mailEntry, this.box);
      } else {
        mailEntry.read = true;
        // replace location to persists the state between page refresh
        this.location.replaceState(this.location.path(), 'm=' + mailEntry.id);
        // set selected email
        // this.selectedMailEntry = mailEntryWithContent;

        this.selectedMailEntry = mailEntry;
      }
    });
  }

  onCompose() {
    this.displayComposer(null, this.box);
  }

  /**
   * delete selected email
   */
  onDelete($event: MouseEvent, mailEntry?: MailEntry) {

    $event.preventDefault();
    $event.stopPropagation();

    let toBeDeleted: Array<number>;
    if ( mailEntry ) {
      toBeDeleted = [ mailEntry.id ];
    } else if ( this.selectedMailEntry != null ) {
      toBeDeleted = [ this.selectedMailEntry.id ];
    } else {
      toBeDeleted = this.entries.reduce((pV, _mailEntry) => {
        if ( _mailEntry.selected ) {
          pV.push(_mailEntry.id);
        }
        return pV;
      }, []);
    }

    const message = toBeDeleted.length === 1 ?
      this.MailboxDeleteSingleConfirmation.nativeElement.textContent :
      this.MailboxDeleteMultipleConfirmation.nativeElement.textContent;

    this.infoService.showDialog(GenericMessageDialogComponent, {
      titleKey: MessageKey.Mails.Delete_Mail,
      buttons: DeleteCancelButtons,
      message,
    }).pipe(map(button => {
      if ( button !== DeleteButton ) {
        return;
      }
      this.mailService.deleteByIdAndBox(toBeDeleted, this.box).subscribe(success => {
        if ( !success ) {
          return;
        }
        // uncheck the checkbox, in case it has been checked
        this.toggleSelectAll = false;

        let nextPageIndex = this.pageIndex;
        if ( toBeDeleted.length === this.entries.length && nextPageIndex > 0 ) {
          nextPageIndex--;
          this.setPageIndex(nextPageIndex);
          return;
        }

        this.mailService.getBox(this.box, nextPageIndex, this.pageSize).subscribe(mailBox => {
          this.pageIndex = nextPageIndex;
          this.entries = mailBox.messages;
          this.dataSource = of(this.entries);
          this.resultsState = this.entries.length > 0 ? 'data' : 'empty';
          this.msgTotalCount = mailBox.msgTotalCount;
          this.toggleSelectAll = false;

          if ( this.selectedMailEntry != null ) {
            this.clearSelected();
          }
        });

        if ( toBeDeleted.length === 1 ) {
          this.infoService.showSnackbar(MessageKey.Mails.MAILBOX_EMAIL_SINGLE_DELETED, InfoType.Success);
        } else {
          this.infoService.showSnackbar(MessageKey.Mails.MAILBOX_EMAIL_MULTIPLE_DELETED, InfoType.Success);
        }
      }, err => {
        this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error, { message: err });
      });
    })).subscribe();
  }

  // todo: it works only once
  onRefresh() {
    this.navigationService.navigate([ '/mailbox', this.box, this.pageIndex, this.pageSize ]);
  }

  /**
   * Clicked on reply button from within selected email
   * @param messageId the email to reply to
   */
  onReplay(messageId: number) {

    const mailEntry = this.entries.find(_mailEntry => _mailEntry.id === messageId);
    if ( mailEntry == null ) {
      return;
    }

    this.mailService.reply(mailEntry.id).subscribe(replyMailEntry => {
      this.location.replaceState(this.location.path(), 'compose=' + 0);
      this.displayComposer(replyMailEntry, this.box, true);
    });
  }

  /**
   * select / unselect all email entries
   * @param selected true or false, select all if true
   */
  selectAll(selected: boolean) {
    this.entries.forEach(x => {
      x.selected = selected;
    });
    this.toggleSelectAll = selected;
  }

  /**
   * select read or unread email entries
   * @param read if true select read emails, otherwise select unread emails
   */
  selectRead(read: boolean) {
    this.toggleSelectAll = false;
    this.entries.forEach(x => {
      x.selected = (read && x.read) || (!read && !x.read);
    });
  }

  // mail in After Selectedmail pager
  setMailIndexAfter(selectedMailEntry: MailEntry): void {
    const index = this.entries.findIndex(entry => entry.id === selectedMailEntry.id);
    if ( index < 0 ) {
      return;
    }
    const lastIndex = this.pageIndex * this.pageSize + index + 1;
    if ( lastIndex === this.msgTotalCount ) {
      return;
    }
    if ( index === this.entries.length - 1 ) {
      const nextPageIndex = this.pageIndex + 1;
      this.mailService.getBox(this.box, nextPageIndex, this.pageSize).subscribe(mailBox => {
        if ( mailBox.messages.length === 0 ) {
          // TODO display notification - there is no another page available
          return;
        }
        this.entries = mailBox.messages;
        this.dataSource = of(this.entries);
        this.pageIndex = nextPageIndex;
        this.handleNextSelectedMailEntry(this.entries[0]);
      });
    } else {
      this.handleNextSelectedMailEntry(this.entries[index + 1]);
    }
  }

  // previous mail in Selected mail pager
  setMailIndexBefore(selectedMailEntry: MailEntry): void {
    const index = this.entries.findIndex(entry => entry.id === selectedMailEntry.id);
    if ( (index < 0) || ((index === 0) && (this.pageIndex === 0)) ) {
      return;
    }
    // todo handle viewing last message in mailbox
    if ( (index === 0) && (this.pageIndex > 0) ) {
      const previousPageIndex = this.pageIndex - 1;
      this.mailService.getBox(this.box, previousPageIndex, this.pageSize).subscribe(mailBox => {
        if ( mailBox.messages.length === 0 ) {
          // TODO display notification - there is no another page available
          return;
        }
        this.entries = mailBox.messages;
        this.dataSource = of(this.entries);
        this.pageIndex = previousPageIndex;
        this.handleNextSelectedMailEntry(this.entries[this.entries.length - 1]);
      });
    } else {
      this.handleNextSelectedMailEntry(this.entries[index - 1]);
    }
  }

  setPageIndex(pageIndex: number, pageSize: number = 10): void {
    this.pageSize = pageSize;
    this.navigationService.navigate([ '/mailbox', this.box, pageIndex, this.pageSize ]);
  }

  /**
   * Set read status for selected mail entries or the mail entry in preview
   * @param $event native browser event
   * @param read new read status
   */
  setSelectedReadStatus($event: any, read: boolean) {
    $event.stopPropagation();

    const toBeMarked: Array<MailEntry> = [];
    if ( this.selectedMailEntry ) {
      // this.selectedMailEntry.read = read;
      toBeMarked.push(this.selectedMailEntry);
    } else {
      for (const entry of this.entries) {
        if ( entry.selected ) {
          toBeMarked.push(entry);
        }
      }
    }

    this.mailService.markAsReadUnread(toBeMarked.map(mailEntry => mailEntry.id), read).subscribe(response => {
      if ( response ) {

        const multiplicator = read ? -1 : 1;
        const messagesCount = toBeMarked.reduce((pV, mailEntry) => {
          pV += (mailEntry.read !== read) ? multiplicator : 0;
          return pV;
        }, 0);

        this.principalService.setMessagesAsReadCount(messagesCount);

        toBeMarked.forEach(mailEntry => {
          mailEntry.read = read;
        });
        this.infoService.showSnackbar(MessageKey.Mails.MAILS_MARK_AS_READ_UNREAD_SUCCESS, InfoType.Success);
      }
    }, err => {
      this.infoService.showSnackbar(MessageKey.Mails.MAILS_MARK_AS_READ_UNREAD_ERROR, InfoType.Warning);
    });
  }

  /**
   * invert read status for selected email entry
   * @param $event native browser event
   * @param mailEntry email entry to change the read flag
   */
  toggleReadStatus($event: any, mailEntry: MailEntry) {
    $event.stopPropagation();
    this.mailService.markAsReadUnread([ mailEntry.id ], !mailEntry.read).subscribe(success => {
      if ( success ) {
        this.principalService.setMessagesAsReadCount(mailEntry.read ? 1 : -1);
        mailEntry.read = !mailEntry.read;
        this.infoService.showSnackbar(MessageKey.Mails.MAILS_MARK_AS_READ_UNREAD_SUCCESS, InfoType.Success);
      } else {
        this.infoService.showSnackbar(MessageKey.Mails.MAILS_MARK_AS_READ_UNREAD_ERROR, InfoType.Warning);
      }
    });
  }

  /**
   * select / unselect email entires depending on this.toggleSelectAll flag
   */
  toggleSelectAllChanged(selected: boolean) {
    this.entries.forEach(x => {
      x.selected = selected;
    });
  }

  tooltipByReadStatus(read: boolean, single = false): string {
    if ( this.selectedMailEntry || single ) {
      return read ? this.transMailboxMarkAsUnread.nativeElement.innerText : this.transMailboxMarkAsRead.nativeElement.innerText;
    }
    const selectedCount = this.entries.reduce((c, mailEntry) => {
      if ( mailEntry.selected ) {
        c++;
      }
      return c;
    }, 0);
    if ( selectedCount > 1 ) {
      for (const entry of this.entries) {
        if ( entry.selected ) {
          read = (read && entry.read) && entry.read;
          if ( read ) {
            return this.transMailboxMarkAsUnreadMultiple.nativeElement.innerText;
          } else {
            return this.transMailboxMarkAsReadMultiple.nativeElement.innerText;
          }
        }
      }
    } else {
      return read ? this.transMailboxMarkAsUnread.nativeElement.innerText : this.transMailboxMarkAsRead.nativeElement.innerText;
    }
  }

  uncheckMail(value) {
    if ( this.box !== value ) {
      return;
    }
    if ( this.selectedMailEntry ) {
      if ( this.pageIndex === 0 ) {
        this.selectedMailEntry = null;
        this.location.replaceState('/mailbox/' + value);
      }
      return;
    }
    return;
  }

  private displayComposer(mailEntry: MailEntry | null, box: MailBoxType, reply: boolean = false) {
    this._composeButtonmDisabled$.next(true);
    this.mailService.showMailComposer(mailEntry, box, [], reply)
    .pipe(switchMap(result => {
      if ( result === true ) {
        return this.mailService.getBox(this.box, this.pageIndex, this.pageSize)
          .pipe(map(_box => this.updateData(_box)));
      }
      return EMPTY;
    }))
    .pipe(finalize(() => this._composeButtonmDisabled$.next(false)))
    .pipe(take(1))
    .subscribe();
  }

  private getUrl() {
    return '/mailbox/' + this.box + '/' + this.pageIndex + '/' + this.pageSize;
  }

  private handleNextSelectedMailEntry(mailEntry: MailEntry) {
    const complition = (newSelectedMailEntry: MailEntry) => {
      this.selectedMailEntry = newSelectedMailEntry;
      this.location.replaceState(this.getUrl(), 'm=' + newSelectedMailEntry.id);
    };
    if ( mailEntry.body == null ) {
      this.mailService.getById(mailEntry.id).subscribe(mailWithBody => {
        mailEntry.body = mailWithBody.body;
        complition(mailEntry);
      });
    } else {
      complition(mailEntry);
    }
  }

  private updateData(content: MailBox): ActivatedRouteSnapshot {
    this.pageSize = content.pageSize;
      this.toggleSelectAll = false;
      this.selectedMailEntry = content.openedMessage;

      this.entries = content.messages;
      this.dataSource = of(this.entries);
      this.resultsState = this.entries.length > 0 ? 'data' : 'empty';
      this.msgTotalCount = content.msgTotalCount;
      this.toggleSelectAll = false;

      const routeSnapshot = this.route.snapshot;
      this.box = routeSnapshot.params['box'];
      const pageStr = routeSnapshot.params['page'];
      this.pageIndex = pageStr != null ? parseInt(pageStr, 10) : 0;

      return routeSnapshot;
  }
}
