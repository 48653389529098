import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, forkJoin } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CatalogBookingDialogParams, CatalogBookingStep } from './catalog-booking-dialog.types';
import DistributableType = Core.DistributableType;
import { InfoService } from 'src/app/core/info/info.service';
import { Core } from 'src/app/core/core.types';
import { StartPageSettings } from 'src/app/route/admin/account-design/account-design.types';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { AccountDesignService } from 'src/app/route/admin/account-design/account-design.service';
import { CatalogService } from 'src/app/core/catalog/catalog.service';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { ProcessService } from 'src/app/route/main/process/process.service';
import { LanguageHelper } from 'src/app/core/language.helper';


@Component({
  selector: 'rag-catalog-booking-dialog',
  templateUrl: './catalog-booking-dialog.component.html',
  styleUrls: [ './catalog-booking-dialog.component.scss' ],
})
export class CatalogBookingDialogComponent
  implements OnInit, AfterViewInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  bookingSteps: Array<CatalogBookingStep>;
  closeButtonDisabled$: Observable<boolean>;
  userProcess?: Core.UserProcess;
  readonly startPageSettings$: Observable<StartPageSettings>;
  private _closeButtonDisabled$ = new BehaviorSubject<boolean>(true);
  private booking: Catalogs.CatalogBooking;
  private bookingUUID: string;
  private bookingFailed = false;

  constructor(
    private accountDesignService: AccountDesignService,
    private catalogService: CatalogService,
    private dialogRef: MatDialogRef<CatalogBookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CatalogBookingDialogParams,
    private principalService: PrincipalService,
    private processService: ProcessService,
    private infoService: InfoService
  ) {
    this.bookingUUID = data.bookingUUID;
    this.closeButtonDisabled$ = this._closeButtonDisabled$.asObservable();
    this.startPageSettings$ = this.accountDesignService.startPage$;
    // enable close button when the user is not logged in. In this case the login form is displayed
    // and the user must be able to close this dialog
    this._closeButtonDisabled$.next(data.isLogged !== false);
  }

  ngOnInit(): void {
    this.bookingSteps = [];
    this.bookingFailed = false;
    this.userProcess = this.data.userProcess;
    // first step - curriculum
    this.bookingSteps.push({
      objId: this.data.contentId,
      objType: this.data.contentType,
      title: this.data.title,
      complete: false,
      isReservation: this.data.isReservation,
      objSubType: this.data.courseType,
      moduleId: this.data.moduleId,
      selectedModuleOnly: this.data.selectedModuleOnly
    });
    // rest steps - offline event schedules
    this.data.offlineContents?.forEach(offlineContent => {
      // the content the user is booking seems to be not an event but curriculum so we need to handle the
      // block events as usual
      if (offlineContent.blockEvent) {

          this.bookingSteps.push({
            objId: offlineContent.offlineContentId,
            objType: Core.DistributableType.lms_offlineCnt,
            title: offlineContent.offlineContentTitle,
            complete: false,
            isReservation: this.data.isReservation,            
          });
        
      } else {
        offlineContent.events.forEach(eventSchedule => {
          this.bookingSteps.push({
            objId: offlineContent.offlineContentId,
            objType: Core.DistributableType.lms_offlineCnt,
            title: eventSchedule.title,
            complete: false,
            eventScheduleId: eventSchedule.id,
            isReservation: this.data.isReservation
          });
        });
      }
    });

    if ( !this.data.isLogged ) {
      // the user is not logged in -> store current booking process
      this.processService.setContext({
        id: this.data.contentId,
        objType: this.data.contentType,
        catalogUUID: this.catalogService.catalogUUID,
      });
    }
  }

  ngAfterViewInit() {
    if ( this.principalService.currentUser?.userId > 0 ) {
      // start booking automatically only if the user is logged in
      this.startBooking();
      this.closeButtonDisabled$
        .pipe(map(disabled => {
          if (!disabled && this.data.isReservation) {
            // if this is a reservation close the dialog once the booking gprocess is complete
            this.onDialogClose();
          }
        }))
        .subscribe();
    }
  }

  onDialogClose(payload: any = null) {
    this.dialogRef.close(this.bookingFailed ? undefined : ( payload ?? this.booking ) );
  }

  getStepLabel(bookingStep: CatalogBookingStep): string {

    let label: string;
    if ( (bookingStep?.objType === DistributableType.lms_offline_event) || (bookingStep?.eventScheduleId > 0) ) {
      label = $localize`:@@admin_content_content_event:Schedule`;
    } else if ( bookingStep?.objType === DistributableType.lms_curriculum ) {
      label = $localize`:@@curriculum:Curriculum`;
    } else if ( bookingStep?.objType === DistributableType.lms_course ) {
      if (bookingStep?.objSubType === Core.CourseType.Recording) {
        label = $localize`:@@global_recording:Recording`;
      } else {
        label = $localize`:@@global_course:Course`;
      }
    } else {
      label = $localize`:@@global_event:Event`;
    }

    const title = LanguageHelper.objectToText(bookingStep.title);
    if (title) {
      // just in case we don't have a title
      label += ': ' + title;
    }

    return label;
  }

  getTemplate(tplExternalCatalog: TemplateRef<any>, tplCatalog: TemplateRef<any>): TemplateRef<any> {
    if ( this.data.isLogged ) {
      return tplCatalog;
    }
    return tplExternalCatalog;
  }

  private cancelBookingsRecursive = (index: number): Array<Observable<void>> => {
    const tasks = [];
    for (let i = index; i >= 0; i--) {
      const step = this.bookingSteps[i];
      if ( step.eventScheduleId == null ) {
        tasks.push(this.catalogService.cancelBooking(this.booking.id));
      } else {
        tasks.push(this.catalogService.cancelBookingByEventSchedule(step.objId, step.eventScheduleId));
      }
    }
    return tasks;
  }

  private startBooking = (index: number = 0) => {
    if ( index >= this.bookingSteps.length ) {
      if (this.bookingFailed) {
        return;
      }
      // no more steps to perform
      this.stepper.next();
      this.stepper.next();
      this.catalogService.throwNotificationEvent().subscribe();
      this._closeButtonDisabled$.next(false);
      return;
    }
    const step = this.bookingSteps[index];
    let task: Observable<any>;

    if ( step.eventScheduleId == null ) {
      // book content and save booking object as result for this dialog
      task = this.catalogService
        .book(step.objId, step.objType, step.isReservation, this.bookingUUID, step.moduleId)
        .pipe(tap(booking => this.booking = booking));
    } else {
      // book event schedule
      task = this.catalogService
        .bookEventSchedule(step.objId, step.eventScheduleId, this.bookingUUID, step.isReservation);
    }

    task.pipe(catchError(e => {

      const errorMessage = String(e.message);
      let message: string;
      if ( errorMessage.includes('Already-assigned (#CABO3)') ) {

        // content already booked (kind of)
        message = '<span class="red">' +
          $localize`:@@already_assigned_schedule:This event is already assigned. So this booking is not possible.`
          + '</span>';
      } else {

        // other errors
        message = '<p>' +
          $localize`:@@catalog_booking_error:Booking failed. Please contact your system administrator for assistance.`
          + '</p><span class="red">' + errorMessage + '</span>';
      }

      this.bookingFailed = true;

      return this.infoService.showAlert(message)
        .pipe(tap(_ => this._closeButtonDisabled$.next(false)))
        // start cancelling recursevly the bookings prior to index
        .pipe(switchMap(_ => forkJoin(this.cancelBookingsRecursive(index - 1))))
        .pipe(tap(_ => this.booking = null))
        .pipe(map(_ => EMPTY));

    })).subscribe(_ => {

      if (this.bookingFailed) {
        return;
      }

      step.complete = true;
      this.stepper.next();

      setTimeout(() => {
        this.startBooking(++index);
      });

    });
  };

}
