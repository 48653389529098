<mat-form-field
  [formGroup]="form"
  appearance="outline"
>

  <mat-label>
    <mat-icon
      *ngIf="field.setByInterface === true"
      class="special-required"
      svgIcon="elevator"
    ></mat-icon>
    {{field.label | localeText}}
  </mat-label>

  <mat-datepicker #datePicker></mat-datepicker>

  <input
    matInput
    [disabled]="isDisabled()"
    type="text"
    [formControlName]="field.fieldId"
    inputmode="date"
    [matDatepicker]="datePicker"
    [name]="field.fieldId"
    [required]="field.required"
    data-cy="profileFieldDate"
  />

  <mat-datepicker-toggle
    matSuffix
    [for]="datePicker"
    data-cy="profileFieldDateToggle"
  ></mat-datepicker-toggle>

  <mat-error
    *ngIf="getError() as error"
  >{{error}}</mat-error>
</mat-form-field>
