<a class="site-wrapper" [routerLink]="getDetailsLink()" data-cy="cardDetails" tabindex="0">

  <div class="card-wrapper" [ngClass]="{'card-wrapper-mobile': !editorMode, 'editor-width': editorMode}">
    <div class="mat-card newstyle-card">
      <div
        class="image-wrapper"
        [ngStyle]="cardBackgroundStyles$ | async"
      >
        <rag-card-status-icon
          *ngIf="getCardStatusIcon(content) as status"
          [status]="status"
        ></rag-card-status-icon>
      </div>
      <div class="mat-card-content">
        <div class="mat-card-title-wrapper">
          <p
            *ngIf="content?.title | localeText as title"
            class="mat-card-title font-medium letterspacing-min"
            [matTooltipDisabled]="title.length < 80"
            [matTooltip]="title">{{title}}</p>
          <span class="mat-card-subtitle font-light letterspacing-more">{{objSubTypeAsText}}</span>
        </div>
        <div class="mat-card-bottom-wrapper">
          <div class="progress-line" mode="indeterminate">
            <div
              [ngClass]="{
              'width-0': (statusColor === 1),
              'width-50': (statusColor === 2),
              'width-100': (statusColor === 3)
               }"
              class="progress-line-bg"
            ></div>
          </div>
          <p class="text-status font-regular-italic letterspacing-more">{{statusText}}</p>
        </div>
      </div>
    </div>
  </div>

</a>
