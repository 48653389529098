<div [formGroup]="form">

  <div class="flex-row">
    <mat-form-field
      appearance="outline"
      cdkOverlayOrigin
      #cdkOverlayOrigin="cdkOverlayOrigin"
    >
      <mat-label>
        <span *ngIf="disabled" i18n="@@password">Password</span>
        <span *ngIf="disabled !== true" i18n="@@pswd_new_password">New password</span>
      </mat-label>

      <input
        (focusout)="onFocus(false)"
        (focusin)="onFocus(true)"
        matInput
        [formControlName]="passwordControlName"
        i18n-placeholder="@@pswd_placeholder"
        placeholder="Enter new password"
        required
        autocomplete="off"
        [type]="hidePassword ? 'text' : 'password'"
        data-cy="ragPassword"
      />

      <mat-error *ngIf="getError() as error">{{error}}</mat-error>
    </mat-form-field>
    <div
      *ngIf="showGeneratePassword"
      class="generate-password"
    >
      <button
        type="button"
        mat-icon-button
        (click)="onGeneratePassword()"
        [disabled]="disabled"
        data-cy="ragPasswordGenerate"
      >
        <mat-icon
          [matTooltipDisabled]="disabled"
          matTooltip="Generate password"
          i18n-matTooltip="@@global_generate_password"
          svgIcon="dice-5-outline"
        ></mat-icon>
      </button>
    </div>

  </div>

  <ng-container *ngIf="shouldShowPasswordRepeat()">

    <mat-form-field appearance="outline">
      <mat-label>
        <span i18n="@@pswd_repeat_password">Confirm password</span>
      </mat-label>

      <input
        matInput
        [formControlName]="passwordRepeatControlName"
        i18n-placeholder="@@pswd_placeholder"
        placeholder="Enter new password"
        required
        type="password"
        autocomplete="off"
        [type]="hidePassword ? 'text' : 'password'"
        data-cy="ragPasswordRepeat"
      />
      <button
        (click)="this.hidePassword = ! this.hidePassword;"
        type="button" matSuffix mat-icon-button
        data-cy="ragPasswordRepeatReveal"
      >
        <mat-icon [svgIcon]="hidePassword ? 'eye' : 'eye-off'"></mat-icon>
      </button>

      <mat-error *ngIf="getError(true) as error">{{error}}</mat-error>
    </mat-form-field>
  </ng-container>

</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="cdkOverlayOrigin"
  [cdkConnectedOverlayOpen]="showPasswordValidationOverlay"
  [cdkConnectedOverlayPositions]="position"
  [cdkConnectedOverlayOffsetY]="validationOffsetY"
>
  <div class="password-validation">
    <rag-password-validation
      [passwordField]="getControl()"
    ></rag-password-validation>
  </div>
</ng-template>
