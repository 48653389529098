import { Moment } from "moment";
import { Core } from "../core.types";
import { DisplayStatus } from "../display-status.enum";
import { Translation } from "../translation/translation.types";

type anyFunction<T> = (...params: any[]) => T;

export const BotValueMappings = {
  "assignmentType": {
    "both": "Verpflichtend und Freiwillig",
    "mandatory": "Verpflichtend",
    "voluntary": "Freiwillig",
  },
  "displaystatus": {
    "0": "Nicht gestartet",
    "1": "Zertifiziert",
    "2": "Nicht zertifiziert",
    "3": "Bestanden",
    "4": "Durchgefallen",
    "5": "Beendet",
    "6": "Unvollständig",
    "7": "Rezertifizierungszeit"
  },
  // "objType": {
  //   "lms_offlineCnt": "Veranstaltung",
  //   "lms_curriculum": "Curriculum",
  //   "lms_course": "Kurs",
  //   "lms_recording": "Aufzeichnung"
  // },
  "objSubType": {
    "11": "Webinar",
    "14": "vor Ort und gleichzeitiges Webinar",
    "16": "Aufzeichnung",
    "7": "Seminar",
    "1": "Zertifizierung",
    "2": "Test",
    "3": "Learning",
    "6": "Dokument",
    "8": "Kurs Dokument",
    "10": "Link",
    "12": "SimpleConnect",
    "13": "Hausaufgabe",
  },
  "currentAccountStatus": {
    "valid": "Bearbeitung möglich",
    "ended": "Bearbeitungsphase beended",
    "anulled": "nicht zugewiesen",
    "locked": "Gesperrt",
    "not_attempted": "Nicht gestartet"
  },
  "offlineType": {
    "virco": "Webinar",
    "offline": "vor Ort",
    "hybrid": "vor Ort und gleichzeitiges Webinar"
  },
  "courseType": {
    "1": "Zertifizierung",
    "2": "Test",
    "3": "Learning",
    "6": "Dokument",
    "7": "Seminar",
    "8": "Kurs Dokument",
    "10": "Link",
    "11": "Webinar",
    "12": "SimpleConnect",
    "13": "Hausaufgabe",
    "14": "Hybrid",
    "16": "Aufzeichnung"
  },
  // "distType": {
  //   "lms_course": "Kurs",
  //   "lms_offlineCnt": "Veranstaltung",
  //   "lms_curriculum": "Curriculum",
  //   "lms_recording": "Aufzeichnung",
  // },
  "distSubType": {
    "1": "Zertifizierung",
    "2": "Test",
    "3": "Learning",
    "6": "Dokument",
    "7": "Seminar",
    "8": "Kurs Dokument",
    "10": "Link",
    "11": "Webinar",
    "12": "SimpleConnect",
    "13": "Hausaufgabe",
    "14": "Hybrid",
    "16": "Aufzeichnung",
  },
  "state": {
    "booked": "Gebucht",
    "pending": "Ausstehende Buchung",
    "not_booked": "Nicht gebucht",
  }
};

export declare class BotApi {
  userBypass(text: string): void;
  botBypass(text: string): void;
  runMessage(text: string): void;
  botMessage(text: string): void;
  registerExternalFunction<T>(fnName: string, callback: anyFunction<T>);
}

export namespace Bots {

  export type EventType = 'bookContent';

  export interface BotVisibleEvent {
    visible: boolean;
    url?: string;
  }  

  export interface Config {
    bots: Array<Settings>;
  }

  export interface Settings {
    uuid: string;
    locationUrl: string;
    botUrl: string;
    title?: string;
    description?: string;
  }
  
  // Converted interfaces

  export interface CatalogEntry {
    id: number;
    title: string;
    description: string;
    link?: string;
    availablePlaces?: number;
    price?: number;
    currency?: string;
    bookedAt?: string;
    paidPrice?: number;
    pictureUUID?: string;
    accomplished?: boolean;
  }  

  export interface CatalogBooking {    
    bookingUUID: string;       
    reservation: boolean;
    bookedAt: string;
    price?: number;
    title: string;
    description: string;
  }
}