import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiUrls } from 'src/app/core/api.urls';
import { Translation } from 'src/app/core/translation/translation.types';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { combineLatest, map, Observable } from 'rxjs';
import { CachedSubject } from 'src/app/core/cached-subject';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';

@Component({
  selector: 'rag-legal-confirmation',
  templateUrl: './legal-confirmation.component.html',
  styleUrls: ['./legal-confirmation.component.scss']
})

export class LegalConfirmationComponent implements OnInit, OnDestroy {

  @Input() eventTitle: Translation;
  @Input() tcFileUUID: string;
  @Input() privacyFileUUID: string;
  @Output() isChecked = new EventEmitter<MatCheckboxChange>();

  tcPdfURL: string;
  privacyPdfURL: string;
  display: string;
  tcCheckedDisabled$: Observable<boolean>;
  privacyCheckedDisabled$: Observable<boolean>;

  private _tcCheckedDisabled$ = new CachedSubject<boolean>(false);
  private _privacyCheckedDisabled$ = new CachedSubject<boolean>(false);
  private _tcChecked$ = new CachedSubject<boolean>(null);
  private _privacyChecked$ = new CachedSubject<boolean>(null);

  constructor() {
    this.tcCheckedDisabled$ = this._tcCheckedDisabled$.asObservable();
    this.privacyCheckedDisabled$ = this._privacyCheckedDisabled$.asObservable();
  }

  ngOnInit(): void {
    if (this.tcFileUUID !== undefined) {
      this.tcPdfURL = ApiUrls.getKey('Files') + '/' + this.tcFileUUID;
      this._privacyChecked$.next(false);
    }
    if (this.privacyFileUUID !== undefined) {
      this.privacyPdfURL = ApiUrls.getKey('Files') + '/' + this.privacyFileUUID;
      this._tcChecked$.next(false);
    }
    combineLatest([
      this._tcChecked$.withoutEmptyValues(),
      this._privacyChecked$.withoutEmptyValues()
    ]).pipe(map(([tcCheched, privacyChecked]) => {
      let checked = false;
      if (this.tcFileUUID !== undefined && this.privacyFileUUID === undefined) {
        checked = tcCheched;
      } else if (this.tcFileUUID === undefined && this.privacyFileUUID !== undefined) {
        checked = privacyChecked;
      } else {
        checked = tcCheched && privacyChecked;
      }
      this._tcCheckedDisabled$.next(tcCheched);
      this._privacyCheckedDisabled$.next(privacyChecked);
      this.isChecked.emit({
        source: null,
        checked: checked
      });
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onChecked(matCheckboxChange: MatCheckboxChange, checkbox: string): void {
    switch ( checkbox ) {
      case 'tc':
        this._tcChecked$.next(matCheckboxChange.checked);
        break;
      case 'privacy':
        this._privacyChecked$.next(matCheckboxChange.checked);
        break;
      default:
        return;
    }
  }

}
