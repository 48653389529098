<form
  *ngIf="(sections?.length > 0); else tplEmptyProfile"
  [formGroup]="formGroup"
  (ngSubmit)="onSave()"
>
  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@profile_title">Profile</span>
    </div>

    <div class="page-header-buttons" [ngClass]="{'read-only': isReadOnly}">
      <button
        mat-stroked-button type="button" class="rag-stroked-button"
        *ngIf="hasEditCredentials()"
        (click)="onEditCredentials()"
        data-cy="UserProfileEditCredentialsButton"
      >
        <span i18n="@@user_profile_edit_credentials">Edit credentials</span>
      </button>

      <button
        mat-flat-button color="primary"
        (click)="onSave($event)"
        [disabled]="isSaveButtonDisabled$ | async"
        data-cy="UserProfileSaveButton"
      >
        <span i18n="@@global_save">Save</span>
      </button>

      <button
        *ngIf="isAuthenticated || isUserProfileExportable()"
        type="button"
        mat-stroked-button
        class="mat-button-fix"
        [matMenuTriggerFor]="headerMenu"
        data-cy="UserProfileMenuButton"
      >
        <mat-icon svgIcon="menu"></mat-icon>

        <mat-menu #headerMenu xPosition="before">
          <a
            mat-menu-item
            *ngIf="isUserProfileExportable()"
            [routerLink]="'/my-profile/export'"
            data-cy="UserProfileExportButton"
          >
            <mat-icon svgIcon="export"></mat-icon>
            <span i18n="@@profile_export_user">Profile export</span>
          </a>

          <ng-container *ngIf="isAuthenticated">

            <mat-divider></mat-divider>

            <button
              type="button"
              mat-menu-item
              color="warn"
              (click)="onDeactivate()"
              data-cy="UserProfileDeactivateButton"
            >
              <mat-icon svgIcon="close-circle-outline"></mat-icon>
              <span i18n="@@global_deactivate">Deactivate</span>
            </button>
          </ng-container>
        </mat-menu>
      </button>
    </div>
  </rag-page-header>

  <div class="profile-sections">
    <rag-profile-section-wrapper
      [sections]="sections"
      [disabled]="isLoading"
    ></rag-profile-section-wrapper>
  </div>
</form>

<ng-template #tplEmptyProfile>
  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@profile_title">Profile</span>
    </div>
  </rag-page-header>

  <div class="no-data">
    <span
      i18n="@@user_profile_no_options_available">You are not allowed to make any changes to your user account.</span>
  </div>
</ng-template>

