<rag-dialog-header>
  <span>{{data.i18n.title}}</span>
</rag-dialog-header>

<div
  [ngClass]="{'has-assignment-type': enableAssignmentType}"
  mat-dialog-content
>
  <div
    *ngIf="enableAssignmentType"
    mat-tab-nav-bar
  >
    <button
      (click)="onSetAssignmentType('mandatory')"
      [active]="assignmentType !== 'voluntary'"
      mat-tab-link
      data-cy="buttonMandatory">
      <mat-icon svgIcon="card-account-details-star-outline"></mat-icon>
      <span i18n="@@assignment_mandatory">Mandatory</span>
    </button>

    <button
      data-cy="buttonVoluntary"
      (click)="onSetAssignmentType('voluntary')"
      [active]="assignmentType === 'voluntary'"
      mat-tab-link>
      <mat-icon svgIcon="card-account-details-outline"></mat-icon>
      <span i18n="@@assignment_voluntary">Voluntary</span>
    </button>
  </div>

  <ng-template #tplAssignmentLists>
    <rag-assignment-dialog-lists
      (dataOut)="onSelectionChanged($event)"
      [dataIn]="listData$"
      [i18n]="data.i18n"
      [isInvalid]="isInvalid"
      [isMaxSelected]="isMaxSelected"
      [maxSelections]="maxSelections"
      [selectionCount]="selectionCount"
      [allowSortingInContainers]="data?.allowSortingInContainers"
    ></rag-assignment-dialog-lists>
  </ng-template>

  <rag-loading-indicator
    *ngIf="loading; else tplAssignmentLists"
  ></rag-loading-indicator>
</div>

<div mat-dialog-actions>
  <mat-error
    *ngIf="isInvalid && (maxSelections > 0)"
  >
    <span>{{data.i18n.tooManySelections}}</span>
  </mat-error>
  <button [mat-dialog-close] class="rag-stroked-button" mat-stroked-button data-cy="buttonCancel">
    <span i18n="@@global_cancel">Cancel</span>
  </button>
  <button (click)="onUpdateClick()" [disabled]="isPristine || isInvalid" autofocus color="primary" mat-flat-button data-cy="buttonSave">
    <span i18n="@@global_save">Save</span>
  </button>
</div>
