import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule, NgClass, NgIf} from '@angular/common';
import {PipesModule} from '../../../core/pipes/pipes.module';
import {MatCardModule} from '@angular/material/card';
import {EventCardComponent} from './event-card/event-card.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CatalogEventCardComponent} from './catalog-event-card/catalog-event-card.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

@NgModule({
    imports: [
        AsyncPipe,
        PipesModule,
        MatCardModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        NgClass,
        CommonModule,
        MatTooltipModule,
        RouterLink,
    ],
  declarations: [
    EventCardComponent,
    CatalogEventCardComponent
  ],
  exports: [
    EventCardComponent,
    CatalogEventCardComponent,
  ]
})

export class EventCardModule {
}
