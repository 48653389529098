import { PreloadService } from './preload.service';
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BotApi, Bots, BotValueMappings } from './bots/bots.types';
import { firstValueFrom, Observable } from 'rxjs';
import { CachedSubject } from './cached-subject';
import { EventType, Router } from '@angular/router';
import { UrlHelper } from './url.helper';
import moment from 'moment/moment';
import { environment } from 'src/environments/environment';
import { ApiUrls } from './api.urls';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, HttpRequestOptions } from './global.types';
import { CatalogService } from './catalog/catalog.service';
import { CatalogBotHelper } from '../component/catalog/catalog.bot.helper';
import { AnyObject, Core } from './core.types';
import { State } from '../app.state';
import { DistributionTypeHelper } from './distribution-type-helper';
import { removeKeys } from './utils';

declare function botApi(name: string, productive: boolean): BotApi;

@Injectable({
  providedIn: 'root',
})
export class BotService {    

  visible$: Observable<Bots.BotVisibleEvent>;  

  private botSettings: Bots.Settings[];
  private _visible$ = new CachedSubject<Bots.BotVisibleEvent>(null);  
  private _initialMsgSent = false; 
  private _msgQueue = new Array<string>();
  private _botApi: BotApi;

  CATALOG_SYS_MSG: string = `We are viewing the catalog content of a learning management system. 
  Online learning content and events are available for booking. 
  You should try to answer all questions asked using this data.
  Questions about appointments always refer to appointments for the events in this data.
  If you have the impression that your counterpart wants to book a piece of content, include a link to the details page that opens in a new window, indicating that bookings can be made there.
  Whenever you provide information about a content, include the details page.
  Whenever you provide content that has already been booked (there is a bookingUUID available), mention this explicitly.
  Whenever you are asked about appointments, look at appointments in events, recurring appointments, or recordings.
  Use ${State.botLanguage} language for communication.`;

  constructor(
    private preloadService: PreloadService,
    private router: Router,
    private http: HttpClient,
    private catalogService: CatalogService    
  ) {
    
    this.visible$ = this._visible$.withoutEmptyValues();    

    this.preloadService.getBotSettings()
      .pipe(tap(botSettings => this.botSettings = botSettings))
      .subscribe();

    this.router.events
      .pipe(map(event => {
        if (event.type !== EventType.NavigationEnd) {
          return;
        }
        const _botUrl = UrlHelper.getUrlSubdirectory(location.href);
        const botSettings = this.botSettings.find(s => s.locationUrl === _botUrl);
        if (botSettings !== undefined) {
          this._visible$.next({
            url: botSettings.botUrl,
            visible: true
          });
        } else {
          this._visible$.next({
            visible: false
          });
        }
      }))
      .subscribe();

    this._msgQueue.push(this.CATALOG_SYS_MSG);
  }

  async botAPI(botId: string) {        
    const _botApi = await botApi(botId, !environment.production);
    _botApi.registerExternalFunction("catalogMyBookedContentsFn", this.catalogMyBookedContentsFn);
    _botApi.registerExternalFunction("catalogPopularBookingsFn", this.catalogPopularBookingsFn);
    _botApi.registerExternalFunction("openContentHandlerFn", this.openContentHandlerFn);
    this._botApi = _botApi;
    return _botApi;
  }

  bypassInformation(data: string, reset = false) {
    if (reset) {
      this._msgQueue.splice(0, this._msgQueue.length);
      this._msgQueue.push(this.CATALOG_SYS_MSG);
    }
    this._msgQueue.push(data);
    this._handleMsgQueue();   
  }

  handleBypassInfromation() {
    this._handleMsgQueue();   
  }

  getAllBotSettings(): Bots.Config {
    return {
      bots: this.botSettings
    };
  }

  getBotForURL(url: string): string | null {
    return this.botSettings
      .find(botSetting => url === botSetting.locationUrl)?.botUrl ?? null;
  }

  formatAPIDataForBot(apiData: AnyObject): string {
    
    apiData = removeKeys(apiData, [
      'extLogin', 'maxUserCount', 'minUserCount', 'availablePlaces', 'availableModulePlaces', 'timeBlocks',
      'attachments', 'extLoginPath', 'extLoginMinsPrefix', 'extLoginMinsSuffix', 'extLoginPathName'
    ]);
    let _str = JSON.stringify(apiData);
    
    for (const key in BotValueMappings) {
      const mappingsForKey = BotValueMappings[key];
      for (const value in mappingsForKey) {
        const regex = new RegExp("\"" + key + "\":\\s*\"*" + value + "\"*,", "g");
        _str = _str.replace(regex, `"${key}": "${BotValueMappings[key][value]}",`);
      }
    }    
    
    _str = _str.replace(/offlineEventsViews/g, 'offlineEventList');
    _str = _str.replace(/eventDateUntil/g, 'eventDateEnd');
    _str = _str.replace(/eventDate"/g, 'eventDateStart"');    
    _str = _str.replace(/("objType":\s*"([_a-zA-Z]+)",)/g, (_match, p1, p2) => {      // leave "objType" as it is because we need the value as input parameter for call back functions
      // add translated "content type" after each "objType" found
      return `${p1}"content type": "${DistributionTypeHelper.asText(p2)}",`;
    })
    _str = _str.replace(/objSubType/g, 'content subtype');
    _str = _str.replace(/distType/g, 'content type');
    _str = _str.replace(/distSubType/g, 'content subtype');
    _str = _str.replace(/distributed/g, 'isAlreadyAssigned');
    _str = _str.replace(/"closedStatus":"noStatus"/g, '"":"noStatus"');
    _str = _str.replace(/blockEvent/g, 'isAppointmentSerie');      
    _str = _str.replace(/items/g, 'curriculumItems');
    _str = _str.replace(/catalogBooking/g, 'catalogBookingInfo');
    _str = _str.replace(/"cardPictureUUID":"([^"]+?)",/g, '"cardPictureUrl": "'+location.origin+'/lms/api/files/v1/$1",');
    _str = _str.replace(/"pictureUUID":"([^"]+?)",/g, '"pictureUrl": "'+location.origin+'/lms/api/files/v1/$1",');
    _str = _str.replace(/"whenCreated":(\d+),/g, (_match, p1) => {
      return `"creationDate": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY')}",`;
    });
    _str = _str.replace(/"creationDate":(\d+),/g,(_match, p1) => {
      return `"creationDate": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY')}",`;
    });
    _str = _str.replace(/"eventDateStart":(\d+),/g,(_match, p1) => {
      return `"eventDateStart": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY HH:mm')}",`;
    });
    _str = _str.replace(/"eventDateEnd":(\d+),/g,(_match, p1) => {
      return `"eventDateEnd": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY HH:mm')}",`;
    });
    _str = _str.replace(/"price":(\d+),/g, (_match, p1) => {
      return `"price": "${(Number(p1) / 100).toFixed(2)}€",`;
    });
    _str = _str.replace(/"priceForUserInCent":(\d+),/g, (_match, p1) => {
      return `"price": "${(Number(p1) / 100).toFixed(2)}€",`;
    });
    _str = _str.replace(/"minPrice":(\d+),/g, (_match, p1) => {
      return `"price": "ab ${(Number(p1) / 100).toFixed(2)}€",`;
    });
    _str = _str.replace(/"maxPrice":(\d+),/g, (_match, p1) => {
      return `"price": "bis ${(Number(p1) / 100).toFixed(2)}€",`;
    });
    return _str;
  }

  persistSettings(botSettings: Bots.Settings): Observable<Bots.Config> {
    const url = ApiUrls.getKey('BotsApiV1');
    return this.http.post<ApiResponse<Bots.Config>>(url, botSettings, HttpRequestOptions)
      .pipe(tap(response => this.botSettings = response.botConfig.bots))
      .pipe(map(response => response.botConfig));
  }

  removeSettings(uuid: string): Observable<Bots.Config> {
    const url = `${ApiUrls.getKey('BotsApiV1')}/${uuid}`;
    return this.http.delete<ApiResponse<Bots.Config>>(url)
      .pipe(tap(response => this.botSettings = response.botConfig.bots))
      .pipe(map(response => response.botConfig));
  }

  catalogMyBookedContentsFn = async (): Promise<Array<Bots.CatalogEntry>> => {    
    const catalog = await firstValueFrom(this.catalogService.cachedCatalog$);
    return catalog.contents.filter(c => c.catalogBooking !== undefined)
        .map(CatalogBotHelper.botifyCatalogEntry);    
  };

  catalogPopularBookingsFn = async (days: number): Promise<Array<Bots.CatalogEntry>> => {    
    return (await this.catalogService.catalogPopularBookings(days))      
      .map(CatalogBotHelper.botifyCatalogBooking);
  };

  openContentHandlerFn = async (objType: Core.DistributableType, objId: number): Promise<boolean> => {    
    console.log('open content of type ' + objType + ' and id ' + objId);        

    const catalog = await firstValueFrom(this.catalogService.cachedCatalog$);

    const targetContent = catalog.contents.find(c => c.objType === objType && c.id === objId);

    let contentLink: string | null;
    if (targetContent !== undefined) {
      if (targetContent.distributed === true) {
        contentLink = UrlHelper.execUrlFor(targetContent);
      } else {
        contentLink = this.catalogService.getDetailsUrl(targetContent);
      }
    }
   
    this.router.navigateByUrl(contentLink);
    return true;
  };

  private _handleMsgQueue() {
    if (this._visible$.value && this._botApi !== undefined) {
      this._msgQueue.forEach(m => this._botApi.userBypass(m));        
    }    
  }

}
