<div *ngFor="let offlineContent of data.offlineContents; let i = index" class="align-left">
  <rag-legal-confirmation
    [tcFileUUID]="offlineContent.tcFileUUID"
    [privacyFileUUID]="offlineContent.privacyFileUUID"
    [eventTitle]="offlineContent.title"
    (isChecked)="updateLegalCheckboxStatus(i, $event)">
  </rag-legal-confirmation>
</div>

<div class="container">

  <ng-container *ngIf="mode === 'book-with-events'">

    <div class="actions">

      <button
        *ngIf="!(selectedEvents?.size > 0)"
        type="button"
        mat-stroked-button
        class="rag-stroked-button booking-button"
        [disabled]="bookingWithoutEventsButtonDisabled || !clickableBookButton || disabled"
        (click)="onBookingWithoutEvents()"
      >{{labelBookmarkWithoutEvents()}}</button>

      <button
        *ngIf="(selectedEvents?.size > 0)"
        type="button"
        mat-flat-button color="primary"
        class="booking-button"
        [disabled]="bookButtonDisabled || !clickableBookButton || disabled"
        (click)="onBookSelectedEvents(assignmentMode)"
      >
        <div
          [matTooltip]="tooltip"
          [matTooltipDisabled]="!bookButtonDisabled"
          [matTooltipShowDelay]="500"
        >

          <!-- nothing to be paid -->
          <ng-container *ngIf="!(totalToPay > 0)">
            <span
              *ngIf="assignmentMode === 'wish'"
              i18n="@@catalog_booking_selected_count_mode_wish"
            >{selectedEventsCount, plural,=0{request 0 schedules} =1{request 1 schedule} other {request {{selectedEventsCount}} schedules}}</span>
            <span
              *ngIf="assignmentMode !== 'wish'"
              i18n="@@catalog_booking_selected_count_buy"
            >{selectedEventsCount, plural,=0{book 0 schedules bindingly} =1{book 1 schedule bindingly} other {book {{selectedEventsCount}} schedules bindingly}}</span>
          </ng-container>

          <!-- there are payable contents -->
          <ng-container *ngIf="totalToPay > 0">
            <span>{{getLabelButtonBuy()}}</span>
          </ng-container>
        </div>
      </button>

    </div>
  </ng-container>

  <ng-container *ngIf="mode === 'book-without-events'">

    <div class="actions">

      <ng-container *ngIf="isBlockEvent && totalAvailablePlaces > 0">
        <div class="places">
          <ng-container *ngIf="totalAvailablePlaces < 10; else manyFreePlaces">
            <span i18n="@@global_free_places">Free places</span>:&nbsp;{{totalAvailablePlaces}}
          </ng-container>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="totalAvailablePlaces">
        <div *ngSwitchCase="-1">
        </div>

        <div class="zero_places" *ngSwitchCase="0">
          <mat-icon svgIcon="alert-circle-outline"></mat-icon>
          <span i18n="@@no_available_places">There are currently no free places available</span>
        </div>

        <button
          *ngSwitchDefault
          type="button"
          mat-flat-button
          color="primary"
          class="booking-button"
          (click)="onBookingWithoutEvents()"
          [disabled]="bookingWithoutEventsButtonDisabled || !clickableBookButton || disabled"
        >{{labelBookmarkWithoutEvents()}}</button>

        <!-- assign only without booking -->        
        <button     
          *ngIf="(isBlockEvent && hasModules) && !selectedModuleOnly"       
          type="button"
          mat-stroked-button          
          class="booking-button"
          (click)="onBookingWithoutEvents(true)"
          [disabled]="bookingWithoutEventsButtonDisabled || !clickableBookButton || disabled"
        >
        <span i18n="@@bookmark_without_events">Bookmark event</span>
      </button>
      </ng-container>      

    </div>

  </ng-container>

  <ng-container *ngIf="mode === 'book-single'">

    <div class="actions">

      <button
        type="button"
        mat-flat-button
        color="primary"
        class="booking-button"
        [disabled]="!clickableBookButton || disabled"
        (click)="onBookingWithoutEvents()"
      >{{labelBookSingle()}}</button>

    </div>

  </ng-container>

  <ng-container *ngIf="mode === 'open'">

    <div class="actions">

      <button
        type="button"
        *ngIf="isBooked"
        mat-flat-button
        color="warn"
        class="booking-button"
        (click)="onCancelBooking()"
      >
        <span i18n="@@cancel_booking">Cancel booking</span>
      </button>

      <a
        *ngIf="isInMyAccount"
        mat-flat-button
        color="primary"
        class="booking-button"
        [routerLink]="contentUrl"
      >
        <span i18n="@@global_open">Open</span>
      </a>
    </div>

  </ng-container>
</div>


<!-- <div class="container" *ngIf="!(isLogged$ | async) ">
  <button
    type="button"
    mat-stroked-button
    class="rag-stroked-button booking-button"
    (click)="onLoginExternalCatalog()"
  >
    <span i18n="@@global_loginIn_Registration">Log in/Register</span>
  </button>


</div> -->

<ng-template #manyFreePlaces>
  <span i18n="@@global_many_free_places">many free places</span>
</ng-template>
