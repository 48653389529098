import { Component } from '@angular/core';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';
import { ControlContainer } from '@angular/forms';
import { DateHelper } from '../../../../core/date.helper';
import * as moment from 'moment';


@Component({
  selector: 'rag-profile-field-date',
  templateUrl: './profile-field-date.component.html',
  styleUrls: [ './profile-field-date.component.scss' ],
})
export class ProfileFieldDateComponent
  extends AbstractProfileFieldComponent<moment.Moment> {

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  protected cleanValue(): moment.Moment {
    return DateHelper.toMoment(this.field?.value);
  }

}
