<rag-dialog-header>
  <span i18n="@@user_profile_edit_credentials">Edit credentials</span>
</rag-dialog-header>

<form (ngSubmit)="onSubmit()" [formGroup]="formGroup" autocomplete="off">

  <mat-dialog-content>
    <rag-profile-field
      *ngIf="data.loginField as field"
      [field]="field"
    ></rag-profile-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@user_profile_current_password">Current password</mat-label>

      <input
        matInput
        type="password"
        formControlName="currentPassword"
        name="currentPassword"
        autocomplete="off"/>
    </mat-form-field>

    <rag-profile-field
      *ngIf="data.passwordField as field"
      [field]="field"
      [disabled]="!formGroup.get('currentPassword').value"
    ></rag-profile-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="button-wrapper">
      <button
        autofocus
        type="button"
        class="rag-stroked-button"
        mat-stroked-button
        [matDialogClose]="null"
      >
        <span i18n="@@global_cancel">Cancel</span>
      </button>

      <button
        [disabled]="!formGroup.valid"
        color="primary"
        mat-flat-button
        (click)="onSubmit($event)"
      >
        <span i18n="@@global_save">Save</span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
