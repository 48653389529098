import { Component, Inject } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoreModule } from 'src/app/core/core.module';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { HtmlDialogData } from './html-dialog.types';

@Component({
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogModule,
    CoreModule,
  ],
  selector: 'rag-html-dialog',
  templateUrl: './html-dialog.component.html',
  styleUrls: ['./html-dialog.component.scss']
})
export class HtmlDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HtmlDialogData
  ) { }
}
