<div class="mail-settings">
  <div class="tabs-group">
    <div
      data-cy="toggleTab"
      (click)="onToggleClick($event)"
      (keyup)="onToggleClick($event)"
      class="tab toggle"
      tabindex="1">

      <mat-icon [svgIcon]="iconName"></mat-icon>
      <span *ngIf="canPlanMessages$ | async" i18n="Text for the flying menu in mailbox|@@shedule_attachments">
        Schedule & Attachments
      </span>
      <span *ngIf="(canPlanMessages$ | async) === false" i18n="@@global_attachments">Attachments</span>
    </div>
  </div>
  <mat-tab-group
    (selectedIndexChange)="onSelectedIndexChanged($event)"
    [@openClose]="isOpen ? 'open' : 'closed'"
    [disableRipple]=true>

    <mat-tab *ngIf="canPlanMessages$ | async" data-cy="tabTime">
      <ng-template mat-tab-label>
        <span i18n="@@time_schedule">Time schedule</span>
      </ng-template>
      <rag-time-settings
        (timeChanged)="setTimeSettings($event)"
        [mailEntry]="mailEntry"></rag-time-settings>
    </mat-tab>

    <mat-tab data-cy="tabAttachments">
      <ng-template mat-tab-label>
        <span i18n="@@attachments">Attachments</span>
      </ng-template>
      <rag-mail-attachments
        [mailEntry]="mailEntry"
        [setAttachmentSettings]="setAttachmentSettings"></rag-mail-attachments>
    </mat-tab>

    <mat-tab [disabled]="(canSendReports$ | async) === false" data-cy="tabReports">
      <ng-template mat-tab-label>
        <span i18n="@@reports">Reports</span>
      </ng-template>
      <rag-reports-settings
        #ragReportsSettings
        [mailEntry]="mailEntry"
        [setReportsSettings]="setReportsSettings"></rag-reports-settings>
    </mat-tab>

  </mat-tab-group>
</div>
