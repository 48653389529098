import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { CatalogHelper } from 'src/app/core/catalog/catalog.helpers';
import { CatalogService } from 'src/app/core/catalog/catalog.service';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { ImageUrlHelper } from 'src/app/core/image-url-helper';
import { MediaMatchService } from '../../../core/media-match.service';
import { CardGrowOnEnterDynamic, ListItemsFadeIn } from '../../../core/animations';
import { TableControllerComponent } from '../../table/table-controller/table-controller.component';
import { TableColumnMenuService } from '../../table/table-column-menu/table-column-menu.service';
import { MergeHelper } from '../../../core/primitives/merge.helper';
import { takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { TableControllerTypes } from '../../table/table-controller/table-controller.types';
import { ContentFilterHelper } from '../../content-filter/content-filter.helper';
import { QueryParamsService } from '../../../core/storage/query-params.service';
import { ContentOverviewColumnMenuItemMap } from '../../../route/user/content-overview/content-overview.columns';
import { PrincipalService } from '../../../core/principal/principal.service';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { PermissionStates } from '../../../core/principal/permission.states';
import { SubscriptionHolder } from '../../../core/reactive/subscription-holder';
import { CachedSubject } from '../../../core/cached-subject';
import { CATALOG_OVERVIEW_MENU_COLUMNS, CatalogOverviewColumnMenuData } from './catalog-view.columns';
import { MatSort } from '@angular/material/sort';
import { LanguageHelper } from '../../../core/language.helper';
import { TableHelper } from '../../table/table-helper';
import { UrlHelper } from 'src/app/core/url.helper';
import { parseFilterOperator } from 'src/app/core/column-settings/column-filter.types';
import { arrayTraverseObjectsToMap, isNothing } from 'src/app/core/utils';
import moment from 'moment';
import { Categories } from 'src/app/core/categories/categories.types';

import {Core} from '../../../core/core.types';
import {AccountDesignService} from "../../../route/admin/account-design/account-design.service";
import { BotService } from '../../../core/bot.service';
import { CatalogBotHelper } from '../catalog.bot.helper';
import { CatalogBookingHelper } from 'src/app/core/catalog/catalog-booking.helper';
import * as uuid from 'uuid';
import { RedirectHelper } from 'src/app/core/redirect.helper';

@Component({
  selector: 'rag-catalog-view',
  templateUrl: './catalog-view.component.html',
  styleUrls: [ './catalog-view.component.scss' ],
  animations: [
    ListItemsFadeIn,
    CardGrowOnEnterDynamic,
  ],
})
export class CatalogViewComponent
  extends TableControllerComponent<Catalogs.CatalogEntry>
  implements OnInit, OnChanges {

    readonly isNothing = isNothing;
  readonly columnFilters$: Observable<TableControllerTypes.ColumnOptions<Catalogs.CatalogEntry>[]>;
  @Input() contents: Catalogs.CatalogEntry[];
  disableAnimations$ = this.mediaMatch.mediaQueryListeners$['prefers-reduced-motion'];
  /**
   * true, if the component should not display any filter and so on and can be managed by outside
   */
  @Input() embedded: boolean;
  @Input() isCards = true;
  @Input() maxItems: number;
  menuData: CatalogOverviewColumnMenuData;
  @Input() sortBy = 'eventDate';
  sortedData: Catalogs.CatalogEntry[];
  permissionStates: PermissionStates;
  showFilter: boolean = null;
  fullScreenSizeEnabled = false;

  private _columnFilters$ = new CachedSubject<TableControllerTypes.ColumnOptions<Catalogs.CatalogEntry>[]>(null);
  private _subscription = new SubscriptionHolder<void>(this);
  private _updateSortData = new EventEmitter<void>(true);
  private _settingsContext: string;
  private _categories: Map<number, Categories.Category>;

  constructor(
    private accountDesignService: AccountDesignService,
    private catalogService: CatalogService,
    private mediaMatch: MediaMatchService,
    private principalService: PrincipalService,
    private queryParamsService: QueryParamsService,
    private route: ActivatedRoute,
    private router: Router,
    private botService: BotService,
    private catalogBookingHelper: CatalogBookingHelper,
    tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);
    this.defaultSort = 'eventDate';

    this.columnFilters$ = this._columnFilters$.asObservable();
    this.sort = new MatSort();
  }

  ngOnInit() {
    this.catalogService.bookingState$
      .pipe(tap(bookingState => this.inputDisabled = bookingState.state === 'inprogress'))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.route.data
      // prevent clearing settingsContext in widgets
      .pipe(filter(data => data?.settingsContext != null))
      .pipe(map(data => {
        this._settingsContext = data.settingsContext;
        this.toggleViewMode(data.userSettings?.isCards ?? this.isCards);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this._updateSortData
      .pipe(debounceTime(200))
      .pipe(tap(() => this.updateSortedData(false)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.observeQueryParams();

    this.columnFilters$.pipe(map(filters => {
      if (filters == null) {
        return;
      }
      const queryParams = this.queryParamsService.queryParams;
      Object.keys(queryParams).forEach(key => {
        const _filter = filters.find(f => f.filter.identifier === key);
        if (_filter != null)  {
          const filterValue = queryParams[key];
          if (filterValue) {
            const actionPos = filterValue.lastIndexOf('$');
            if (actionPos > 0) {
              _filter.filter.action = parseFilterOperator(filterValue.substring(actionPos));
              _filter.filter.value = filterValue.substring(0, actionPos);
            } else {
              _filter.filter.value = filterValue;
            }
          }
        }
      });
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.accountDesignService.fullScreenSizeEnabled$
      .pipe(tap(fullScreenSizeEnabled => {
        this.fullScreenSizeEnabled = fullScreenSizeEnabled;
      }))
      .subscribe();    

    this.sort.direction = 'desc';
  }

  ngOnChanges(changes: SimpleChanges): void {

    if ( Object.prototype.hasOwnProperty.call(changes, 'contents') ) {

      this._subscription.observable = this.principalService.permissionStates$
        .pipe(map(permissions => this.updateRouteData(permissions, this.contents)));
    }

    if ( Object.prototype.hasOwnProperty.call(changes, 'maxItems') ) {
      this._updateSortData.next();
    }

    if ( !this.embedded && Object.prototype.hasOwnProperty.call(changes, 'embedded') ) {
      // delay until embedded has been set -> if false, observe route

      const catalogUUID = this.route.snapshot.paramMap.get('catalogUUID');
      this._subscription.observable = combineLatest([
        this.principalService.permissionStates$,
        this.catalogService.list(catalogUUID),
      ])
        .pipe(map(([ permissions, catalog ]) =>
          this.updateRouteData(permissions, catalog?.contents, catalog?.catalogs, catalog?.categories)));
    }
  }

  getDefaultPictureForType(catalogEntry: Catalogs.CatalogEntry): string {
    if (!this.permissionStates.showNewUserInterfaceCards) {
      return "assets/images/curriculum.jpg";
    }
    switch(catalogEntry.objType) {
      case Core.DistributableType.lms_curriculum:
        return "assets/images/card_curriculum.jpg"
      case Core.DistributableType.lms_offlineCnt:
        return "assets/images/card_event.jpg"
      case Core.DistributableType.lms_course:
        return "assets/images/card_event_online.jpg";
    }
    return "assets/images/curriculum.jpg";
  }

  getTemplate(
    tplDataLoading: TemplateRef<any>,
    tplDataEmpty: TemplateRef<any>,
    tplCards: TemplateRef<any>,
    tplTable: TemplateRef<any>,
  ): TemplateRef<any> {

    if ( this.isLoading || (this.embedded && this.contents == null) ) {
      return tplDataLoading;
    }
    //
    // if ( this.isDataEmpty ) {
    //   return tplDataEmpty;
    // }

    if ( this.isCards ) {
      return tplCards;
    }

    return tplTable;
  }

  imageUrlFor(catalogEntry: Catalogs.CatalogEntry) {
    return ImageUrlHelper.urlForPicture(catalogEntry?.pictureId, catalogEntry?.pictureUUID);
  }

  isInMyAccount(catalogEntry: Catalogs.CatalogEntry): boolean {
    return CatalogHelper.isInMyAccount(catalogEntry);
  }

  getQueryParams(urlTree: UrlTree | null): any | null {
    return UrlHelper.getQueryParams(urlTree);
  }

  getRouterLink(urlTree: UrlTree | null): string | null {
    return UrlHelper.getRouterLink(urlTree);
  }

  getUrl(content: Catalogs.CatalogEntry): string | null {
    let url: string;
    if ( this.isInMyAccount(content) ) {
      const backUrlParam = `backUrl=${encodeURIComponent(this.catalogService.catalogUrl)}`;
      url = `${UrlHelper.execUrlFor(content)}?${backUrlParam}`;
    } else {
      url = this.catalogService.getDetailsUrl(content);
    }

    return url || null;
  }

  getUrlForContent(content: Catalogs.CatalogEntry): UrlTree | null {

    const url = this.getUrl(content);
    if ( !url ) {
      return null;
    }

    return this.router.parseUrl(url);
  }

  onFilterChanged(filters: TableControllerTypes.ColumnOptions<Catalogs.CatalogEntry>[]): void {
    this.checkFilter();

    // update query params - does not modify url if embedded in widget
    const queryParams = ContentFilterHelper.asQueryParams(filters);

    this.queryParamsService.patchQueryParams(queryParams, !this.embedded);

    // trigger update of sorted data
    this._updateSortData.next();

    // expand filter panel after page load (with active/changed filter)
    this.showFilter ??= filters
      ?.map(o => ContentFilterHelper.isFilterActive(o.filter))
      ?.find(o => o.active && o.changed) != null;
  }

  onSortChanged(column: string, propagate = true): void {

    const menuItems = this.columnMenuData?.menuItems as ContentOverviewColumnMenuItemMap;
    if ( menuItems != null ) {
      menuItems.sortAttribute.options.filter.value = column;
    }

    this.sortBy = column;

    // trigger update of sorted data
    if (propagate) {
      this._updateSortData.next();
    }
  }

  toggleViewMode(isCardView?: boolean) {
    if ( (isCardView != null) && (this.isCards === isCardView) ) {
      // skip updates if state is unchanged
      return;
    }

    // if no explicit state is given, toggle the current value
    this.isCards = isCardView ?? !this.isCards;

    if (this._settingsContext && this.principalService.isLogged) {
      const payloadSettings = { isCards: this.isCards };
      this.principalService
        .saveUserSettings(this._settingsContext, JSON.stringify(payloadSettings))
        .subscribe();
    }

    // trigger update of sorted data
    this._updateSortData.next();
  }

  typeForCatalogEntry(catalogEntry: Catalogs.CatalogEntry): string {
    return DistributionTypeHelper.asText(catalogEntry.objType, catalogEntry.objSubType);
  }

  private setSortedData(
    data: Catalogs.CatalogEntry[],
  ): void {

    if ( this.maxItems > 0 ) {
      data = data.slice(0, this.maxItems);
    }

    this.sortedData = data;
  }

  private observeQueryParams() {
    this.queryParamsService.pick([ 'sortBy' ])

      // remove any filter action from param
      .pipe(map(params => ContentFilterHelper.parseFilterParam(params.sortBy).value))

      // update local state variables
      .pipe(tap(sortBy => this.onSortChanged(sortBy || this.defaultSort)))

      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private updateRouteData(
    permissions: PermissionStates,
    contents?: Catalogs.CatalogEntry[],
    catalogs?: Catalogs.CatalogTitle[],
    categories?: Array<Categories.Category>
  ): void {

    if ( !(contents?.length > 0) ) {
      // clear datasource and terminate early
      this.sortedData = [];
      this.setTableData(this.sortedData);
      return;
    }

    let contentsForBot = MergeHelper.cloneDeep(contents);
    CatalogBotHelper.addDetailPageUrls(contentsForBot, this.getUrl.bind(this));
    
    const formattedApiData = this.botService.formatAPIDataForBot(contentsForBot);
    this.botService.bypassInformation(
      `The catalog contains the following contents in JSON format
      
      ${formattedApiData}`, true);

    this._categories = arrayTraverseObjectsToMap(categories, item => item.id, item => item.subCategories);

    const menuData = MergeHelper.cloneDeep(CATALOG_OVERVIEW_MENU_COLUMNS);
    const menuItems = menuData.menuItems;

    const catalogFilter = menuItems.catalog.options;
    catalogFilter.dropDownOptions = (catalogs ?? []).reduce((pV, catalog) => {
      pV[catalog.uuid] = LanguageHelper.objectToText(catalog.title);
      return pV;
    }, {});

    const sortAttribute = menuItems.sortAttribute.options;
    sortAttribute.dropDownOptions = MergeHelper.cloneDeep(sortAttribute.dropDownOptionsOriginal);
    sortAttribute.filter.value = this.sortBy;
    sortAttribute.filter.defaultValue = this.defaultSort;

    let filters: TableControllerTypes.ColumnOptions<Catalogs.CatalogEntry>[];

    const tagsFilter = menuItems.tags.options;
    // did not find any reason why the filterStateAttribute does not get taken from columns definition :(
    tagsFilter.filterStateAttribute = 'tags';
    tagsFilter.dropDownOptions = TableHelper.tagsAsDropDownOptions(contents);

    if ( permissions.isLogged ) {

      filters = [
        menuItems.search.options,
        tagsFilter,
        menuItems.catalogState.options,
        menuItems.contentType.options,
        menuItems.priceRange.options,
        catalogFilter,
        sortAttribute,
      ];
    } else {

      filters = [
        menuItems.search.options,
        tagsFilter,
        menuItems.contentType.options,
        menuItems.priceRange.options,
        catalogFilter,
        sortAttribute,
      ];
    }

    filters = ContentFilterHelper.reduceFilterOptions(filters, contents);

    if (categories !== undefined && categories.length > 0) {
      const categoriesFilter = menuItems.categories.options;
      categoriesFilter.dropDownOptions = TableHelper.categoriesAsDropDownOptions(contents, this._categories);
      filters.push(categoriesFilter);
    }

    if ( !permissions.isLogged ) {
      // remove sorting by booking state for guests
      delete sortAttribute.dropDownOptions.catalogState;
    }

    this.permissionStates = permissions;
    this.setMenuData(menuData);
    this.setTableData(contents);

    this._columnFilters$.next(filters);
    this._updateSortData.emit();

    // allow modification
    this.inputDisabled = false;
  }

  private updateSortedData(propagate = true): void {

    // ensure filters are properly initialized
    this.checkFilter();

    if ( !(this.dataSource.data?.length > 0) ) {
      this.sortedData = [];
      return;
    }

    if ( this.embedded ) {
      // no sorting when embedded (widget mode)
      this.setSortedData(this.dataSource.filteredData ?? this.dataSource.data);
      return;
    }

    const eventDateFromNow = this.sortBy === 'eventDateFromNow';
    let sortAttribute = this.getValidSort(this.sortBy) ?? 'eventDate';

    this.onSortChanged(sortAttribute, propagate);
    if ( this.isCards ) {
      this.sort.direction = 'asc';
    }

    if ( sortAttribute === 'whenCreated' ) {
      this.sort.direction = 'desc';
    }
    sortAttribute = eventDateFromNow ? 'eventDate' : sortAttribute;
    this.setSort(this.sort, sortAttribute, () => {
      // need to delay applying sort, as setSort requires setTimeout to execute successfully
      let sortedData = this.dataSource
        .sortData(this.dataSource.filteredData ?? this.dataSource.data, this.sort);

      if (eventDateFromNow) {
        const oldEvents = sortedData.filter(data => {
          const offlineEventsViews = data.offlineEventsViews;
          return offlineEventsViews?.every(event => moment().isAfter(event.eventDate));
        });

        const upcomingEvents = sortedData.filter(data => {
          const offlineEventsView = data.offlineEventsViews;
          return offlineEventsView?.find(event => moment().isBefore(event.eventDate));
        });

        upcomingEvents.push(...oldEvents);
        sortedData = upcomingEvents;
      }
      this.setSortedData(sortedData);
    });

  }

  private proceesBooking(catalogEntry: Catalogs.CatalogEntry): Observable<void> {
      const bookingUUID = uuid.v4();  
  
      return this.catalogBookingHelper.bookWithEvents(
        false,
        catalogEntry,
        null,
        null,
        new Map<number, Set<number>>(),
        bookingUUID,
        null
      )
      .pipe(map(handlingBookingData => {
        if (handlingBookingData == null) {
          return;
        }
          
        RedirectHelper.clearRedirect();
        this.router.navigate(['bookings', handlingBookingData.bookingUUID], {
          replaceUrl: true,
        });
      }));
    }
}
