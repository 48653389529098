import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CoreModule } from 'src/app/core/core.module';
import { LegalConfirmationComponent } from './legal-confirmation.component';

@NgModule({
  declarations: [
      LegalConfirmationComponent,
  ],
  imports: [
    MatCheckboxModule,
    CoreModule,
    CommonModule,
  ],
  exports: [
      LegalConfirmationComponent,
  ],
})
export class LegalConfirmationModule {
}
