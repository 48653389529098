<button
  type="button"
  *ngIf="!hostHidden"
  mat-icon-button
  class="action action-locked"
  matTooltip="Locked"
  i18n-matTooltip="@@elearning_content_action_locked"
  matTooltipPosition="above"
  (click)="onCheckLock($event)"
  data-cy="buttonCurriculumLock"
>
  <mat-icon svgIcon="lock"></mat-icon>
</button>
