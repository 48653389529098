import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogBookingActionsComponent } from './catalog-booking-actions.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { LegalConfirmationModule } from 'src/app/route/user/catalog/legal-confirmation/legal-confirmation.module';


@NgModule({
  declarations: [ CatalogBookingActionsComponent ],
  exports: [ CatalogBookingActionsComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule,
    LegalConfirmationModule
  ],
})
export class CatalogBookingActionsModule {
}
