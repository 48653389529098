import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResolveStart, Router } from '@angular/router';
import { EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { ApiResponse, TrainWidgetResponse } from 'src/app/core/global.types';
import { ApiUrls } from '../api.urls';
import { CachedSubject } from '../cached-subject';
import { Core, ImageableContentReference, NumberedAnyObject } from '../core.types';
import { DisplayStatusHelper } from '../display-status-helper';
import { ViewData, ViewHelper } from '../view-helper';
import {
  Content,
  ContentServiceEvent,
  CourseInfo,
  NamedDistributable,
  UserContentAction,
  UserContentActionEnum,
  UserContentRelation,
  UserContentRelationEnum,
} from './content.types';
import { PrincipalService } from '../principal/principal.service';
import { State } from '../../app.state';
import { LearnerAccountTypes } from '../learner-account/learner-account.types';
import { CourseTypeHelper } from '../course-type-helper';
import { ImageUrlHelper } from '../image-url-helper';
import { SortHelper } from '../sort.helper';
import { DistributionTypeHelper } from '../distribution-type-helper';
import { InfoService } from '../info/info.service';
import { InfoType, MessageConstants, YesButton, YesNoButtons } from '../info/info.types';
import { AdminCoursesTypes } from '../admin-courses.types';
import { Attachment } from '../../component/attachments/attachments.types';


const ACCEPTABLE_CACHE_TIME = 1000 * 30;

@Injectable({
  providedIn: 'root',
})
export class ContentService {

  events$: Observable<ContentServiceEvent>;

  private accountData_ = new CachedSubject<ImageableContentReference[]>(null);
  private _events$: Subject<ContentServiceEvent>;

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private router: Router,
  ) {

    this._events$ = new Subject();
    this.events$ = this._events$.asObservable();

    this.router.events
      .pipe(filter(e => e instanceof ResolveStart))
      .pipe(tap(() => {
        if (this.accountData_.latestUpdate >= new Date().getTime() - ACCEPTABLE_CACHE_TIME) {
          console?.log('skipping reloading accountData cache > last time was:' +
            new Date(this.accountData_.latestUpdate));
        } else {
          console?.log('resetting accountData cache');
          this.accountData_.reset();
        }
      }))
      .subscribe();

    this.principalService.principal$
      .pipe(filter(principal => principal && principal.userId > 0))
      .pipe(distinctUntilChanged((a, b) => a.userId === b.userId))
      .pipe(filter(() => this.accountData_.value != null))
      .pipe(switchMap(() => this.fetchAccountData(true)))
      .subscribe();
  }

  get accountData$(): Observable<ImageableContentReference[]> {
    return this.accountData_.withoutEmptyValuesWithInitial()
      // just to make sure we don't trigger identical objects
      .pipe(distinctUntilChanged());
  }

  /**
   * populates {@link Content.eventDate}, {@link Content.eventDateUpcoming}, and {@link Content.eventInPast}.
   * This also sorts {@link Content.offlineEvents} by {@link OfflineContent.EventSchedule.eventDate} (ascending).
   *
   * @param content is assumed to be a top-level item (direct or indirect assignment)
   */
  static calculateEventDates(content: ImageableContentReference): void {
    const currentDate = new Date().getTime();

    switch ( content?.objType ) {
      case Core.DistributableType.lms_curriculum:

        const offlineContents = ContentService.findOfflineContents(content)
          .map(item => ContentService.calculateEventDatesItem(item, currentDate))
          .filter(item => item?.eventDate > 0)
          .sort((a, b) => SortHelper.compareDates(a.eventDate, b.eventDate, true));

        // find the first upcoming event that has a valid eventDateUpcoming
        content.eventDateUpcoming = offlineContents
          .find(item => !item.eventInPast && (item.eventDateUpcoming > 0))
          ?.eventDateUpcoming;

        // if eventDateUpcoming is not defined: find the earliest event that has an eventDate
        content.eventDate = content.eventDateUpcoming ?? offlineContents
          .find(item => (item.eventDate > 0))
          ?.eventDate;

        if ( offlineContents.length === 0 ) {

          // hide items without offline contents from filter results
          content.eventInPast = null;
        } else {

          // find any included event that is not flagged as "eventInPast"
          content.eventInPast = offlineContents
            .find(item => !item.eventInPast) == null;
        }

        // todo check if TF-2317 requires matching on curricula

        break;

      case Core.DistributableType.lms_offlineCnt:
        ContentService.calculateEventDatesItem(content, currentDate);
        break;

      default:

        // ignored
        return;
    }
  }

  static calculateDuration(content: ImageableContentReference): string {
    if ( content.durationSum > 0 ) {
      const hours = Math.floor(content.durationSum / 60);
      const minutes = content.durationSum % 60;
      const hourUnit = State.language === 'de' ? ' Std.' : ' h';
      const minUnit = State.language === 'de' ? ' Min.' : ' m';
      if ( hours && minutes ) {
        content.calculatedDuration = hours + ':' + ('00' + minutes).slice(-2) + hourUnit;
      } else if ( hours ) {
        content.calculatedDuration = hours + hourUnit;
      } else if ( minutes ) {
        content.calculatedDuration = minutes + minUnit;
      } else {
        content.calculatedDuration = '';
      }
    } else {
      content.calculatedDuration = '';
    }
    return content.calculatedDuration;
  }

  static filterHiddenAndIndirectAssignments(contents: ImageableContentReference[]): ImageableContentReference[] {
    if ( contents == null ) {
      return [];
    }

    return contents.filter(content => {
      const viewData = ViewHelper.getViewData(content);
      if ( viewData.hidden === true ) {
        // ignore explicitly hidden items
        return false;
      } else if ( (content.hasDirectAssignment === false) && (viewData.parent != null) ) {
        // hide indirectly assigned contents with valid parent
        return false;
      } else if ( (viewData.parent == null) && (viewData.hiddenParent != null) && (content.objType !== Core.DistributableType.lms_curriculum) ) {
        // hide child items with explicitly hidden parents
        return false;
      }
      // all others are valid
      return true;
    });
  }

  static getContentById(
    contents: ImageableContentReference[],
    contentId: number | null,
    deep = true,
    types: Core.DistributableType[] = null,
  ): ImageableContentReference | null {

    if ( !(contentId > 0) || !(contents.length > 0) ) {
      // abort, if no valid input given
      return null;
    }

    const acceptAnyType = !(types?.length > 0);
    const nullMatches = acceptAnyType || types.includes(null);
    const result = contents
      .find(entry => (entry?.id === contentId) &&
        (acceptAnyType || ContentService.isAnyType(entry.objType ?? entry.type, types, nullMatches)));
    if ( result != null ) {
      // yay, results :D
      return result;
    }

    if ( !deep ) {
      // no result on first layer -> abort if shallow search
      return null;
    }

    // try to find any child object
    const items = contents
      .flatMap(entry => entry?.items ?? []);
    return ContentService.getContentById(items, contentId, true, types);
  }

  static getContentHref(content: ImageableContentReference, run: boolean = null): string {
    return ContentService.getContentHrefImpl(content, run);
  }

  static getDueBy(con: ImageableContentReference) {
    con.dueBy = con.lastValidUntil;
  }

  static getImage(con: ImageableContentReference): string {
    // when pictureUUID is available get picture from train else get placeholder pictures
    const url = ImageUrlHelper.urlForPicture(con.pictureId, con.pictureUUID);
    if ( url != null ) {
      return url;
    } else if ( con.objType === Core.DistributableType.lms_curriculum ) {
      return 'assets/images/curriculum.jpg';
    } else if ( con.objType === Core.DistributableType.lms_course ) {
      return 'assets/images/course.jpg';
    } else if ( con.objType === Core.DistributableType.lms_offlineCnt && con.objSubType === Core.CourseType.Virco ) {
      return 'assets/images/virtualClassroom.jpg';
    } else if ( con.objType === Core.DistributableType.lms_offlineCnt ) {
      return 'assets/images/offlineContent.jpg';
    } else {
      return 'assets/images/context.jpg';
    }
  }

  static getItemProgress(content: ImageableContentReference | null): string | null {
    ContentService.calculateProgress(content);
    const doneCount = content?.doneCount;
    const totalCount = content?.items?.length;
    if ((doneCount > 0) && (totalCount > 0) && (doneCount < totalCount)) {
      return $localize`:@@curriculum_card_progress_of:
        (${doneCount} of ${totalCount})
      `;
    }
    return null;
  }

  static getMinutes(content: ImageableContentReference): number {
    if ( !content || Object.prototype.hasOwnProperty.call(content, 'durationSum') ) {
      return content.durationSum;
    }

    let min = 0;
    if ( !content.duration ) {
      content.durationSum = min;
      return min;
    }

    if ( content.durationDimension !== 'hours' ) {
      min = Number(content.duration);
    } else {
      let hoursAndMinutes;
      try {
        hoursAndMinutes = content.duration.split(':');
        min += Number(hoursAndMinutes[0]) * 60;
        min += Number(hoursAndMinutes[1]);
      } catch ( e ) {
        // ToDo Snackbar with error
        console.log('Duration wrong format!', e);
      }
    }

    content.durationSum = min;
    return min;
  }

  static isAnyType(
    type: Core.DistributableType | null,
    types: Core.DistributableType[],
    nullMatches: boolean,
  ): boolean {

    if (nullMatches && (type == null)) {
      return true;
    }

    return types.includes(type);
  }

  static isAssignmentMatching(con: Content, query: string): boolean {
    if ( query === 'booked' ) {
      return con.booked;
    }
    const assignmentType = (con || {}).assignmentType || '';
    if ( assignmentType === 'both' || query == null || query === '' ) {
      return true;
    }
    return assignmentType.toLowerCase() === query;
  }

  static isCourseLocked(content: ImageableContentReference): boolean {
    return content.locked &&
      !( // item is not locked when:
        content.repetitions === -2 && // "without tracking"
        DisplayStatusHelper.isStatusGreen(content.displaystatus)
      );
  }

  static isExecutable(content: Content) {
    const viewData = ViewHelper.getViewData(content);

    if ( !(viewData && content.fullhref && viewData.parent) ) {
      // parent and fullhref are required
      return;
    }

    const parent = viewData.parent;
    const parentData = ViewHelper.getViewData(parent);
    viewData.executableItem = parent;
    parentData.sco = content;
  }

  static isTypeMatching(con: Content, query: string): boolean {
    if ( con == null || query == null || query === '' ) {
      return true;
    }

    // if no type is selected return all
    if ( con.objType === Core.DistributableType.lms_curriculum && query === 'Curriculum' ) {
      return true;
    } else if (con.objType === Core.DistributableType.lms_course && con.courseType === Core.CourseType.Recording && query === 'Recording') {
      return true;
    } else if ( con.objType === Core.DistributableType.lms_course && query === 'Course' ) {
      return con.courseType !== Core.CourseType.Recording;

    } else if ( con.objType === Core.DistributableType.lms_offlineCnt && con.objSubType === Core.CourseType.Virco && query === 'Virtual Classroom' ) {
      return true;
    } else if ( con.objType === Core.DistributableType.lms_offlineCnt && con.objSubType === Core.CourseType.Seminar && query === 'Face-to-Face event' ) {
      return true;
    } else if (con.objType === Core.DistributableType.lms_offlineCnt && con.objSubType === Core.CourseType.Hybrid && query === 'Hybrid event') {
      return true;
    }

    return false;
  }

  static shouldCheckCurriculumCompletion(
    content: ImageableContentReference | null,
  ): boolean {

    if ( content == null ||
      content.objType !== Core.DistributableType.lms_curriculum ||
      content.currentAccountStatus !== 'valid'
    ) {
      // not a valid curriculum -> no check for completion
      return false;
    }

    if ( DisplayStatusHelper.isStatusGreen(content.status) ) {
      // content is done already -> nothing to do
      return false;
    }

    if ( content.hasAllRequiredItemsDone != null ) {
      // calculated by train classic -> accept as correct
      return content.hasAllRequiredItemsDone;
    }

    if ( !(content.items?.length > 0) ) {
      // a curriculum without items? -> should be green on assignment
      return true;
    }

    return content.items
      .filter(item => {

        if ( item.mandatory !== true ) {
          // item is optional -> not one of the relevant items
          return false;
        }

        // include any item that is not yet "green"
        return !DisplayStatusHelper.isStatusGreen(item.status);
      })
      // are there any items that have to be done? -> if no, then the user may complete the curriculum
      .length === 0;
  }

  private static calculateEventDatesItem(content: ImageableContentReference, currentDate: number): ImageableContentReference {
    if ( !(content?.objType === Core.DistributableType.lms_offlineCnt) ) {
      // can only calculate for offline contents
      return content;
    }

    const items = content.offlineEvents ?? content.items ?? [];
    if (items.length === 0) {
      content.eventInPast = false;
      return;
    }

    // TODO: replace "offlineEvents" with "items" in train-api & in front-end
    content.items = items.sort((a, b) => SortHelper.compareDates(a.eventDate, b.eventDate, true));

    const earliestDate = items[0].eventDate;

    // find minimal and closest upcoming start dates
    const result = items.reduce((pV, item) => {
      const eventDate = item.eventDate;
      const eventDateUntil = item.eventDateUntil;

      const inFuture = (eventDate > currentDate);
      const isRunning = !inFuture && (eventDateUntil > currentDate);
      if ( isRunning && ((pV.running == null) || (pV.running > eventDate)) ) {
        // find the earliest running event to display
        pV.running = eventDate;

      } else if ( inFuture && ((pV.upcoming == null) || (pV.upcoming > eventDate)) ) {
        // find the closest start date from now
        pV.upcoming = eventDate;

      } else if ( !inFuture && ((pV.latest == null) || (pV.latest < eventDate)) ) {
        // find the closest start date before now
        pV.latest = eventDate;
      }

      if (inFuture || isRunning) {
        // toggle inPast to "false", if there is any offlineEvent that ends after the current date
        pV.inPast = false;
      }

      return pV;
    }, { inPast: true, running: null, upcoming: null, latest: null });

    content.eventDate = result.running ?? result.upcoming ?? result.latest ?? earliestDate;
    content.eventDateUpcoming = result.upcoming;
    // take empty items as "needs to select" -> upcoming
    content.eventInPast = result.inPast;
    return content;
  }

  private static findOfflineContents(
    content: ImageableContentReference, visited: NumberedAnyObject<boolean> = {}): ImageableContentReference[] {

      if ( !(content?.objType === Core.DistributableType.lms_curriculum) ) {
        // can only calculate for offline contents
        return [];
      }

      const id = content.id;
      if ( Object.prototype.hasOwnProperty.call(visited, id) ) {
        // prevent infinite recursion
        return [];
      }
      visited[id] = true;

      // todo implement me!
      return (content.items ?? [])
        .flatMap(item => {
          switch ( item.objType ?? '' ) {
            case Core.DistributableType.lms_curriculum:
              // recursive finder
              return ContentService.findOfflineContents(item, visited);

            case Core.DistributableType.lms_offlineCnt:
              return [ item ];

            default:
              // ignore others
              return [];
          }
        })
        .filter(item => item != null);
  }

  private static getContentHrefImpl(content: ImageableContentReference, run: boolean, rnd: number = Math.random()): string {
    if ( run && !content ) {
      return '/run';
    }

    const viewData = ViewHelper.getViewData(content);
    const parent = viewData?.parent as ImageableContentReference;
    const type = content?.objType;
    if ( !(type || parent) ) {
      // must have some info to generate links
      return null;
    }

    if ( viewData.rnd === rnd ) {
      // already visited
      if ( console && console.warn ) {
        console.warn('maybe found circular dependency?', content, rnd);
      }
      return null;
    }
    viewData.rnd = rnd;

    if ( Object.prototype.hasOwnProperty.call(viewData, 'contentHref') && (viewData.contentHrefRun === run) ) {
      return viewData.contentHref;
    }

    viewData.contentHrefRun = run;
    if ( (type === undefined) && parent && (parent.objType === Core.DistributableType.lms_course) ) {
      const parentHref = ContentService.getContentHrefImpl(parent, false, rnd);
      if ( run && !ContentService.isCourseLocked(content) ) {
        const courseType = LearnerAccountTypes.courseTypeFactory(parent.courseType);
        switch ( courseType ) {
          case Core.CourseType.ToDo:
            viewData.contentHref = parentHref + '/todo/' + (parent.curItemId || parent.id);
            break;
          default:
            if (parentHref == null) {
              viewData.contentHref = 'sco/' + content.id;
            } else {
              viewData.contentHref = parentHref + '/sco/' + content.id;
            }
        }
      } else {
        viewData.contentHref = parentHref;
      }
      return viewData.contentHref;

    } else if ( type === Core.DistributableType.lms_course ) {
      const courseType = LearnerAccountTypes.courseTypeFactory(content.courseType);
      if ( run && !ContentService.isCourseLocked(content) ) {
        const parentHref = ContentService.getContentHrefImpl(parent, true, rnd);
        switch ( courseType ) {
          case Core.CourseType.ToDo:
            viewData.contentHref = parentHref + '/todo/' + (content.curItemId || content.id);
            break;
          default:
            viewData.contentHref = parentHref + '/sco/' + content.id;
        }
        return viewData.contentHref;
      } else if ( parent ) {
        return ContentService.getContentHrefImpl(parent, false, rnd);
      } else {
        switch ( courseType ) {
          case Core.CourseType.ToDo:
            // locked state is handled in LearnerCourseTodoComponent
            viewData.contentHref = '/run/todo/' + content.id;
            break;
          default:
            viewData.contentHref = '/content/' + content.id;
        }
        return viewData.contentHref;
      }

    } else if ( type === Core.DistributableType.lms_offlineCnt ) {
      // offline content does not have a list view
      const parentHref = ContentService.getContentHrefImpl(parent, true, rnd);
      if ( parentHref === '/run' ) {
        viewData.contentHref = '/run/offline/' + content.id;
      } else {
        viewData.contentHref = parentHref + '/offline/' + content.curItemId;
      }
      return viewData.contentHref;

    } else if ( type === Core.DistributableType.lms_context ) {
      const parentHref = ContentService.getContentHrefImpl(parent, true, rnd);
      if ( content.description && content.description !== 'n/a' ) {
        // only
        viewData.contentHref = parentHref + '/context/' + (content.id || content.curItemId);
      }
      return viewData.contentHref;

    } else if ( type === Core.DistributableType.lms_curriculum ) {
      const parentHref = ContentService.getContentHrefImpl(parent, false, rnd);
      if ( parentHref == null ) {
        viewData.contentHref = '/content/' + content.id;
      } else {
        viewData.contentHref = parentHref;
      }
      return viewData.contentHref;

    } else if ( console && console.warn ) {
      console.warn('failed to find content href for ', content.objType, content.id);
    }
  }

  private static searchFirstExecutableItemImplFilter(
    content: ImageableContentReference,
    parent: ImageableContentReference,
    acceptGreen: boolean): boolean {

      if ( !content ) {
        return false;
      }

      const hasOwnRepetitions = parent && Object.prototype.hasOwnProperty.call(parent, 'repetitions');
      const repetitions = hasOwnRepetitions && parent.repetitions;
      if ( DisplayStatusHelper.isStatusGreen(content.displaystatus) ) {
        return acceptGreen && ((!hasOwnRepetitions) || (repetitions === -2) || (repetitions === -3));
      } else {
        return repetitions !== 0;
      }
  }

  static calculateProgress(con: ImageableContentReference) {
    if (Object.prototype.hasOwnProperty.call(con, 'progress') &&
      Object.prototype.hasOwnProperty.call(con, 'doneCount')) {
      return;
    }

    con.progress = 0;
    con.doneCount = 0;
    // TF-1874
    if (CourseTypeHelper.isTodo(con)) {
      if (DisplayStatusHelper.isStatusYellow(con.displaystatus)) {
        con.progress = 50;
        return;
      } else if (DisplayStatusHelper.isStatusGreen(con.displaystatus)) {
        con.progress = 100;
        return;
      }
    }

    const items = con.items ?? con.offlineEvents;
    if ((items?.length > 0)) {
      const finishedCount = items
        .filter(item => DisplayStatusHelper.isStatusGreen(item.displaystatus ?? item.displayStatus))
        .length;
      con.doneCount = finishedCount;
      con.progress = Math.round((finishedCount / items.length) * 100);
    }
  }

  checkCurriculumCompletion(
    curriculum: ImageableContentReference,
  ): Observable<void> {

    const curriculumId = curriculum?.id;
    if ( !(curriculumId > 0) || ! DistributionTypeHelper.isCurriculum(curriculum.objType) ) {
      // ignore anything that is no curriculum
      return EMPTY;
    }

    const url = ApiUrls.getKey('LearnerCompleteCurriculum')
      .replace(/{curriculumId}/gmi, String(curriculum.id));
    return this.infoService.showMessage($localize`:@@should_curriculum_be_closed:
      All mandatory items have been successfully completed.<br>
      Now you have the possibility to mark the curriculum as finished, and start the validity phase
      according to the curriculum configuration.<br>
      After closing, no further access to the contents will be tracked.<br>
      By choosing "yes", you will finish the curriculum. If you do not yet wish to do this, you can close this dialog
      by clicking on "no".
    `, {
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
      buttons: YesNoButtons,
    })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => this.http.post<any>(url, null)))
      .pipe(map(() => void (0)));
  }

  setCourseSupervisor(course: AdminCoursesTypes.Course, userId: number) {
    const url = ApiUrls.getKey('AdminCourseSetSupervisor');

    const data: UserContentRelation = {
      userId,
      objId: course.courseId,
      objType: Core.DistributableType.lms_course,
      relation: UserContentRelationEnum.SUPERVISOR,
    };

    return this.http.post<ApiResponse<UserContentRelation>>(url, data)
      .pipe(map(response => response.relation))
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(tap(relation => this._events$.next({
        type: 'SetCourseSupervisor',
        courseId: course.id,
        payload: relation
      })))
      .pipe(tap(_ => {
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }));
  }

  removeCourseSupervisor(relationUUID: string) {
    const url = ApiUrls.getKey('AdminCourseRemoveSupervisor')
      .replace('{relationUUID}', relationUUID);

    return this.http.delete<ApiResponse<UserContentRelation>>(url)
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(tap(_ => this._events$.next({
        type: 'RemoveCourseSupervisor',
        payload: relationUUID
      })))
      .pipe(tap(_ => {
        this.infoService.showMessage($localize`:@@general_delete_success:The target has been deleted.`,
          { infoType: InfoType.Success });
      }));
  }

  fetchAccountData(reset = false): Observable<ImageableContentReference[]> {
    if ( reset ) {
      this.accountData_.reset();
    }
    if ( this.accountData_.queryStart() ) {
      this.http.get(ApiUrls.getKey('AccountV4'))
        .pipe(map((response: any) => this.buildReferences(response.data || [])))
        .pipe(tap(this.accountData_.next), catchError(this.accountData_.nextError))
        .subscribe();
    }
    return this.accountData$;
  }

  fetchCourseInfo(courseId: number | string): Observable<CourseInfo> {
    if ( !(courseId > 0) ) {
      throw new Error('invalid courseId "' + courseId + '"');
    }
    const query = ApiUrls.getKeyWithParams('LearnerCourseInfo', '1')
      .replace(/{courseId}/gm, '' + courseId);
    return this.http.get<CourseInfo>(query);
  }

  fetchOpenLearnContents<T>(contentFactory: (content: any) => T | null): Observable<T[]> {
    return this.http.get(ApiUrls.getKey('AccountV4'))
      .pipe(
        map((response: TrainWidgetResponse) => {

          const parse: (acc: Array<T>, content: any) => void = (acc: Array<T>, content: any) => {
            if ( content.objType === Core.DistributableType.lms_curriculum) {
              for ( const item of content.items ) {
                parse(acc, item);
              }
            } else {
              const instance = contentFactory(content);
              if ( instance != null ) {
                // todo: check if this course already exists
                acc.push(instance);
              }
            }
          };

          const results = new Array<T>();
          for ( const item of response.data ) {
            parse(results, item);
          }
          return results;
        }),
      );
  }

  finishUnfinished(itemIds: number[]): Observable<any> {
    // ToDo show warning with confirmation that all runningSCOs are going to be closed
    const queries: Observable<any>[] = [];
    // for each running SCO call API to close open session
    itemIds.forEach(itemId => {
      if ( itemId > 0 ) {
        queries.push(this.http.get(ApiUrls.getKey('FinishUnfinished').replace(/{itemId}/, String(itemId))));
      }
    });
    // wait for all queries to complete before continuing
    return forkJoin(queries).pipe((response) => response);
  }

  getContentsByType(objType: Core.DistributableType): Observable<NamedDistributable[]> {
    const url = ApiUrls.getKey('AdminGetContentsByType')
      .replace(/{distType}/gi, String(objType));

    return this.http.get<ApiResponse<NamedDistributable[]>>(url)
      .pipe(map(response => {
        response.contents.every(c => c.objectType = objType);
        return response.contents;
      }));
  }

  getDuration(content: ImageableContentReference) {
    if ( !content || Object.prototype.hasOwnProperty.call(content, 'durationSum') ) {
      return;
    }

    let duration = ContentService.getMinutes(content);
    if ( content.objType === Core.DistributableType.lms_curriculum ) {
      duration = Number(duration);
      if ( this.accountData_.value && !(content.items?.length > 0) ) {
        // inject referenced curriculum -> should not be required, but keep the fallback anyway
        const curriculum = ContentService
          .getContentById(this.accountData_.value, content.id, false, [Core.DistributableType.lms_curriculum]);
        Object.assign(content, curriculum);
      }
      if ( content.items ) {
        duration += this.getDurationOfChildren(content.items);
      }
    }
    content.durationSum = duration;
  }

  getFirstSco(content: ImageableContentReference): ImageableContentReference {
    if ( !(content && content.items && content.objType === Core.DistributableType.lms_course) ) {
      return null;
    }
    const viewData = ViewHelper.getViewData(content);
    const isToDoItem = CourseTypeHelper.isTodo(content);

    let firstItem: Content;
    content.items.forEach(item => {
      // const itemData = ViewHelper.getViewData(item);
      // itemData.parent = content;

      if ( !((isToDoItem || item.fullhref) && (item.locked !== true)) ) {
        // ignore locked items and items missing fullhref
        // todo check if item is locked when green!
        return;
      }

      firstItem = firstItem || item;
      if ( !viewData.sco && !DisplayStatusHelper.isStatusGreen(item.displaystatus) ) {
        viewData.sco = item;
      }
    });

    if ( firstItem && !viewData.sco ) {
      // in case all SCOs are already completed return the first one
      viewData.sco = firstItem;
    }
    return viewData.sco;
  }

  searchFirstExecutableItem(allContents: ImageableContentReference[], parent: ImageableContentReference, con: ImageableContentReference = null): Content {
    const viewData = ViewHelper.getViewData(con ?? parent);
    this.searchFirstExecutableItemImpl(allContents, parent, con, false);
    if ( viewData.executableItem == null ) {
      this.searchFirstExecutableItemImpl(allContents, parent, con, true);
    }
    return viewData.executableItem;
  }

  handleUserContentAction(action: UserContentAction) {

    let task: Observable<UserContentAction>;
    let successMessage;

    switch (action.action) {
      case UserContentActionEnum.READ_ACKNOWLEDGE:
        successMessage = $localize`:@@content_read_confirm_success:You have confirmed!`;
        task = this.readDocumentAcknowledge(action);
        break;
      case UserContentActionEnum.NEED_CLARIFICATION:
        successMessage = $localize`:@@content_read_confirm_with_clarification_success:Your inquiry has been sent successfully.`;
        task = this.requestClarification(action);
        break;
      default:
        console?.warn('unknown content action', action.action);
    }

    if (task == null) {
      return;
    }

    task
      .pipe(delay(500)) // ?????
      .pipe(tap(_ => this.fetchAccountData(true)))
      .pipe(tap(_ => {
        this.infoService.showMessage(successMessage, {
          infoType: InfoType.Success
        });
      }))
      .subscribe();
  }

  getImageableContentReference(objType: Core.DistributableType, objId: string): Observable<ImageableContentReference<any>> {

    const url = ApiUrls.getKey('ImageContentReference')
      .replace('{objType}', objType).replace('{objId}', objId);
    return this.http.get<ApiResponse<ImageableContentReference>>(url)
      .pipe(map(response => response.reference));
  }

  fetchAttachmentsForContent(objType: Core.DistributableType, objId: number): Observable<Array<Attachment>> {
    const url = ApiUrls.getKey('AttachmentsForContent')
      .replace('{objType}', objType)
      .replace('{objId}', String(objId));
    return this.http.get<ApiResponse<Array<Attachment>>>(url).pipe(map(response => response.attachments));
  }

  private readDocumentAcknowledge(action: UserContentAction): Observable<UserContentAction> {
    const url = ApiUrls.getKey('UserContentAction');
    const payload: UserContentAction = {
      objId: action.objId,
      objType: action.objType,
      action: UserContentActionEnum.READ_ACKNOWLEDGE
    };
    return this.http.post<ApiResponse<UserContentAction>>(url, payload)
      .pipe(map(response => response.action));
  }

  private requestClarification(action: UserContentAction): Observable<UserContentAction> {
    const url = ApiUrls.getKey('UserContentAction');
    const payload: UserContentAction = {
      objId: action.objId,
      objType: action.objType,
      action: UserContentActionEnum.NEED_CLARIFICATION,
      text: action.text
    };
    return this.http.post<ApiResponse<UserContentAction>>(url, payload)
      .pipe(map(response => response.action));
  }

  /**
   * Appends ViewData to each content element including setting its root parent property
   * @param accountData array of all my learning contents
   */
  private buildReferences(accountData: Array<ImageableContentReference>): Array<ImageableContentReference> {
    // collect curricula
    const curricula: NumberedAnyObject<Content> = accountData
      .reduce((pV, content) => {
        if ( content.items == null ) {
          // no need for any further steps
          return pV;
        }

        const isACurriculum = DistributionTypeHelper.isCurriculum(content.objType);
        if ( isACurriculum ) {
          // collect any curriculum for later use
          pV[content.id] = content;
        }

        const contentData = ViewHelper.getViewData(content);
        const contentHidden = contentData.hidden = content.hideInLearnerAccount === true;

        content.items.forEach(item => {
          const viewData = ViewHelper.getViewData(item);
          if ( isACurriculum ) {
            // store immediate parent to generate the tree
            viewData.curriculum = content;
          }

          if ( content.hasDirectAssignment !== false ) {
            // add parent for directly assigned contents
            if ( contentHidden ) {
              viewData.hiddenParent = content;
            } else {
              viewData.parent = content;
            }
          }
        });

        return pV;
      }, {});

    // copy items and view data to curriculum references
    Object.values(curricula)
      .flatMap(curriculum => curriculum.items)
      .filter(content => DistributionTypeHelper.isCurriculum(content.objType))
      .forEach(item => {
        const curriculumId = item.id;
        // find the actual curriculum
        const curriculum = curricula[curriculumId];
        if ( curriculum == null ) {
          // should not happen -.-
          ViewHelper.getViewData(item).brokenAssignment = true;
          if ( console?.warn != null ) {
            console.warn('found curr-in-curr without curriculum data', item);
          }
          return;
        }

        // copy children to reference
        item.items = curriculum.items;
        // create common object for view data
        item.$view = curriculum.$view = {
          ...item.$view ?? {},
          ...curriculum.$view ?? {},
        };
      });

    // collect curriculum items
    let curriculumItems = Object.values(curricula)
      .flatMap(curriculum => curriculum.items);

    let iteration = 0;
    do {
      // find any curriculum that is missing its parent
      curriculumItems = curriculumItems
        .reduce((pV, content) => {
          const viewData = ViewHelper.getViewData(content);
          const curriculum = viewData.curriculum;
          if ( viewData.parent != null ) {
            if ( (viewData.curriculum == null) && (console?.warn != null) ) {
              console.warn('found item without immediate parent:', content);
            }

            // we already have a valid parent -> skip
            return pV;
          }

          const parentData = ViewHelper.getViewData(curriculum);
          if ( parentData.hiddenParent != null ) {
            // pass any hiddenParent to children
            viewData.hiddenParent = parentData.hiddenParent;
          }

          let parent = parentData?.parent;
          if ( (parent == null) && (curriculum?.hideInLearnerAccount !== true) ) {
            // use immediate parent if it is not flagged hideInLearnerAccount
            parent = curriculum;
          }

          if ( parent == null ) {
            // leave for later iteration
            pV.push(content);
          } else {
            // add parent to current curriculum
            viewData.parent = parent;
            // copy parent to children as well
            content.items?.forEach(item => ViewHelper.getViewData(item).parent = parent);
          }
          return pV;
        }, []);

      if ( curriculumItems.length === 0 ) {
        // terminate search if all parents are defined
        iteration = 10;
      }
    } while ( iteration++ < 10 );

    if ( (console?.warn != null) && (curriculumItems.length > 0) ) {
      console.warn('failed to determine parents for items (early termination? hidden parent?)', curriculumItems);
    }

    return accountData;
  }

  private getDurationOfChildren(contents: ImageableContentReference[]): number {

    let sum = 0;
    contents.forEach(item => {
      // const viewData = ViewHelper.getViewData(item);
      // viewData.parent = parent;
      if ( item.duration && !item.iteratedOverForDuration ) {
        const minutes = ContentService.getMinutes(item);
        sum += minutes;
      } else if ( item.objType === Core.DistributableType.lms_curriculum ) {
        this.getDuration(item);
        if ( item.durationSum > 0 ) {
          sum += item.durationSum;
        }
      }
    });
    return Number(sum);
  }

  private searchFirstExecutableItemImpl(
    allContents: ImageableContentReference[],
    parent: ImageableContentReference,
    con: ImageableContentReference = null,
    acceptGreen: boolean,
    rnd: number = Math.random()
  ): void {

    const parentData = ViewHelper.getViewData(parent);
    if ( !parentData ) {
      // parent is required!
      return;
    }

    if ( con == null ) {
      // set con for initial execution
      con = parent;
    }

    const viewData = ViewHelper.getViewData(con);
    if ( viewData.sco || viewData.executableItem ) {
      // already has a valid executable content
      return;
    }

    if ( DistributionTypeHelper.isCurriculum(con.objType) ) {
      // prevent checking curriculum more than once - for this call
      if ( viewData.searchFirstExecutableItemVisited === rnd ) {
        return;
      }
      viewData.searchFirstExecutableItemVisited = rnd;

      if ( (allContents?.length > 0) && (con.items == null) ) {
        // content is curriculum without items (child curriculum)
        const fullVersionContent = ContentService
          .getContentById(allContents, con.id, false, [Core.DistributableType.lms_curriculum]) ?? {};
        con = { ...con, ...fullVersionContent };
      }
    }

    if ( (con.locked === true) || !ContentService.searchFirstExecutableItemImplFilter(con, con, acceptGreen) ) {
      // ignore locked and completed items
      return;
    }

    const contentType = con.objType;
    if ( contentType === Core.DistributableType.lms_offlineCnt || contentType === Core.DistributableType.lms_context ) {
      // offline content is executable directly
      this.updateExecutableItem(viewData, con);

    } else if ( contentType === Core.DistributableType.lms_course ) {
      if ( !con.items ) {
        // courses should contain executable items!
        return;
      }

      const firstItem: ImageableContentReference = this.getFirstSco(con);
      if ( ContentService.searchFirstExecutableItemImplFilter(firstItem, con, acceptGreen) ) {
        this.updateExecutableItem(viewData, con);

      } else if ( firstItem != null ) {
        const itemGreen = DisplayStatusHelper.isStatusGreen(firstItem.displaystatus);
        const contentGreen = DisplayStatusHelper.isStatusGreen(con.displaystatus);
        if ( itemGreen !== contentGreen ) {
          // inconsistent data!
          viewData.executableItemError = firstItem;

          // allow execution anyway
          this.updateExecutableItem(viewData, con);
        }
      }
    } else if ( (parent != null) && (contentType === undefined) && (parent.objType === Core.DistributableType.lms_course) ) {
      if ( ContentService.searchFirstExecutableItemImplFilter(con, parent, acceptGreen) ) {
        viewData.executableItem = con;
      } else {
        return;
      }
    } else if ( contentType === Core.DistributableType.lms_curriculum ) {
      if ( !con.items ) {
        // curricula should contain executable items!
        return;
      }

      const len = con.items.length;
      for ( let index = 0; index < len; index++ ) {
        const content = con.items[index];
        const contentData = ViewHelper.getViewData(content);
        this.searchFirstExecutableItemImpl(allContents, con, content, acceptGreen, rnd);
        const executableItem = contentData.executableItem || (contentData.sco ? content : null);
        if ( executableItem ) {
          // found an executable item -> return early
          this.updateExecutableItem(viewData, executableItem);
          return;
        }
      }
    } else {
      // todo implement other content types
    }
  }

  private updateExecutableItem(
    viewData: ViewData | null,
    executableItem: ImageableContentReference | null,
  ): void {

    if ( viewData == null ) {
      // you know what you did ;)
      return;
    }

    viewData.executableItem = executableItem;

    const parentViewData = ViewHelper.getViewData(viewData.parent);
    if ( parentViewData == null ) {
      // no parent -> nothing to pass on
      return;
    }

    if ( (parentViewData.executableItem == null) ||
      // only replace completed items / ordering remains "random"
      DisplayStatusHelper.isStatusGreen(parentViewData.executableItem.displaystatus) ||
      DisplayStatusHelper.isStatusRecert(parentViewData.executableItem.displaystatus)
    ) {
      parentViewData.executableItem = executableItem;
    }
  }

}
