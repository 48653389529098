<div class="container">
  <div class="caption">
    <span>{{caption}}</span>
    <mat-icon
      *ngIf="showSizeTooltip"
      #matTooltip="matTooltip"
      class="info-icon"
      svgIcon="information-outline"
      matTooltip="The recommended size for a tile image is 305px * 150px"
      i18n-matTooltip="@@size_image_tooltip"
      (click)="matTooltip.toggle()"
    ></mat-icon>
  </div>

  <div class="image-container">

    <div
      data-cy="dragDropFile"
      ragFilesDropArea
      [accept]="getFilesDropAreaAcceptTypes()"
      (filesDropped)="onFile($event)"
      class="boxer"
      [ngClass]="{'hasContent': imgURL}"
      *ngIf="downloadLink === null"
    >

      <!-- Image -->
      <img
        alt="image to display"
        *ngIf="imgURL && isImage"
        [src]="imgURL">

      <!-- PDF -->
      <pdf-viewer
        [contentEditable]="false"
        *ngIf="imgURL && isPdf"
        [autoresize]="false"
        [original-size]="false"
        [page]="1"
        [render-text]="true"
        [show-all]="false"
        [src]="imgURL"
        style="display: block; width: 100%; height: 100%;"
      ></pdf-viewer>

      <!-- CSV -->
      <mat-icon *ngIf="imgURL && isCsv" class="csv-file" svgIcon="file-delimited"></mat-icon>

      <!-- DOCX -->
      <mat-icon *ngIf="imgURL && isDocx" class="csv-file" svgIcon="file-word"></mat-icon>

      <!-- Icon to display when no file is selected -->
      <mat-icon *ngIf="!imgURL" [svgIcon]="fileTypeIcon"></mat-icon>
    </div>

    <div class="boxer" *ngIf="downloadLink !== null">

      <!-- Icon to download file -->
      <a
        class="download-icon"
        type="button"
        mat-icon-button
        color="primary"
        [href]="downloadLink | safe: 'url'"
        target="_blank">
          <mat-icon svgIcon="download" data-cy="buttonDownloadFile"></mat-icon>
      </a>
    </div>

    <!-- Delete or Undo button -->
    <button
      data-cy="buttonUndoFile"
      (click)="onRemove($event)"
      *ngIf="showActionButton()"
      color="warn"
      mat-mini-fab
      type="button"
      >
        <mat-icon [matTooltip]="tooltip | localeText" [svgIcon]="actionIcon"></mat-icon>
    </button>

    <div
      *ngIf="!actionButtonsDisabled && hasAccessorryViews"
       class="accessory-views">
       <div *ngFor="let accessoryView of accessorryViews" class="accessory-view">
         <ng-container [ngTemplateOutlet]="accessoryView"></ng-container>
       </div>
    </div>

  </div>
  <div class="flex">
    <label class="file-input size-fit-content">

      <div class="icon-wrapper">
        <mat-icon color="primary" svgIcon="upload" data-cy="buttonUploadFile"></mat-icon>
      </div>

      <input
        #file
        [disabled]="disabled"
        [readOnly]="disabled"
        (change)="onFile(file.files)"
        [accept]="getInputAcceptTypes()"
        type="file"/>
    </label>

    <button
      *ngIf="getAcceptFileExtensions() as extensions"
      type="button"
      mat-icon-button
      color="accent"
      #matTooltipExtensions="matTooltip"
      [matTooltip]="getAcceptFileExtensionsText(extensions)"
      (click)="matTooltipExtensions.toggle()"
    >
      <mat-icon svgIcon="information"></mat-icon>
    </button>

    <div class="file-name width-100 file-name-margin">
        <span>{{fileName}}</span>
    </div>

  </div>

</div>
