import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {map, tap} from "rxjs/operators";
import {destroySubscriptions, takeUntilDestroyed} from "../../core/reactive/until-destroyed";
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { BotService } from 'src/app/core/bot.service';
import { BotApi } from 'src/app/core/bots/bots.types';
import * as uuid from 'uuid';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { firstValueFrom } from 'rxjs';
import { State } from 'src/app/app.state';

@Component({
  standalone: true,
  selector: 'rag-bot-dialog',
  templateUrl: './bot-dialog.component.html',
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PipesModule
  ],
  styleUrls: ['./bot-dialog.component.scss']
})
export class BotDialogComponent implements OnInit, OnDestroy {

  protected botURL: string;  
  protected visible = false;
  protected botId: string;  
  private botApi: BotApi;

  constructor(
    private botService: BotService,
    private principalService: PrincipalService
  ) {}

  ngOnInit(): void {    

    this.botService.visible$
      .pipe(map(event => {
        this.botURL = event.url;
        this.visible = event.visible;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  toggleOpen() {
    if (this.botId === undefined) {
      this.botId = uuid.v4();
      setTimeout(() => {
        this.handleBotBootstrap();    
      });
      return;
    }
    this.botId = undefined;    
    this.botApi = undefined;
  }

  handleBotBootstrap = async () => {        
    this.botApi = await this.botService.botAPI(this.botId);    
    if (this.botApi === undefined) {
      console.error("botApi not found.");
      return;
    }
    if (this.visible) {
      setTimeout( async () => {
        this.botService.handleBypassInfromation();
        const isLogged = await firstValueFrom(this.principalService.isLogged$);
        if (isLogged) {
          const userFirstName = this.principalService.currentUser.firstname;
          this.botApi.botBypass("My name is " + userFirstName);
          this.botApi.runMessage("Welcome me in " + State.botLanguage + " language");
        }
      }, 1000)
    }
  }
}

