import { LocaleTranslation, Translation } from './translation/translation.types';
import { ProfileFieldTypes } from './input/profile-field/profile-field.types';
import { ExternalLogin, LandingPageEnum, TrainResponse } from './global.types';
import { ProfileSectionTypes } from '../component/input/profile-section/profile-section.types';
import { UserAttributeType } from './admin-user-attribute.types';
import { FileAuthorizationRefType, FileAuthorizationType } from './files/file-upload.types';

export const URL_PATTERN = /^(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?\S*$/i;
export const SIMPLE_MONEY_PATTERN = /^[1-9]\d*$/i;
export const MONEY_PATTERN = /^0?(?=\.)\d*(\.\d\d)?$/i;
export const TIME_PATTERN = /^\d\d:\d\d$/;
export const TIME_PATTERN_V2 = /^\d\d:[0-5][0-9]$/;
export const DOT_REGEX = /\./g;
export const UNIX_TIMESTAMP_REGEX = /:([0-9]{13})/;
/**@see classic:com.reflact.sphere.meta.api.ValidationRegexes#EMAIL*/
export const EMAIL_PATTERN = /^([A-Z0-9_+\-]+\.?)*[A-Z0-9_+\-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
export const GEO_PATTERN =
  new RegExp('^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$');
export const TELEPHONE_PATTERN = /^\+?[0-9]{6,}$/;

export type Translateable = string | LocaleTranslation;
// export type Title = Translateable;

export interface HasInfoModel {
  info?: string;
  hasInfo?: boolean;
}

export interface Identifiable {
  id: number;
}

export interface Nameable {
  name?: string;
}

export interface LegallyConformable {
  tcFileUUID?: string;
  privacyFileUUID?: string;
}

export interface Titleable {
  title?: Translateable;
  description?: Translateable;
}

export interface Imageable {
  pictureId?: number;
  pictureUUID?: string;
  picture?: FileInfo;
  cardPicture?: FileInfo;
  cardPictureUUID?: string;
}

export interface Typeable {
  type?: string;
}

export type Visualizable = Titleable & Imageable;

export interface Statusable {
  // status?: number;
  displayStatus: number;
}

export interface IOfflineAccountStatus {
  status: IOfflineAccountStatus;
}

export interface Assignable {
  assignmentMandatory: boolean;
}

export interface AccountDataField
  extends HasInfoModel {
  editable: boolean;
  fieldId: string;
  label: string;
  options?: AccountDataOptions[];
  regex?: string;
  required: boolean;
  type: string;
  value: any;
}

export interface AccountDataOptions {
  key: string;
  value: string;
}


export interface AccountDataDivisions {
  divisions: AccountDataDivision[];
}

export interface AccountDataDivision {
  column: number;
  divisionId: string;
  fields: AccountDataField[];
  title: string;
}

export interface AccountDataTab {
  divisions: AccountDataDivision[];
  label: string;
  tabId: string;
}

export interface AccountData
  extends TrainResponse {
  tabs: AccountDataTab[];
}

export interface AccountRequest {
  area?: string;
  company?: string;
  costCenter?: string;
  costcenterDesc?: string;
  dateOfBirth?: string;
  activationDate?: string;
  deactivationDate?: string;
  email?: string;
  employeeID?: string;
  extID?: string;
  firstname?: string;
  freeTextAttribute1?: string;
  freeTextAttribute2?: string;
  freeTextAttribute3?: string;
  freeTextAttribute4?: string;
  freeTextAttribute5?: string;
  freeTextAttribute6?: string;
  freeTextAttribute7?: string;
  fullName?: string;
  geID?: string;
  hireDate?: string;
  hostID?: string;
  jobCode?: string;
  language?: string;
  lastname?: string;
  location?: string;
  locationDesc?: string;
  login?: string;
  sex?: string;
  soeID?: string;
  state?: string;
  substitute2ID?: string;
  substituteID?: string;
  success?: boolean;
  superiorID?: string;
  userId?: number;
  password?: string;
}

export namespace Core {

  export type UserEventType = 'save_button_pressed';
  export interface UserEvent {
    eventId: UserEventType;
    [key: string]: any;
  }

  export enum Context {
    OFFLINE_CONTENT_TABLE_SETTINGS = 'oflCnt'
  }

  export enum CurriculumItemType {
    inherited = 'inherited',
    training = 'training',
    threshold = 'threshold',
    prerequisite = 'prerequisite',
    todo = 'todo'
  }

  export enum DistributableType {
    unknown = 'unknown',
    lms_course = 'lms_course',
    lms_workflow = 'lms_workflow',
    skilltarget = 'skilltarget',
    lms_curriculum = 'lms_curriculum',
    lms_offlineCnt = 'lms_offlineCnt',
    lms_offline_event = 'lms_offline_event',
    lms_vroom_group = 'lms_vroom_group',
    lms_context = 'lms_context',
    lms_cur_path = 'lms_cur_path',
    catalog = 'catalog',
    qa = 'qa',
    /**
     * only for frontend administration -> do not save this type in db!
     */
    lms_recording = 'lms_recording'
  }

  export enum CourseType {
    // Unknown = 0,
    Certification = 1,
    Test = 2,
    Learning = 3,
    // Talk = 4,
    // SideBySide = 5,
    // Document = 6,
    Seminar = 7,
    ScoDocument = 8,
    // Vroom = 9,
    Link = 10,
    Virco = 11,
    SimpleConnect = 12,
    ToDo = 13,
    Hybrid = 14,
    Recording = 16,
  }

  export enum CurriculumAccountStatus {
    anulled = 'anulled',
    ended = 'ended',
    locked = 'locked',
    not_attempted = 'not_attempted',
    valid = 'valid',
  }

  export type DistAssignmentType = 'both' | 'mandatory' | 'voluntary';

  /**
   * included in responses from train classic <br>
   * for train api use {@link RbacActions}
   */
  export interface AccessControl {
    /**
     * {@link Core.RbacActions.maySave}
     */
    w: boolean;
    /**
     * {@link Core.RbacActions.mayDelete}
     */
    d: boolean;
    /**
     * {@link Core.RbacActions.mayCreate}
     */
    c: boolean;
    /**
     * {@link Core.RbacActions.mayRead}
     */
    r: boolean;
    /**
     * {@link Core.RbacActions.mayAdministrate}
     */
    a: boolean;
  }
  export interface RbacActions {
    bitMask: number;
    mayAdministrate: boolean;
    mayCreate: boolean;
    mayDelete: boolean;
    mayRead: boolean;
    maySave: boolean;
  }

  export interface RbacActionsAdminAccount
    extends RbacActions {
    maySendTo: boolean;
  }

  export interface RbacActionsBasis
    extends RbacActions {
    mayCreateFilter: boolean;
  }

  export interface RbacActionsDistribution
    extends RbacActions {
    mayDistribute: boolean;
  }

  export interface RbacActionsElearning
    extends RbacActions {
    mayDistribute: boolean;
    mayExecute: boolean;
    mayReDeploy: boolean;
    maySend: boolean;
    mayUnlock: boolean;
  }

  export interface RbacActionsOffline
    extends RbacActions {
    maySaveStatusOnly: boolean;
  }

  /**
   * @deprecated replace with {@see WithRbacActions}
   */
  export interface WithAccessControl<T extends AccessControl> {
    accessControl?: T;
  }

  export interface WithRbacActions<T extends RbacActions> {
    rbacActions: T;
  }

  export interface WithOptionalRbacActions<T extends RbacActions> {
    rbacActions?: T;
  }

  export enum RbacModule {
    adm = 'adm',
    bas = 'bas',
    ctrl = 'ctrl',
    crt = 'crt',
    dist = 'dist',
    rep = 'rep',
  }

  export enum RbacTargetEnum {
    account = 'account',
    course = 'course',
    curriculum = 'curriculum',
    doclib = 'doclib',
    FOLDER = 'FOLDER',
    function_dashboard = 'function_dashboard',
    groupacc = 'groupacc',
    LAYOUT_FOLDER = 'LAYOUT_FOLDER',
    licence_group = 'licence_group',
    offline_content = 'offline_content',
    offline_event = 'offline_event',
    PROJECT = 'PROJECT',
    report = 'report',
    RESOURCE_FOLDER = 'RESOURCE_FOLDER',
    SCO_FOLDER = 'SCO_FOLDER',
    skill = 'skill',
    skilltarget = 'skilltarget',
    targetgroup = 'targetgroup',
    WBT_FOLDER = 'WBT_FOLDER',
    workflow = 'workflow',
  }

  export interface RbacTargetEntry<K> {
    objectId: K;
    userId: number;
  }

  export interface RbacTargetEntryResult<K, A extends RbacActions>
    extends RbacTargetEntry<K>, WithRbacActions<A> {
  }

  export interface ModelField<T = any>
    extends HasInfoModel {
    fieldType: UserAttributeType;
    consentText?: string;
    groupId?: number;
    label: Translation;
    options?: KeyValue<string>[];
    orderIndex?: number;
    required: boolean;
    title: string;
  }

  export interface FrontendAction {
    action: 'recordFields' | 'landingPageForward' | null;
  }

  export interface FrontendActionForwardPage extends FrontendAction {
    pages: Array<LandingPageEnum>;
  }

  export interface FrontendActionRecordFields
    extends FrontendAction {
    action: 'recordFields';
    groups: ProfileSectionTypes.ProfileSection[];
    fields: ModelField[];
  }

  export interface KeyValue<T> {
    key: string;
    value: T;
  }

  export interface AccountSettings {
    doubleOptIn: boolean;
  }

  export type UserProcessType = 'doubleOptIn' | 'purchase' | 'registration' | 'bookingInProgress' | 'link';

  export interface UserProcess<T = any> {
    type: UserProcessType;
    hash: string;
    createdAt: number;
    pausedAt?: number;
    canceledAt?: number;
    completeAt?: number;
    state: T;
  }

}

export namespace ComEvents {
  export type Module = 'Notifications'
  export type Type = 'saveRequest' | 'saveAsDraftRequest' | 'chooseTemplate' | 'deleteTemplate';

  export interface Event {
    moduleId: Module;
    eventId: Type;
    context?: AnyObject;
  }
}

export interface ContentReference extends Distributable, Titleable {

}

export interface ImageableContentReference<T = any> extends TransientView<FileUploadable | T>,
  ContentReference, Imageable {

}

export interface FileUploadable {
  pictureFile?: File;
  cardPictureFile?: File;
}

export interface AnyObject<T = any> {
  [key: string]: T;
}

export interface NumberedAnyObject<T = any> {
  [key: number]: T;
}

export interface EntityObject<T = any>
  extends AnyObject<T | number | string | boolean> {
  id: number;
  uuid?: string;
  userId?: number;
  userFullName?: string;
}

export interface TitledDistributionReference extends Titleable {
  objType: Core.DistributableType;
  objSubType?: Core.CourseType;
}

export interface Distributable<T = any> extends EntityObject<T> {
  objType: Core.DistributableType;
  objSubType?: Core.CourseType;
  distributed?: boolean;
}

export interface ParentfulDistributable<T = any> extends Distributable<T> {
  parentObjId: number;
  parentObjType: Core.DistributableType;
}

export interface EntityObjectsCollection {
  [key: number]: EntityObject;
}

export type FieldType = 'dropdown' | 'text' | 'date' | 'Date' | 'email';

// Html input types
export type InputType = 'text' | 'email' | 'date' | 'password';

export type FormChangeEvent = 'modified' | 'invalidated';

export enum PrincipalType {
  user = 'user',
  targetGroup = 'targetgroup',
  group = 'group',
  macros = 'macros'
}

export const MacroPrincipalsIds: Map<number, string> = new Map([
  [0, '{msg:userAccId}'],
  [-1, '{msg:user}'],
  [-2, '{cit:suppv}'],
  [-3, '{usr:supid}'],
  [-4, '{usr:subst1ID}'],
  [-5, '{usr:subst2ID}'],
  [-6, '{cur:personInChargeId}'],
  [-7, '{off:assignedUsersAsReceiver}']
]);

export const MacroPrincipals: Map<string, Principal> = new Map([
  [ '{msg:userAccId}', {id: 0, name: $localize`:@@global_event_triggers:Event Triggers`, type: PrincipalType.macros}],
  [ '{msg:user}', {id: -1, name: $localize`:@@global_event_triggers:Event Triggers`, type: PrincipalType.macros}],
  [ '{cit:suppv}', {id: -2, name: $localize`:@@global_model_user_suppv:Superior`, type: PrincipalType.macros}],
  [ '{usr:supid}', {id: -3, name: $localize`:@@global_model_user_userState:Superior`, type: PrincipalType.macros}],
  [ '{usr:subst1ID}', {id: -4, name: $localize`:@@global_model_user_subst1:Superior 1`, type: PrincipalType.macros}],
  [ '{usr:subst2ID}', {id: -5, name: $localize`:@@global_model_user_subst2:Superior 2`, type: PrincipalType.macros}],
  [ '{cur:personInChargeId}', {id: -6, name: $localize`:@@global_model_user_person_in_charge:Person in charge`, type: PrincipalType.macros}],
  [ '{off:assignedUsersAsReceiver}', {id: -7, name: $localize`:@@global_model_user_assigned_user_as_receiver:Assigned user`, type: PrincipalType.macros}],
]);

export type AdminUserServiceEventType = 'saved';

export interface Principal extends Identifiable {
  name: string;
  type: PrincipalType;
  /**
   * e.g. User or Target group is deleted, but used as receiver (TF-7552)
   */
  invalid?: boolean;
}

export interface MessageAccountInfo
  extends Principal {
  active: boolean;
  userId: number;
  fieldId?: string;
}

export interface Entity extends Identifiable {
  created?: number;
  lastModified?: number;
}

export interface UserView {
  id: number;
  firstName: string;
  lastName: string;
}

export interface User
extends Entity, Core.WithOptionalRbacActions<Core.RbacActionsBasis> {
  userId: number;
  firstname: string;
  lastname: string;
  active: boolean;
  email: string;
  employeeID: string;
  extID: string;
  login: string;
  userGroups?: string;

  [key: string]: any;
}

export interface UserField extends ProfileFieldTypes.ProfileField {
  context: UserFieldContext;
  sortNumber: number;
}

export enum UserFieldContext {
  /**
   * e.g. username, password, language
   */
  baseData = 'baseData',
  hrData = 'hrData',
  nonHRData = 'nonHRData',
  recordFields = 'recordFields',
  selfRegistration = 'selfRegistration',
}

export interface UserGroup extends Identifiable {
  parentID: number;
  name: string;
  children?: Array<UserGroup>;
  active: boolean;
}

export interface UserGroupView extends Identifiable {
  title: string;
  active: boolean;
  parentId?: number;
  userCount: number;
}

export interface AdminUserServiceEvent {
  event: AdminUserServiceEventType;
  user?: User;
}

export interface Curriculum
  extends Entity {
  name: string;
}

export interface TargetGroup
  extends Identifiable, Nameable, Core.WithOptionalRbacActions<Core.RbacActionsBasis> {
  description: string;
  userCount?: number;
}

export interface SelectableItem extends Identifiable, Nameable {}

export interface GroupSelectableItem extends SelectableItem {
  orgGroup: boolean;
}

type Unpacked<T> = T extends (infer U)[] ? U : T;

export type AsyncForEachCallback<T> = (e: T, i: number, arr: T[]) => Promise<void>;

export const asyncForEach = async <T extends any[]>(array: T, callback: AsyncForEachCallback<Unpacked<T>>) => {
  for ( let index = 0; index < array.length; index++ ) {
    await callback(array[index], index, array);
  }
};

export const JsonOmitViewReplacer = (key: string, value: any): any => {
  if ( key === '$view' ) {
    return undefined;
  }
  return value;
};

export interface TransientView<T = any> {
  $view?: T;
}

export interface FileInfo {
  fileId?: number;
  id?: number;
  fileName: string;
  fileSize?: number;
  mime?: string;
  size?: number;
  uuid: string;
  encoding?: string;
  authRefType?: FileAuthorizationRefType;
  authorizationType?: FileAuthorizationType;
  fileType?: string;
}

export interface ShortUrl {
  accountId: number;
  objectId: number;
  objectType: number;
  url: string;
  objectUuid?: string;
}

export namespace DataProcessing {

  export type Mode = 'edit' | 'validating' | 'validated';

  export interface Message {
    isError: boolean;
    text: string;
  }

}

export namespace Dialogs {
  export const BigDialogProps = {
    minWidth: 'calc( min( 95vw, 800px))',
    panelClass: 'max-width-dialog-container',
  };
}
export namespace DoubleOptIn {

  export type Stage = 'initial' | 'registered';

  export interface State extends Distributable {
    stage: Stage;
  }
  export type Process = Core.UserProcess<DoubleOptIn.State>;
}

export namespace PersonalLink {

  export interface State {
    firstname: string;
    lastname: string;
    offlineEventId: number;
    offlineContentId?: number;
    extLogin?: ExternalLogin;
    extLoginPath?: string;
  }

  export type Process = Core.UserProcess<PersonalLink.State>;
}

export interface IStack<T> {
  push(item: T): void;
  pop(): T | undefined;
  peek(): T | undefined;
  size(): number;
}

export interface IQueue<T> {
  enqueue(item: T): void;
  dequeue(): T | undefined;
  size(): number;
}

export class Stack<T> implements IStack<T> {
  private storage: T[] = [];

  constructor(private capacity: number = Infinity) {}

  push(item: T): void {
    if (this.size() === this.capacity) {
      throw Error("Stack has reached max capacity, you cannot add more items");
    }
    this.storage.push(item);
  }

  pop(): T | undefined {
    return this.storage.pop();
  }

  peek(): T | undefined {
    return this.storage[this.size() - 1];
  }

  size(): number {
    return this.storage.length;
  }
}

export class Queue<T> implements IQueue<T> {
  private storage: T[] = [];

  constructor(private capacity: number = Infinity) {}

  enqueue(item: T): void {
    if (this.size() === this.capacity) {
      throw Error("Queue has reached max capacity, you cannot add more items");
    }
    this.storage.push(item);
  }
  dequeue(): T | undefined {
    return this.storage.shift();
  }
  size(): number {
    return this.storage.length;
  }  
}
