import { Injectable } from '@angular/core';
import { ContentService } from '../../../core/content/content.service';
import { ContentFilterHelper } from '../../../component/content-filter/content-filter.helper';
import { TableControllerTypes } from '../../../component/table/table-controller/table-controller.types';
import { AnyObject, ImageableContentReference } from '../../../core/core.types';
import { PermissionStates } from '../../../core/principal/permission.states';
import { ContentOverviewColumnMenuItemMap } from './content-overview.columns';
import { TableHelper } from '../../../component/table/table-helper';
import { WithTags } from 'src/app/core/primitives/primitives.types';


@Injectable({ providedIn: 'root' })
export class ContentOverviewService {

  constructor(
    private contentService: ContentService,
  ) {
  }

  static getColorValidUntil(content: ImageableContentReference, warningDate = ContentOverviewService.getWarningDate()): string {
    if ( !(content.lastValidUntil > 0) ) {
      return 'black';
    }

    const lastValid = new Date(content.lastValidUntil);
    if ( (warningDate.getTime() < lastValid.getTime()) ) {
      return 'black';
    } else {
      return 'red';
    }
  }

  static getFilterOptions(contents: ImageableContentReference[], queryParams: AnyObject<string>, permissions: PermissionStates,
    isCardView: boolean, menuItems: ContentOverviewColumnMenuItemMap): TableControllerTypes.ColumnOptions[] {

    // show sorting only for card view
    const sortAttributeOptions = menuItems.sortAttribute.options;
    sortAttributeOptions.filterHidden = !isCardView;

    const assignmentTypeFilterOptions = menuItems.assignmentType.options;
    let eventLocationFilterOptions = menuItems.eventLocation.options;
    let statusFilterOptions = menuItems.displaystatus.options;
    let eventInPastFilterOptions = menuItems.eventInPast.options;
    const typeFilterOptions = menuItems.objType.options;

    const tagFilterOptions = menuItems.tags.options;
    tagFilterOptions.dropDownOptions = TableHelper.tagsAsDropDownOptions(contents as WithTags[]);

    let options: AnyObject<string>;
    if ( permissions.navUserLearnOfflineContent ) {
      // learner mode "offline content" is active -> remove regular learning information

      // @see https://train-jira.reflact.com/browse/TF-2316
      statusFilterOptions = null;

      // @see https://train-jira.reflact.com/browse/TF-2318
      options = ContentFilterHelper.getOriginalDropdownOptions(assignmentTypeFilterOptions);
      delete options.mandatory;
      delete options.voluntary;

      // @see https://train-jira.reflact.com/browse/TF-3046
      delete assignmentTypeFilterOptions.dropDownOptionsStatic;

      // @see https://train-jira.reflact.com/browse/TF-2319
      options = ContentFilterHelper.getOriginalDropdownOptions(sortAttributeOptions);
      delete options.lastValidUntil;
    } else {
      // regular learner mode -> remove event filters

      eventLocationFilterOptions = null;

      // @see https://train-jira.reflact.com/browse/TF-3046
      delete typeFilterOptions.dropDownOptionsStatic;

      // @see https://train-jira.reflact.com/browse/TF-4758
      if ( !(permissions.navUserLearnMandatory && permissions.navUserLearnVoluntary) ) {
        // remove filter, if use does not have both mandatory and voluntary content permissions
        delete assignmentTypeFilterOptions.dropDownOptionsStatic;

        options = ContentFilterHelper.getOriginalDropdownOptions(assignmentTypeFilterOptions);
        if (!permissions.navUserLearnMandatory) {
          delete options.mandatory;
        }
        if (!permissions.navUserLearnVoluntary) {
          delete options.voluntary;
        }
      }

      // @see https://train-jira.reflact.com/browse/TF-2316
      eventInPastFilterOptions = null;

      // @see https://train-jira.reflact.com/browse/TF-2319
      options = ContentFilterHelper.getOriginalDropdownOptions(sortAttributeOptions);
      delete options.eventDate;
    }

    let result = [
      // keep this order as it controls ordering of the view

      // filters with url state (synchronous)
      menuItems.title.options,
      tagFilterOptions,

      statusFilterOptions,
      eventInPastFilterOptions,
      typeFilterOptions,
      assignmentTypeFilterOptions,

      // sorting
      sortAttributeOptions,

      // async filters (must be toggled)
      eventLocationFilterOptions,
    ]
      // remove null values
      .filter(item => item != null);

    // remove any dropdown filters that have only one or no options
    if ( !permissions.hideForILearn ) {
      result = ContentFilterHelper.reduceFilterOptions(result, contents);
    }

    ContentFilterHelper.initFromQueryParams(result, queryParams);

    // skip null values
    return result.filter(entry => entry != null);
  }

  static getWarningDate(warningDays = 7): Date {
    const warningDate = new Date();
    warningDate.setDate(warningDate.getDate() + warningDays);
    return warningDate;
  }

  prepareViewData(contents: ImageableContentReference[]): ImageableContentReference[] {
    if ( !(contents?.length > 0) ) {
      return [];
    }

    const warningDate = ContentOverviewService.getWarningDate();

    return contents.map(content => {
      // check for images and add the image links to content object
      content.imageUrl = ContentService.getImage(content);

      // check for progress and add to content object
      ContentService.calculateProgress(content);
      ContentService.getDueBy(content);
      this.contentService.getDuration(content);
      ContentService.calculateDuration(content);
      this.contentService.searchFirstExecutableItem(contents, content);
      content.fontColor = ContentOverviewService.getColorValidUntil(content, warningDate);
      ContentService.calculateEventDates(content);

      return content;
    });
  }

}
