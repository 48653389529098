<div [ngClass]="{'is-fullscreen': fullscreen | async}" class="sco-wrapper">
  <p class="page-header sco-actions">
    <span class="back-button-wrapper">
      <button (click)="onCloseCourse()" mat-stroked-button>
        <mat-icon svgIcon="arrow-left"></mat-icon>
        <span class="back-button-title" i18n="@@sco_actions_exit_course">Back to Your Course List</span>
      </button>
      <!-- <a [queryParams]="backParams" [routerLink]="backUrl" class="close-sco" mat-button></a> -->
    </span>

    <span class="course-title-wrapper">
      <ng-container
        *ngIf="con.$view && con.$view.parent && con.$view.parent.id; then parentTitle else scoTitle"></ng-container>
      <ng-template #scoTitle>
        <span [title]="con.title" class="course-title">{{con.title}}</span>
      </ng-template>
      <ng-template #parentTitle>
        <span [title]="con.$view.parent.title" class="course-title">{{con.$view.parent.title}}</span>
      </ng-template>
    </span>

    <span class="countdown-wrapper" [ngClass]="{'countdown-wrapper-hidden': countdownValue === ''}">
      <span class="maxTimeAllowedCountdown {{countdownModus}}">
        <mat-icon svgIcon="timer"></mat-icon>
        {{countdownValue}}
      </span>
    </span>
    <rag-toggle-fullscreen (toggle)="toggleFullscreen($event)"
                           [fullscreen]="(fullscreen | async) === true"></rag-toggle-fullscreen>
  </p>

  <div class="sco-content">
    <img *ngIf="!scoRunning" [src]="'assets/images/loading.gif'" alt="loading content"/>
    <div [ngClass]="{'iframe-wrapper': true, 'ios-scroll': hasOverflowScrolling, 'content-hidden': contentHidden}">
      <iframe
        #scoFrame
        *ngIf="scoRunning" [src]="this.startUrl | safe:'resourceUrl'"
        allowfullscreen
        frameborder="0"
        name="scoFrame"
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-modals allow-downloads"
        referrerpolicy="strict-origin-when-cross-origin"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; microphone; download *; fullscreen; camera; speaker; geolocation"
      >

      </iframe>
    </div>
  </div>
</div>
