import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, from, Observable, of } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { State } from 'src/app/app.state';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { CatalogService } from 'src/app/core/catalog/catalog.service';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { Core, ParentfulDistributable } from 'src/app/core/core.types';
import { DialogHeaderService } from 'src/app/core/dialog-header/dialog-header.service';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { InfoService } from 'src/app/core/info/info.service';
import { InfoType, MessageConstants, YesButton, YesNoButtons } from 'src/app/core/info/info.types';
import { LanguageHelper } from 'src/app/core/language.helper';
import { PreloadService } from 'src/app/core/preload.service';
import { BookingComplitionType, BookingInProgress, NumberedMap, PRICELESS, PriceMap } from 'src/app/core/principal/principal.types';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { RedirectHelper } from 'src/app/core/redirect.helper';
import * as uuid from 'uuid';
import { CatalogBookingDialogComponent } from '../catalog-booking-dialog/catalog-booking-dialog.component';
import {
  CatalogBookingDialogParams,
  CatalogDetailsOfflineData,
} from '../catalog-booking-dialog/catalog-booking-dialog.types';
import { ProcessService } from 'src/app/route/main/process/process.service';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { CatalogBookingHelper } from 'src/app/core/catalog/catalog-booking.helper';
import { CatalogHelper } from 'src/app/core/catalog/catalog.helpers';
import { UrlHelper } from 'src/app/core/url.helper';
import { FinancialsHelper } from 'src/app/core/financials/financials.helper';

type ActionsMode = null | 'book-with-events' | 'book-without-events' | 'book-single' | 'open';

@Component({
  selector: 'rag-catalog-booking-actions',
  templateUrl: './catalog-booking-actions.component.html',
  styleUrls: [ './catalog-booking-actions.component.scss' ]
})
export class CatalogBookingActionsComponent implements OnChanges, OnInit, OnDestroy {

  @Input() data: CatalogDetailsOfflineData;
  @Input() bookingInProgress: BookingInProgress;
  @Input() selectedEvents: Map<number, Set<number>> = new Map();
  @Input() selectedModuleId: number;
  @Input() disabled = false;
  @Input() selectedModuleOnly = false;
  @Output() selection: EventEmitter<Array<ParentfulDistributable>>;

  assignmentMode: OfflineContent.EventAssignmentMode;
  bookButtonDisabled: boolean;
  bookableContentsCount: number;
  bookingWithoutEventsButtonDisabled = false;
  checkedArray: boolean[] = [];
  clickableBookButton = false;
  contentUrl: string;
  isBooked: boolean;
  isInMyAccount: boolean;
  mode: ActionsMode;
  selectedEventsCount = 0;
  totalToPay = 0;
  currency: string = undefined;
  bookingUUID: string;
  isLogged$: Observable<boolean>;
  isPaymentModuleEnabled = false;
  isBlockEvent = false;
  hasModules = false;
  totalAvailablePlaces = 0;

  private completeBookingOnceSelectedITemsAreAvailable = false;

  constructor(
    private catalogBookingHelper: CatalogBookingHelper,
    private currencyPipe: CurrencyPipe,
    private infoService: InfoService,
    private catalogService: CatalogService,
    private principalService: PrincipalService,
    private preloadService: PreloadService,
    private processService: ProcessService,
    private dialogHeaderService: DialogHeaderService,
    private router: Router
  ) {
    this.isLogged$ = this.principalService.isLogged$;
    this.selection = new EventEmitter();
  }

  get tooltip() {
    if ( !this.checkedArray[0] && (this.selectedEventsCount > 0) ) {
      return $localize`:@@catalog_offline_event_give_consent:You must first give your consent`;
    } else {
      return $localize`:@@catalog_offline_event_book_denied:You must first select an appointment per event`;
    }
  }

  ngOnInit() {

    this.isBlockEvent = this.data?.catalogEntry.blockEvent;
    if (this.isBlockEvent) {
      this.hasModules = this.data?.catalogEntry.modules?.length > 0;
    }

    this.bookButtonDisabled = true;

    this.preloadService.isPaymentModuleEnabled$
      .pipe(takeUntilDestroyed(this))
      .subscribe(enabled => {
        this.isPaymentModuleEnabled = enabled;
    });

    this.clickableBookButton = true;

    this.calculateTotalAvailablePlaces();

    this.checkedArray = this.data.offlineContents.map(offlineContent => {
      if ( offlineContent.privacyFileUUID !== undefined || offlineContent.tcFileUUID !== undefined ) {
        this.bookingWithoutEventsButtonDisabled = true;
        return false;
      }
      return true;
    });

    if (this.bookingInProgress != null && this.principalService.isLogged) {
      // handle local storage record only if the user is logged in
      this.restartBookingAfterAuthentication(this.bookingInProgress);
    }

    this.dialogHeaderService.headerEvents$.pipe(switchMap(event => {
      if (event.type === 'close') {
        this.clickableBookButton = true;
        return this.processService.invalidateBookingInProgress();
      }
      return EMPTY;
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnChanges(changes: SimpleChanges): void {

    const offlineContents = this.data?.offlineContents ?? [];

    this.bookableContentsCount = offlineContents.filter(
      content => content.assignmentMode !== 'admin' && content.events?.length > 0).length;

    this.assignmentMode = offlineContents
      .map(content => content.assignmentMode as OfflineContent.EventAssignmentMode)
      .reduce((pV, assignMode) => {

        switch ( assignMode ?? '' ) {
          case 'take':
            return 'take';
          case 'wish':
            return 'wish';
          default:
            return pV;
        }
      }, 'admin');

    this.mode = this.getActionsMode();

    const _selectedModuleIdChange: SimpleChange = changes['selectedModuleId'];
    if (_selectedModuleIdChange !== undefined) {
      this.calculateTotalAvailablePlaces();
      return;
    }

    this.updateSelectedEventsCount();
    const catalogEntry = this.data?.catalogEntry;
    this.contentUrl = UrlHelper.execUrlFor(catalogEntry);
    this.isBooked = CatalogHelper.isBooked(catalogEntry?.catalogBooking);
    this.isInMyAccount = CatalogHelper.isInMyAccount(catalogEntry);

    const _selectedEvents: SimpleChange = changes['selectedEvents'];

    if (_selectedEvents?.currentValue && !_selectedEvents.firstChange && this.completeBookingOnceSelectedITemsAreAvailable) {
      // proceed with booking
      this.completeBookingAfterAuthentication();
    }
  }

  onBookSelectedEvents(assignmentMode: OfflineContent.EventAssignmentMode | null) {

    if ( this.isBookButtonDisabled() ) {
      // no events selected
      return;
    }

    if ( this.data?.catalogEntry == null ) {
      return;
    }

    const isLogged = this.principalService.isLogged;

    // book wihout payment
    const bookingUUID = uuid.v4();

    if ( !this.isPaymentModuleEnabled || this.totalToPay === 0 ) {

      if (!isLogged) {

        this.persistBookingInProgress('book', bookingUUID)
        .pipe(tap(userProcess => {
          this.onLoginExternalCatalog(false, bookingUUID, userProcess);
        }))
        .pipe(take(1))
        .subscribe();


        return;
      }

      this.book(false, bookingUUID, assignmentMode)
        .subscribe();
      return;
    }

    // book using payment
    this.clickableBookButton = false;

    if (!isLogged) {

      this.persistBookingInProgress('bookWithPurchaseRequest')
        .pipe(tap(userProcess => {
          this.onLoginExternalCatalog(true, null, userProcess);
        }))
        .pipe(take(1))
        .subscribe();

      return;
    }

    this.book(true, bookingUUID, assignmentMode)
      .pipe(tap(booking => {
        if (booking.bookingUUID === null) {
          location.reload();
          return;
        }
        this.purchase(bookingUUID);
      }))
      .subscribe();
  }

  getLabelButtonBuy(): string {

    const priceNotInCent = FinancialsHelper.fromPriceInCent(this.totalToPay);
    const price = this.currencyPipe.transform(priceNotInCent, this.data.catalogEntry.priceCurrency ?? this.currency);
    switch ( this.assignmentMode ?? '' ) {

      case 'wish':
        // offline events need to be acknowledged
        return $localize`:@@catalog_button_booking_mode_wish:Request for ${price}`;

      case 'take':
      default:
        // regular buy / book
        if (this.isPaymentModuleEnabled) {
          return $localize`:@@catalog_button_booking_order_paid:Order paid for ${price}`;
        } else {
          return $localize`:@@catalog_button_booking_buy:Book for ${price}`;
        }
    }
  }

  isBookButtonDisabled(): boolean {

    if ( !(this.checkedArray?.length > 0) ||
      // any of the checkboxes is 'false' / unchecked
      (this.checkedArray.find(entry => entry !== true) != null) ) {
      return true;
    }

    // find any events that do not fall into the allowed min / max boundaries
    return Array.from( this.data?.offlineContents ?? [])
      .map(content => {

        const id = content.id;
        const events = this.selectedEvents?.get(id);
        const selectedEventsCount = events?.size ?? 0;

        /*
        // do not check the minimum!
        const requiredMinSchedulesCount = content.requiredMinSchedulesCount;
        if ((requiredMinSchedulesCount > 0) && (selectedEventsCount < requiredMinSchedulesCount)) {
          // min count not reached
          return false;
        }
        */

        const allowedMaxSchedulesCount = content.allowedMaxSchedulesCount;
        // check if max count is exceeded
        return !((allowedMaxSchedulesCount > 0) && (selectedEventsCount > allowedMaxSchedulesCount));
      })
      .find(entry => entry !== true) != null;
  }

  isBookingWithoutEventsButtonDisabled() {

    if (this.bookingInProgress != null) {
      return true;
    }

    let oneEntryIsFalse = true;
    if ( this.checkedArray.length > 0 ) {
      oneEntryIsFalse = this.checkedArray.find(entry => entry !== true) != null;
    }

    // If one entry was found with value false, then disable book button.
    this.bookingWithoutEventsButtonDisabled = oneEntryIsFalse;
  }

  labelBookmarkWithoutEvents(): string {

    if ( this.isBlockEvent ) {
      const offlineContent = this.data?.offlineContents.find(oc => oc.id === this.data?.catalogEntry.id);
      if ( offlineContent !== undefined && offlineContent.priceForUserInCent > 0 ) {
        // this is a block event and there is a price
        // return $localize`:@@global_buy:Buy`;

        const priceNotInCent = FinancialsHelper.fromPriceInCent(offlineContent.priceForUserInCent);
        const price = this.currencyPipe.transform(priceNotInCent, offlineContent.priceCurrency ?? this.currency);
        if (this.isPaymentModuleEnabled) {
          return $localize`:@@catalog_button_booking_order_paid:Order paid for ${price}`;
        } else {
          return $localize`:@@catalog_button_booking_buy:Book for ${price}`;
        }
      }
      return $localize`:@@block_event_book:Book event`;
    }

    if ( this.data?.catalogEntry?.objType === Core.DistributableType.lms_curriculum ) {
      return $localize`:@@bookmark_curriculum_without_events:Book without events`;
    }

    return $localize`:@@bookmark_without_events:Bookmark event`;
  }

  labelBookSingle(): string {
    const catalogEntry = this.data?.catalogEntry;
    const priceInCent = catalogEntry?.priceForUserInCent;
    if (priceInCent > 0) {
      const priceNotInCent = FinancialsHelper.fromPriceInCent(priceInCent);
      const price = this.currencyPipe
        .transform(priceNotInCent, catalogEntry?.priceCurrency ?? this.currency);
      if (this.isPaymentModuleEnabled) {
        return $localize`:@@catalog_button_booking_order_paid:Order paid for ${price}`;
      } else {
        return $localize`:@@catalog_button_booking_buy:Book for ${price}`;
      }
    }
    return $localize`:@@book:Book`;
  }

  onBookingWithoutEvents(bookmark = false) {
    if ( this.data?.catalogEntry == null ) {
      return;
    }

    const messageWithoutEvents = $localize`:@@catalog_bookmark_without_events:
      Do you want to bookmark this event?
      It will be added to your personal profile.
      You can book concrete dates at a later date.
    `;
    const messageContentNotHavingEvents = $localize`:@@catalog_booking_confirm:Would you like to book this content?`;

    const { catalogEntry } = this.data;
    let messageTask: Observable<string>;

    if ( DistributionTypeHelper.isCurriculum(catalogEntry.objType) ) {
      messageTask = this.catalogService
        .searchForEvents(catalogEntry.id, catalogEntry.objType, this.catalogService.catalogUUID)
        .pipe(map(offlineContents => {
          if ( offlineContents?.length === 0 ) {
            return messageContentNotHavingEvents;
          }
          return messageWithoutEvents;
        }));

    } else if ( DistributionTypeHelper.isOfflineContent(catalogEntry.objType) ) {
      if (catalogEntry.blockEvent && !bookmark) {
        messageTask = of(messageContentNotHavingEvents);
      } else {
        messageTask = of(messageWithoutEvents);
      }
    } else {
      messageTask = of(messageContentNotHavingEvents);
    }

    messageTask
      .pipe(switchMap(message => this.infoService.showMessage(message, {
        buttons: YesNoButtons,
        title: MessageConstants.DIALOG.TITLE.CONFIRM,
      })
        .pipe(switchMap(button => {
          if ( button !== YesButton ) {
            this.catalogService.abortBooking();
            RedirectHelper.clearRedirect();
            return of();
          }

          const isLogged = this.principalService.isLogged;

          if ( !isLogged ) {
            return this.persistBookingInProgress( bookmark ? 'bookmark' : 'bookWihoutEvents')
            .pipe(tap(userProcess => {
              this.onLoginExternalCatalog(false, null, userProcess);
            }))
          }

          return this.proceesBooking(catalogEntry, bookmark);
        }))))
      .subscribe();
  }

  private calculateTotalAvailablePlaces() {
    const { catalogEntry, offlineContents } = this.data;
    if (catalogEntry.blockEvent) {
      if (catalogEntry.maxUserCount > 0) {
        if (catalogEntry.offlineEventsViews.length === 0) {
          this.totalAvailablePlaces = catalogEntry.maxUserCount;
        } else {
          const availablePlacesForModule = catalogEntry.availableModulePlaces[this.selectedModuleId];
          if (availablePlacesForModule === undefined) {
            this.totalAvailablePlaces = catalogEntry.maxUserCount;
          } else {
            this.totalAvailablePlaces = availablePlacesForModule;
          }
        }
      } else {
        this.totalAvailablePlaces = Number.MAX_VALUE;
      }
      return;
    }
    const offlineContent = offlineContents.find(offlineContent => offlineContent.id === catalogEntry.id);
    if ( offlineContent === undefined ) {
      return;
    }
    this.totalAvailablePlaces = offlineContent.events.reduce((pV, eventSchedule) => {
      return pV + eventSchedule.availablePlaces;
    }, 0);
  }

  private proceesBooking(catalogEntry: Catalogs.CatalogEntry, bookmark = false): Observable<void> {
    const bookingUUID = uuid.v4();

    const price = catalogEntry.priceForUserInCent ?? 0;
    this.clickableBookButton = false;
    const isReservation = price > 0 && DistributionTypeHelper.isOfflineContent(catalogEntry.objType);

    return this.catalogBookingHelper.bookWithEvents(
      isReservation,
      catalogEntry,
      null,
      null,
      new Map<number, Set<number>>(),
      bookingUUID,
      bookmark ? null : this.selectedModuleId,
      this.selectedModuleOnly
    )
    .pipe(map(handlingBookingData => {
      if (handlingBookingData == null) {
        return;
      }

      if (price === 0 || !this.isPaymentModuleEnabled) {
        RedirectHelper.clearRedirect();
        if (bookmark) {          
          const urlTree = this.router.createUrlTree([], {
            queryParams: { _: (new Date()).getTime() },
            queryParamsHandling: 'merge',
          });
      
          from(this.router.navigateByUrl(urlTree, {
            replaceUrl: true,
            onSameUrlNavigation: 'reload'
          }))
          .subscribe();
        } else {
          this.router.navigate(['bookings', handlingBookingData.bookingUUID], {
            replaceUrl: true,
          });
        }
        return;
      }

      const redirectUrl = CatalogBookingHelper.getDSBRedirect();

      const htmlRender = document.createElement('div');

      let description = LanguageHelper.objectToText(catalogEntry.description, State.language);
      if (description?.length > 150) {
        description = description.substring(0, 150) + '...';
      }

      htmlRender.innerHTML = description;
      description = htmlRender.textContent;

      const lineItem: Catalogs.LineItem = {
        objectId: catalogEntry.id,
        objectType: catalogEntry.objType,
        title: LanguageHelper.objectToText(catalogEntry.title, State.language),
        description,
        quantity: 1,
        price: price,
        priceFloat: price / 100.0,
      };

      if (catalogEntry.priceComment !== undefined) {
        lineItem.comment = catalogEntry.priceComment;
      }

      const purchaseRequest: Catalogs.PurchaseRequest = {
        bookingUUID: handlingBookingData.bookingUUID,
        lineItems: [lineItem],
        redirectAfterErrorUrl: redirectUrl,
        redirectAfterSuccessUrl: redirectUrl
      };
      this.purchase(handlingBookingData.bookingUUID, purchaseRequest);

      this.clickableBookButton = true;
    }))
    .pipe(finalize(() => {
      this.clickableBookButton = true;
    }))
  }

  onLoginExternalCatalog(isReservation: boolean, bookingUUID?: string, userProcess?: Core.UserProcess): Observable<Catalogs.CatalogBooking | boolean> {

    const catalogEntry = this.data.catalogEntry;

    this.processService.setContext({
      id: catalogEntry.id,
      objType: catalogEntry.objType,
      catalogUUID: this.catalogService.catalogUUID,
    });

    return this.infoService
      .showDialog<CatalogBookingDialogComponent, CatalogBookingDialogParams, Catalogs.CatalogBooking | boolean>(
        CatalogBookingDialogComponent, {
          isLogged: false,
          bookingUUID: bookingUUID ?? uuid.v4(),
          contentId: catalogEntry.id,
          contentType: catalogEntry.objType,
          title: catalogEntry.title,
          offlineContents: [],
          isReservation,
          // contentIsNotABlockEvent: catalogEntry.objType !== Core.DistributableType.lms_offlineCnt,
          userProcess,
          courseType: catalogEntry?.objSubType,
        });
  }

  /**
   *
   * @returns cancel booked content
   */
  onCancelBooking(): void {
    const catalogEntry = this.data?.catalogEntry;
    if ( catalogEntry == null ) {
      return;
    }

    this.clickableBookButton = false;
    this.catalogBookingHelper.cancel(catalogEntry)
      .pipe(finalize(() => this.clickableBookButton = true))
      .pipe(take(1))
      .subscribe();
  }

  updateLegalCheckboxStatus(index: number, checked: any) {

    // Fill array with values
    this.checkedArray[index] = checked.checked;

    this.updateSelectedEventsCount();
    this.isBookingWithoutEventsButtonDisabled();
  }

  /**
   * As ngOnChanges does not trigger again for a second update of selectedEvents, we need to manually trigger an update.
   * Keep the copy inside CatalogDetailsOfflineComponent for potential race conditions.
   */
  updateSelectedEventsCount(): void {

    let totalToPay = 0;
    const eventSchedules: Array<OfflineContent.EventScheduleCatalogView> = [];
    this.selectedEventsCount = Array.from(this.selectedEvents.entries())
      .map(([ offlineContentId, selectedEventIds ]) => {
        const offlineContent = this.data.offlineContents.find(oflCnt => oflCnt.id === offlineContentId);
        if ( offlineContent !== undefined ) {
          if ( offlineContent.blockEvent ) {
            // return fake event for the rest procedure to calculate the total price properly
            return [{
              availablePlaces: 1,
              eventDate: 0,
              eventDateUntil: 0,
              id: offlineContent.id,
              location: null,
              netDuration: 0,
              trainers: [],
              priceCurrency: offlineContent.priceCurrency,
              priceValue: offlineContent.priceForUserInCent
            }];
          }
          return eventSchedules.concat(
            offlineContent.events.filter(eventScheduleView => selectedEventIds.has(eventScheduleView.id)));
        }
        return eventSchedules;
      })
      .map(selectedEvents => {  // :( no peek function in JS
        totalToPay += selectedEvents.reduce((pV, selectedEvent) => {
          if ( this.currency === undefined && selectedEvent.priceCurrency !== undefined ) {
            this.currency = selectedEvent.priceCurrency;
          }
          return pV + (selectedEvent.priceValue ?? 0);
        }, 0);
        return selectedEvents;
      })
      .map(selectedEvents => selectedEvents.length)
      .reduce((pV, size) => pV + size, 0);

    this.totalToPay = totalToPay;
    this.bookButtonDisabled = this.isBookButtonDisabled();
  }

  private purchase(bookingUUID: string, purchaseRequest?: Catalogs.PurchaseRequest) {

    if (purchaseRequest === undefined) {

      const redirectUrl = CatalogBookingHelper.getDSBRedirect();

      const htmlRender = document.createElement('div');

      purchaseRequest = Array
        .from(this.selectedEvents.entries())
        .reduce((pV, [ offlineContentId, eventSchedules ]) => {

          const offlineContent = this.data.offlineContents.find(
            _offlineContent => _offlineContent.id === offlineContentId);

          eventSchedules.forEach(eventScheduleId => {

            const eventSchedule = offlineContent.events.find(
              _eventSchedule => _eventSchedule.id === eventScheduleId);

            let title = LanguageHelper.objectToText(eventSchedule.title, State.language);
            let description = LanguageHelper.objectToText(eventSchedule.description, State.language);
            // handle to long description
            if (description?.length > 150) {
              description = description.substring(0, 150) + '...';
            }

            htmlRender.innerHTML = title;
            title = htmlRender.textContent;

            htmlRender.innerHTML = description;
            description = htmlRender.textContent;

            const price = eventSchedule.priceValue ?? 0;

            const lineItem: Catalogs.LineItem = {
              objectId: eventScheduleId,
              objectType: Core.DistributableType.lms_offline_event,
              parentObjId: offlineContentId,
              parentObjType: Core.DistributableType.lms_offlineCnt,
              title,
              description,
              // imageUrl: null,
              quantity: 1,
              price,
              priceFloat: price / 100.0,
            };

            if (eventSchedule.priceDescription != null) {
              lineItem.comment = eventSchedule.priceDescription;
            }

            pV.lineItems.push(lineItem);
          });
          return pV;
        }, {
          bookingUUID,
          redirectAfterSuccessUrl: redirectUrl,
          redirectAfterErrorUrl: redirectUrl,
          lineItems: [],
        });

      htmlRender.remove();
    }

    if ( purchaseRequest.lineItems.length > 0 ) {
      this.catalogService.purchase(purchaseRequest).subscribe(url => {
        // redirect to payment gateway
        // TODO: display notification that the user will be redirected to payment gateway
        window.location.replace(url);
      }, error => {
        console.error(error);

        this.clickableBookButton = true;

        let message;
        switch (error.status) {
          case 400:
            message = $localize`:@@global_error_not_authorized:You are not authorized to perform this operation. Please contact your system administrator for more information.`;
            break;

          default:
            message = $localize`:@@general_error:The last operation failed. Please try again later.`;
        }
        this.infoService.showMessage(message, {
          infoType: InfoType.Error,
        }).subscribe();
      });
    }
  }

  private persistBookingInProgress(type: BookingComplitionType, bookingUUID?: string): Observable<Core.UserProcess> {

    const {selectedEvents, selectedPrices} = this.getSelectedItemsInfo();

    const bookingInProgress: BookingInProgress = {
      type,
      selectedEvents,
      selectedPrices
    };

    if (bookingUUID) {
      bookingInProgress.bookingUUID = bookingUUID;
    }

    return this.processService.persistBookingInProgress(bookingInProgress);
  }

  private getSelectedItemsInfo(): {selectedEvents: NumberedMap; selectedPrices: PriceMap} {
    const selectedEvents: NumberedMap = {};
    const selectedPrices: PriceMap = {};
    for (const [key, value] of this.selectedEvents) {
      selectedEvents[key] = Array.from(value);
      const offlineContent = this.data.offlineContents.find(c => c.id === key);
      selectedPrices[key] = selectedEvents[key].map(offlineEventId => {
        const offlineEvent = offlineContent.events.find(e => e.id === offlineEventId);
        if (offlineEvent.priceValue != null && offlineEvent.priceValue >= 0) {
          return {
            price: offlineEvent.priceValue,
            currency: offlineEvent.priceCurrency
          };
        }
        return PRICELESS;
      });
    }
    return {
      selectedEvents,
      selectedPrices
    };
  }

  // private restoreSelectedEvents(selectedItems: SelectedEvents) {
  //   const selectedEvents: Map<number, Set<number>> = new Map();
  //   Object.keys(selectedItems).forEach(key => {
  //     selectedEvents.set(parseInt(key, 10), new Set(selectedItems[key]));
  //   });
  //   this.selectedEvents = selectedEvents;
  // }

  private convertPersistedItems(selectedItems: NumberedMap): Array<ParentfulDistributable> {
    const result: Array<ParentfulDistributable> = [];
    Object.keys(selectedItems).forEach(parentObjId => {
      const eventIds: Array<number> = selectedItems[parentObjId];
      eventIds.forEach(eventId => {
        result.push({
          parentObjId: parseInt(parentObjId, 10),
          id: eventId,
          parentObjType: Core.DistributableType.lms_offlineCnt,
          objType: Core.DistributableType.lms_offline_event
        });
      });
    });
    return result;
  }

  private restartBookingAfterAuthentication(bookingInProgress: BookingInProgress) {

    if (bookingInProgress.type === 'bookWihoutEvents') {
      this.completeBookingAfterAuthentication();
      return;
    }

    // ensure all selected items are still available for booking
    const selectedEvents: Array<string> = Object.keys(bookingInProgress.selectedEvents);

    const areAllPrerequisitesFulfilled = selectedEvents.every(key => {
      const offlineContantId = parseInt(key, 10);
      const offlineContent = this.data.offlineContents.find(c => c.id === offlineContantId);
      if (offlineContent != null) {
        const events = offlineContent.events ?? [];
        if (events.length > 0) {
          const selectedEventSchedulesIds = bookingInProgress.selectedEvents[offlineContantId];
          const eventSchedulesAvailableForBookingCount = selectedEventSchedulesIds.reduce((pV, eventScheduleId, index) => {
            const eventSchedule = events.find(e => e.id === eventScheduleId);
            // is there still place in this event schedule ?
            if (eventSchedule != null && eventSchedule.availablePlaces > 0) {
              // is the price / currency changed ?
              const selectedPrice = bookingInProgress.selectedPrices[offlineContantId][index];
              // handling "for free" against price === 0 as not relevant
              if (selectedPrice.price !== eventSchedule.priceValue && eventSchedule.priceValue > 0) {
                return pV;
              }
              // differnece in currency is only relevant when the price > 0
              if (selectedPrice.currency !== eventSchedule.priceCurrency && eventSchedule.priceValue > 0) {
                return pV;
              }
              return pV + 1;
            }
            return pV;
          }, 0);
          return eventSchedulesAvailableForBookingCount ===
            selectedEventSchedulesIds.length;
        }
      }
      // abort
      return false;
    });

    if (areAllPrerequisitesFulfilled) {

      const selectedItems = this.convertPersistedItems(bookingInProgress.selectedEvents);
      this.selection.emit(selectedItems);

      // enable booking restart once selected Items are set
      this.completeBookingOnceSelectedITemsAreAvailable = true;

      return;
    }

    this.infoService
      .showAlert($localize`:@@catalog_booking_booking_cannot_be_restarted:There has been a change in the items you selected.<br>Please review provided content carefully and make your choice again.`)
      .pipe(switchMap(_ => this.processService.invalidateBookingInProgress()))
      .subscribe();
  }

  private completeBookingAfterAuthentication() {

    if (this.bookingInProgress == null) {
      return;
    }

    this.processService.invalidateBookingInProgress(true);

    switch (this.bookingInProgress.type) {
      case 'book':
        this.book(false, this.bookingInProgress.bookingUUID, null)
          .subscribe();
        break;

      case 'bookmark':
        this.proceesBooking(this.data.catalogEntry, true)
        .subscribe();
        break;

      case 'bookWihoutEvents':
        this.proceesBooking(this.data.catalogEntry)
        .subscribe();
        break;

      case 'bookWithPurchaseRequest':
        if ( this.checkedArray?.length > 0 ) {
          // automatically mark all checkboxes as checked -> this is the continuation of an already confirmed booking
          for ( let i = 0; i < this.checkedArray.length; i++ ) {
            this.checkedArray[i] = true;
          }
        }
        this.onBookSelectedEvents(null);
    }
  }

  private getActionsMode(): ActionsMode {

    // by block events the user cannot book single schedules
    if ( this.data.catalogEntry.blockEvent ) {
      return 'book-without-events';
    }

    if ( this.bookableContentsCount > 0 ) {

      // there are some offline contents with selectable events
      return 'book-with-events';
    } else if ( this.data?.offlineContents?.length === 0 ) {

      // this content does not have any bookable contents
      return 'book-single';
    } else {

      // this content does not have any events available for selection
      return 'book-without-events';
    }
  }

  private book(isReservation: boolean, bookingUUID: string, assignmentMode: OfflineContent.EventAssignmentMode | null): Observable<Catalogs.V2.HandlingBookingData> {

    return this.catalogBookingHelper.bookWithEvents(
      isReservation,
      this.data.catalogEntry,
      assignmentMode,
      this.data.offlineContents,
      this.selectedEvents,
      bookingUUID)
      .pipe(finalize(() => {
        // this.clickableBookButton = true;
        this.bookingUUID = undefined;
      }))
      .pipe(take(1));
  }

}
