<ng-template #tplEmpty></ng-template>

<ng-template #tplImage>
  <img
    class="status-icon"
    [src]="status.src"
    [alt]="status.label"
    [matTooltip]="status.label"
    [ngClass]="status.cssClass"
  />
</ng-template>

<ng-template #tplSvgIcon>
  <mat-icon
    class="status-icon"
    [svgIcon]="status.svgIcon"
    [matTooltip]="status.label"
    [ngClass]="status.cssClass"
  ></mat-icon>
</ng-template>

<ng-container
  *ngTemplateOutlet="getTemplate(tplEmpty, tplImage, tplSvgIcon)"
></ng-container>
