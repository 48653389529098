<div class="container" [class.horizontal]="horizontal">
  <!-- <ng-container
    *ngIf="detailsEnabled && isAvailableForBooking(targetObject)"
  >
    <a
      *ngIf="getDetailsUrl(catalogEntry) as urlTree"
      mat-flat-button
      color="primary"
      [routerLink]="getRouterLink(urlTree)"
      [queryParams]="getQueryParams(urlTree)"
    >
      <span i18n="@@global_details">Details</span>
    </a>
  </ng-container> -->

  <a
    *ngIf="isBooked(targetObject)"
    mat-flat-button
    color="warn"
    [disabled]="inputDisabled"
    (click)="onCancelBooking($event, targetObject)"
  >
    <span *ngIf="bookMark" i18n="@@remove_bookmark">Remove bookmark</span>
    <span *ngIf="!bookMark" i18n="@@remove_event">Remove event</span>
  </a>

  <!-- <a
    *ngIf="isInMyAccount(targetObject)"
    mat-stroked-button
    [routerLink]="getContentUrl(catalogEntry)"
  >
    <span i18n="@@global_open">Open</span>
  </a> -->
</div>
