<mat-form-field appearance="outline">
  <!-- matInput is required for the form field - will not be saved -->
  <input matInput type="hidden"/>
  <mat-checkbox
    [formControl]="inputRequiresConsent"
    [required]="required"
    [disabled]="disabled || !fieldControl?.value"
    data-cy="profileFieldConsent"
  >
    <span i18n="@@profile_field_requiresConsent">Give consent</span>
  </mat-checkbox>
  <mat-hint
    class="whitespace-prewrap consent-text"
  >{{field.consentText | localeText}}</mat-hint>
</mat-form-field>
