import { Core, ImageableContentReference } from "../core.types";
import { DisplayStatusColors, DisplayStatusHelper } from "../display-status-helper";

export class ContentHelper {

  static isLocked(content: ImageableContentReference) {
    let locked = true;
    if ( content.locked === true ) {
      const status = DisplayStatusHelper.toColor(content.displaystatus);
      const repetitions: number = content.repetitions;
      const isGreen = status === DisplayStatusColors.green;
      if ( isGreen && ((repetitions === -2) || (repetitions === -3)) ) {
        // content is green and locked -> repetitions still allow execution
      } else {
        // content is locked -> green should not show lock icon
        // todo maybe show disabled play button?
        return !isGreen;
      }
    }

    const objType = content.objType;
    if ( objType === Core.DistributableType.lms_course ) {
      locked = (content.locked === true) || !(content.items?.length > 0);
      content.items?.forEach(item => {
        if ( item?.fullhref != null ) {
          locked = locked || item.locked; // one locked SCO will lock the whole course
        }
      });
    } else if ( objType === Core.DistributableType.lms_offlineCnt || objType === Core.DistributableType.lms_context || content.fullhref ) {
      locked = false;
    }

    return locked;
  }
}