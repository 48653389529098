<mat-form-field
  [formGroup]="form"
  appearance="outline"
>

  <mat-label>
    <mat-icon
      *ngIf="field.setByInterface === true"
      class="special-required"
      svgIcon="elevator"
    ></mat-icon>
    {{field.label | localeText}}
  </mat-label>

  <input
    matInput
    type="text"
    [formControlName]="field.fieldId"
    inputmode="number"
    [name]="field.fieldId"
    [required]="field.required"
    pattern="(|[\-]{0,1}[1-9]\d*)"
    [disabled]="isDisabled()"
    data-cy="profileFieldNumber"
  />

  <mat-error
    *ngIf="getError() as error"
  >{{error}}</mat-error>

</mat-form-field>
