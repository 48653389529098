import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HtmlDialogComponent } from 'src/app/component/html-dialog/html-dialog.component';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { Distributable } from 'src/app/core/core.types';
import { InfoService } from 'src/app/core/info/info.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { isTimeCrossing } from 'src/app/core/utils';
import { AfterViewInit } from '@angular/core';
import { ViewHelper } from 'src/app/core/view-helper';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { CatalogBookingHelper } from 'src/app/core/catalog/catalog-booking.helper';


export type SelectedEvents = Map<number, Set<number>>;
const DISPLAY_COLUMNS_BLOCK_EVENT = [ 'checkbox', 'startDate', 'endDatum', 'title', 'trainers', 'location', 'ext-login', 'notices' ];
const DISPLAY_COLUMNS_DEFAULT = [ ...DISPLAY_COLUMNS_BLOCK_EVENT, 'freePlaces', 'price' ];

@Component({
  selector: 'rag-catalog-booking-table',
  templateUrl: './catalog-booking-table.component.html',
  styleUrls: [ './catalog-booking-table.component.scss' ],
})
export class CatalogBookingTableComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() bookableContentsCount: number;
  @Input() preSelection: Array<Distributable>;
  @Input() offlineContents: Array<OfflineContent.EventCatalogView>;
  @Input() forceSelectable = false;
  @Output() selectedEvents$: Observable<SelectedEvents>;
  @Output() moduleChange: EventEmitter<number>;

  bookableOfflineContents: Array<OfflineContent.EventCatalogView>;
  isExpanded = false;
  dataSources: Array<MatTableDataSource<OfflineContent.EventScheduleCatalogView>>;

  private selectedEvents: SelectedEvents = new Map();
  private _selectedEvents$ = new EventEmitter<SelectedEvents>(true);
  private _shouldBroadcastSelectedItems = false;
  private _modulesFormControls: Map<number, FormControl> = new Map();
  private _modulesHavingEvents: Map<number, Array<OfflineContent.EventModule>> = new Map();

  constructor(
    private infoService: InfoService,
    private translationService: TranslationService
  ) {
    this.moduleChange = new EventEmitter();
    this.selectedEvents$ = this._selectedEvents$.asObservable();
  }

  ngOnInit(): void {

    this.selectedEvents$
      .pipe(tap(next => this.selectedEvents = next))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.bookableOfflineContents = (this.offlineContents ?? []).filter(content => {

      if (content.blockEvent) {
        return true;
      }

      if ( !content.activeEventsAvailable ||
        (content.assignmentMode === 'admin') ||
        !(content.events?.length > 0) ) {
        return false;
      }

      content.events.sort(CatalogBookingHelper.sortComparatorEventSchedule);

      content.events.forEach(eventView => {
        eventView.$view = {
          maxCountReached: false,
          overlappingCounter: 0,
        };
      });
      return true;
    })
    .sort((v1, v2) => 
      v1.events[0].eventDate - v2.events[0].eventDate
    );

    this.bookableContentsCount = this.bookableOfflineContents.length;

    this.dataSources = this.bookableOfflineContents.map((eventCatalogView, index) => {

      const events = eventCatalogView.events ?? [];
      if (events.length === 0) {
        return new MatTableDataSource(events);
      }

      let tableData: Array<OfflineContent.EventScheduleCatalogView>;

      if (eventCatalogView.blockEvent) {

        if (eventCatalogView.modules.length > 0) {
          // search first module with available events schedules

          const modulesHavingEvents = eventCatalogView.modules
            .filter(m => eventCatalogView.events.find(e => e.module?.id === m.id) != undefined)
            .sort((m1, m2) => m1.id - m2.id);

          const firstModule = modulesHavingEvents?.[0];

          // if none is found then skip initialization steps. The taböe stays empty.
          if (firstModule === undefined) {
            tableData = [];
          } else {
            tableData = eventCatalogView.events.filter(e => e.module.id === firstModule.id)
              .sort(CatalogBookingHelper.sortComparatorEventSchedule);
              const moduleFormControl = new FormControl();

              moduleFormControl.setValue(firstModule.id);
              this.moduleChange.emit(firstModule.id);

              moduleFormControl.valueChanges.pipe(map(moduleId => {
                // refresh displayed event schedules
                const tableData = eventCatalogView.events
                .filter(e => e.module.id === moduleId)
                .sort(CatalogBookingHelper.sortComparatorEventSchedule);
                this.dataSources[index].data = tableData;
                // emit module change
                this.moduleChange.emit(moduleId);
              }))
              .pipe(takeUntilDestroyed(this))
              .subscribe();

              this._modulesHavingEvents.set(eventCatalogView.id, modulesHavingEvents);
              this._modulesFormControls.set(eventCatalogView.id, moduleFormControl);
          }

        } else {
          tableData = events;
        }

        const ids = new Set<number>();
        tableData.forEach(e => ids.add(e.id));
        this.selectedEvents.set(eventCatalogView.id, ids);

      } else {
        tableData = events;
      }

      return new MatTableDataSource(tableData);
    });
  }

  ngAfterViewInit(): void {
    if (this._shouldBroadcastSelectedItems) {
      this._selectedEvents$.emit(this.selectedEvents);
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    const preSelectionChange: SimpleChange = changes['preSelection'];
    if ( preSelectionChange?.currentValue !== undefined ) {

      // preselection cames from catalog-actions when restarting booking process after authentication

      let hasFound = false;
      preSelectionChange.currentValue.forEach(distReference => {
        this.offlineContents.every(eventCatalogView => {
          if ( eventCatalogView.events?.length > 0 ) {
            const eventView = eventCatalogView.events.find(eventV => eventV.id === distReference.id);
            if ( eventView ) {
              hasFound = true;
              this.toggleSelection(eventCatalogView, eventView, true);
              return false;
            }
          }
          return true;
        });
      });
      if ( hasFound ) {
        setTimeout(() => this._selectedEvents$.emit(this.selectedEvents));
      }
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  getModulesHavingEvents(offlineContent: OfflineContent.EventCatalogView) {
    return this._modulesHavingEvents.get(offlineContent.id);
  }

  shouldDisplayHeader(offlineContent: OfflineContent.EventCatalogView) {
    return offlineContent.blockEvent && offlineContent.modules.length > 0;
  }

  getSelectedTabIndex(offlineContent: OfflineContent.EventCatalogView) {
    const moduleId = this.formControl(offlineContent).value;
    const modules = this.getModulesHavingEvents(offlineContent);
    return modules.findIndex(m => m.id === moduleId);
  }

  setSelectedModule(offlineContent: OfflineContent.EventCatalogView, event: MatTabChangeEvent) {
    const modules = this.getModulesHavingEvents(offlineContent);
    const moduleId = modules[event.index].id;
    this.formControl(offlineContent).setValue(moduleId);
  }

  private formControl(offlineContent: OfflineContent.EventCatalogView): FormControl {
    return this._modulesFormControls.get(offlineContent.id);
  }

  getColorOfFreePlaces(eventSchedule: OfflineContent.EventScheduleCatalogView): string {

    const availablePlaces = eventSchedule.availablePlaces;
    const closedStatus = eventSchedule.closedStatus;
    const maxUserCount = eventSchedule.maxUserCount;
    return CatalogBookingHelper.getColorOfFreePlaces(availablePlaces, maxUserCount, closedStatus);
  }

  getFreePlacesPlaceholder(eventSchedule: OfflineContent.EventScheduleCatalogView): string {

    const availablePlaces = eventSchedule.availablePlaces;
    const closedStatus = eventSchedule.closedStatus;
    const maxUserCount = eventSchedule.maxUserCount;
    return CatalogBookingHelper
      .getFreePlacesPlaceholder(availablePlaces, maxUserCount, closedStatus);
  }

  hasInfo(eventSchedule: OfflineContent.EventScheduleCatalogView) {
    return this.translationService.translate(eventSchedule.description)?.length > 0;
  }

  hasTrainers(eventSchedule: OfflineContent.EventScheduleCatalogView) {
    return eventSchedule.trainers?.length > 0;
  }

  isCheckBoxVisible(offlineContent: OfflineContent.EventCatalogView) {
    return !offlineContent.blockEvent || this.forceSelectable;
  }

  isEventChecked(eventView: OfflineContent.EventCatalogView, eventSchedule: OfflineContent.EventScheduleCatalogView) {
    const contentId = eventView.id;
    const eventId = eventSchedule.id;
    return this.isEventCheckedByIds(contentId, eventId);
  }

  isEventScheduleDisabled(
    eventView: OfflineContent.EventCatalogView,
    eventSchedule: OfflineContent.EventScheduleCatalogView,
  ) {
    if ( eventView.blockEvent ) {
      return false;
    }
    if ( !CatalogBookingHelper.mayBookEventSchedule(eventSchedule.closedStatus, eventSchedule.eventDate,
      eventSchedule.eventDateUntil, eventSchedule.bookingUntilEnd, eventSchedule.priceValue) ) {
      return true;
    }

    const viewData = ViewHelper.getViewData(eventSchedule);
    const contentId = eventView.id;
    const isChecked = this.isEventCheckedByIds(contentId, eventSchedule.id);
    const selectedEvents = this.selectedEvents.get(contentId);
    const maxCountReached = !isChecked && selectedEvents && selectedEvents.size === eventView.allowedMaxSchedulesCount;
    if ( maxCountReached ) {
      return true;
    }

    return viewData.overlappingCounter > 0 || !(eventSchedule.availablePlaces > 0);
  }

  getDisplayColumns(offlineContent: OfflineContent.EventCatalogView): string[] {

    let isThereAtLeastOneLocation = false;
    let istThereAtLeastOneExtLogin = false;
    offlineContent.events?.forEach(offlineEvent => {
      isThereAtLeastOneLocation = isThereAtLeastOneLocation || (offlineEvent.location != null);
      istThereAtLeastOneExtLogin = istThereAtLeastOneExtLogin || (offlineEvent.extLogin != null);
    });

    const columnToDisplay = offlineContent.blockEvent ? DISPLAY_COLUMNS_BLOCK_EVENT : DISPLAY_COLUMNS_DEFAULT;

    const displayColumns = columnToDisplay
      .filter(column => {
        switch ( column ) {
          case 'location':
            return isThereAtLeastOneLocation;
          case 'ext-login':
            return istThereAtLeastOneExtLogin;
          default:
            return true;
        }
      });
    return displayColumns;
  }

  onSelectedChange($event: MatCheckboxChange, eventView: OfflineContent.EventCatalogView,
    eventSchedule: OfflineContent.EventScheduleCatalogView) {

    // toggle selection state
    this.toggleSelection(eventView, eventSchedule, $event.checked);

    // trigger update of dependent components
    this._selectedEvents$.emit(this.selectedEvents);
  }

  isAllChecked(eventView: OfflineContent.EventCatalogView)  {
    const selectedCount = this.selectedEvents.get(eventView.id)?.size ?? 0;
    if (eventView.multipleSchedules && selectedCount > 0) {
      if (eventView.allowedMaxSchedulesCount > 0) {
        return selectedCount === eventView.allowedMaxSchedulesCount;
      }
      return selectedCount === eventView.events.reduce((pV, e) => {
        if (this.isEventCheckedByIds(eventView.id, e.id)) {
          return pV + 1;
        }
        return pV;
      }, 0);
    }
    return selectedCount === 1;
  }

  isSelectAllDisabled(eventView: OfflineContent.EventCatalogView) {
    const selectedCount = this.selectedEvents.get(eventView.id)?.size ?? 0;
    if (eventView.multipleSchedules) {
      if (eventView.allowedMaxSchedulesCount > 0) {
        return eventView.allowedMaxSchedulesCount < eventView.events.length;
      }
      return false;
    }
    return (eventView.events?.length ?? 0) > 1 || selectedCount === 1;
  }

  isSelectAllIndeterminate(eventView: OfflineContent.EventCatalogView) {
    const selectedCount = this.selectedEvents.get(eventView.id)?.size ?? 0;
    return selectedCount > 0 && !this.isSelectAllDisabled(eventView) && !this.isAllChecked(eventView);
  }

  onSelectAllChange($event: MatCheckboxChange, eventView: OfflineContent.EventCatalogView) {
    if ($event.checked) {
      eventView.events.forEach(e => {
        if (this.isEventCheckedByIds(eventView.id, e.id) ||
          (!eventView.blockEvent && this.isEventScheduleDisabled(eventView, e)) ) {
            return;
        }
        this.toggleSelection(eventView, e, true);
      });
      return;
    }
    eventView.events.forEach(e => {
      if (this.isEventCheckedByIds(eventView.id, e.id)) {
        this.toggleSelection(eventView, e, false);
      }
    });
  }

  showInfo(eventSchedule: OfflineContent.EventScheduleCatalogView) {
    this.infoService.showDialog(HtmlDialogComponent, {
      title: $localize`:@@global_info:Info`,
      html: this.translationService.translate(eventSchedule.description),
    });
  }

  shouldDisplayPriceForBLockEvent(offlineContent: OfflineContent.EventCatalogView) {
    return offlineContent.blockEvent && (
      (offlineContent.priceForUserInCent ?? 0) > 0
    );
  }

  private isEventCheckedByIds(contentId: number, eventId: number) {
    const selectedEventSchedulesForThisEvent = this.selectedEvents.get(contentId);
    if ( selectedEventSchedulesForThisEvent == null ) {
      return false;
    }
    return selectedEventSchedulesForThisEvent.has(eventId);
  }

  private toggleSelection(
    eventView: OfflineContent.EventCatalogView,
    eventScheduleView: OfflineContent.EventScheduleCatalogView,
    checked: boolean): void {

    let selectedEventSchedulesForThisEvent = this.selectedEvents.get(eventView.id);

    if ( checked ) {

      if ( selectedEventSchedulesForThisEvent == null ) {
        selectedEventSchedulesForThisEvent = new Set();
        this.selectedEvents.set(eventView.id, selectedEventSchedulesForThisEvent);
      }

      if ( !eventView.multipleSchedules ) {
        // todo currently train supports only one event schedule per member
        selectedEventSchedulesForThisEvent.clear();
      }
      // add event to collection for current content
      selectedEventSchedulesForThisEvent.add(eventScheduleView.id);


    } else if ( selectedEventSchedulesForThisEvent?.delete(eventScheduleView.id) ) {

      if ( selectedEventSchedulesForThisEvent.size === 0 ) {
        // remove offlineContent from map
        this.selectedEvents.delete(eventView.id);
      }
    }
    this._selectedEvents$.emit(this.selectedEvents);

    // "multipleSchedules" support
    if ( eventView.multipleSchedules ) {

      eventView.events.forEach(_eventScheduleView => {
        // ignore current event schedule view
        if ( _eventScheduleView.id === eventScheduleView.id ) {
          return;
        }
        const viewData = ViewHelper.getViewData(_eventScheduleView);
        // "allowedMaxSchedulesCount" support

        // "schedulesOverlapping" support
        if ( !eventView.schedulesOverlapping ) {
          if ( isTimeCrossing(
            eventScheduleView.eventDate,
            eventScheduleView.eventDateUntil,
            _eventScheduleView.eventDate,
            _eventScheduleView.eventDateUntil,
          ) ) {
            if ( checked ) {
              viewData.overlappingCounter++;
            } else {
              viewData.overlappingCounter--;
            }
            return;
          }
        }
      });
    }
  }

}
