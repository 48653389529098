<div class="container" [ngClass]="{'fit-vertical': fitVertical}">

  <div
    class="count-label"
  >
    {{getCountLabel()}}
  </div>

  <div
    (filesDropped)="onFile(fileInput, $event)"
    [accept]="accept"
    class="files-area"
    ragFilesDropArea
    data-cy="dragDropFile"
  >
    <rag-file-render
      (remove)="onFileRemove(fileInput, file)"
      *ngFor="let file of files"
      [file]="file"
      [readonly]="readonly"
      [removeConfirmation]="removeConfirmation"
    ></rag-file-render>

    <div *ngIf="count === 0" class="empty-or-readonly">
      <span *ngIf="!readonly" i18n="@@multifile_no_files_selected">Click on the button to add files or drag & drop them into this area.</span>
      <span *ngIf="readonly" i18n="@@mutlifile_readonly_mode">Selecting files is currently disabled.</span>
    </div>
  </div>

  <label class="file-input">
    <div [class.hidden]="readonly" class="icon-wrapper">
      <mat-icon color="primary" svgIcon="upload"></mat-icon>
    </div>

    <input
      #fileInput
      (change)="onFile(fileInput, fileInput.files)"
      [accept]="accept"
      [disabled]="readonly"
      multiple
      type="file"
      data-cy="inputFile"
    />
  </label>
</div>
