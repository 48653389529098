<a class="site-wrapper" style="" [routerLink]="getDetailsLink()" data-cy="cardDetails">

  <div class="card-wrapper" [ngClass]="{'card-wrapper-mobile': !editorMode, 'editor-width': editorMode}">
    <div class="newstyle-card">
      <div class="card-image-wrapper" [ngStyle]="cardBackgroundStyles$ | async">
        <rag-card-status-icon
          *ngIf="getCardStatusIcon(content) as status"
          [status]="status"
        ></rag-card-status-icon>
      </div>
      <div class="mat-card-content">
        <div>
          <p
            *ngIf="content?.title | localeText as title"
            class="mat-card-title font-medium letterspacing-min"
            [matTooltipDisabled]="title.length < 80"
            [matTooltip]="title">{{title}}</p>
          <div>
            <span class="mat-card-subtitle font-light letterspacing-more">{{ getTypeLabel() }}</span>
            <span
              *ngIf="getCurriculumValidUntil(content) as info"
              class="mat-card-subtitle font-light letterspacing-more"
            >
              &mdash;
              <span
                [ngClass]="{'warning': info.isWarning}"
              >{{ info.label }} {{ info.date | formatDate:'date' }}</span>
            </span>
          </div>
        </div>

        <div class="status-wrapper">
          <div class="progress-line-bg">
            <div
              [ngStyle]="{'width': progress}"
              [ngClass]="{'progress-line-green':progress === '100%'}"
              class="progress-line"
              mode="indeterminate"
            ></div>
          </div>
          <div class="percentage status-text-wrapper">
            <span class="font-regular-italic letterspacing-more">{{ progress }} {{ progressSteps }}</span>
          </div>
        </div>
        <div *ngIf="itemsCount > 0; else noData" class="schedules">
          <div class="schedule-picker">
            <button
              (click)="selectedScheduleChanged('previous', $event)"
              mat-icon-button
              type="button"
              [disabled]="itemsCount === 1"
              data-cy="buttonCardLeft"
            >
              <mat-icon svgIcon="chevron-left" class="chevron-icon"></mat-icon>
            </button>

              <rag-content-action class="schedule-info"
                [parent]="content"
                [content]="getExecutableItem(selectedItem)"
                [version]="2"
                [selectedItemTitle]="selectedItem?.title | localeText"
                [matTooltip]="getTooltip(selectedItem)"
                matTooltipClass="tooltip-linebreak"
                matTooltipPosition="above"
              ></rag-content-action>

            <button
              (click)="selectedScheduleChanged('next', $event)"
              mat-icon-button
              type="button"
              [disabled]="itemsCount === 1"
              data-cy="buttonCardRight"
            >
              <mat-icon svgIcon="chevron-right" class="chevron-icon"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
<ng-template #noData>
  <div class="schedules">
    <span i18n="@@no_content_curriculum" class="no-items">There is currently no content available</span>
  </div>
</ng-template>

