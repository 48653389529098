import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, from, map, Observable, toArray } from 'rxjs';
import {
  CurriculumInvoiceGroup,
  CurriculumInvoiceGroupView
} from '../route/admin/admin-curriculum/admin-curriculum-invoice-groups/admin-curriculum-invoice-groups.types';
import { ApiUrls } from './api.urls';
import { ApiResponse, HttpRequestOptions, TrainResponse } from './global.types';
import { Core } from './core.types';

@Injectable({
  providedIn: 'root',
})
export class TagsService {

  constructor(
    private http: HttpClient
  ) {

  }

  getAllTags(): Observable<Array<string>> {
    const url = ApiUrls.getKey('Tags');
    return this.http.get<ApiResponse<Array<{title: string}>>>(url)
      .pipe(map(response => response.tags))
      .pipe(concatMap(tags => from(tags)))
      .pipe(map(t => t.title))
      .pipe(toArray());
  }

  getTagsByContext(arg0: string): Observable<Array<CurriculumInvoiceGroup>> {
    const url = ApiUrls.getKey('GetCurriculumInvoiceGroups');
    return this.http.get<TrainResponse<Array<CurriculumInvoiceGroup>>>(url)
      .pipe(map(response => response.data));
  }

  deleteTagForObjects(tag: string, objType: Core.DistributableType, objIds: Array<number>): Observable<boolean> {
    const url = ApiUrls.getKey('Tags');
    const payload = {
      tag,
      objType,
      objIds
    };
    return this.http.delete<ApiResponse<any>>(url, {
      body: payload
    })
      .pipe(map(response => response.success === true));
  }

  getDropdownOptionsForTags(data): {} {
    return (data ?? [])
      .reduce((pV, dataEntry) => {
        let tags = [];
        if ((typeof (dataEntry.tags) === 'string') && (dataEntry.tags !== '')) {
          tags = this.splitTags(dataEntry.tags);
        } else if (Array.isArray(dataEntry.tags) && (dataEntry.tags.length > 0)) {
          const tag1 = dataEntry.tags[0];
          if (typeof (tag1) === 'string') {
            tags = dataEntry.tags;
          } else if (typeof(tag1?.title) === 'string') {
            tags = dataEntry.tags
              .map(o => o.title);
          }
        }
        tags.forEach(tag => {
          if (pV[tag] != null) {
            return;
          }
          pV[tag] = tag;
        });
        return pV;
      }, {});
  }

  saveInvoiceGroup(prevName: string, _group: CurriculumInvoiceGroupView): Observable<Array<CurriculumInvoiceGroup>> {
    const url = ApiUrls.getKey('Tags') + '/curriculumGroup';
    const payload = {
      prevName,
      name: _group.name,
      description: _group.description,
      curriculaIds: _group.curricula.map(c => c.id)
    };
    return this.http.post<ApiResponse<Array<CurriculumInvoiceGroup>>>(url, payload, HttpRequestOptions)
      .pipe(map(response => response.groups));
  }

  splitTags(tagString: string): string[] {
    if (tagString == null || tagString === '') {
      return [];
    }

    const strings = tagString.split(',');
    strings
      .map(tag => tag.toLowerCase());
    return strings;
  }

}
