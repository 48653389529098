import { Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardStatusIcon } from './card-status-icon.types';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'rag-card-status-icon',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
  ],
  templateUrl: './card-status-icon.component.html',
  styleUrls: ['./card-status-icon.component.scss']
})
export class CardStatusIconComponent {

  @Input() status: CardStatusIcon;

  getTemplate(
    tplEmpty: TemplateRef<unknown>,
    tplImage: TemplateRef<unknown>,
    tplSvgIcon: TemplateRef<unknown>,
  ): TemplateRef<unknown> {
    if (this.status?.svgIcon) {
      return tplSvgIcon;
    } else if (this.status?.src) {
      return tplImage;
    }
    return tplEmpty;
  }

}
