import { UserProfileAttribute, UserProfileResponse, UserProfileValidationError } from './user-profile.types';
import { ProfileSectionTypes } from '../../../component/input/profile-section/profile-section.types';
import { ProfileFieldTypes } from '../../../core/input/profile-field/profile-field.types';
import { LanguageHelper } from '../../../core/language.helper';
import { AnyObject } from '../../../core/core.types';
import { naturalCompare } from '../../../core/natural-sort';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { InputHelper } from '../../../component/input/input.types';


export class UserProfileHelper {

  public static asProfileSections(
    data: UserProfileResponse | null,
    disableCredentials = true,
    hideCredentials = false,
    processState?: AnyObject,
  ): ProfileSectionTypes.ProfileSection[] {

    const groups = data?.groups;
    const attributes = data?.attributes;
    if ( attributes == null || !(groups?.length > 0) ) {
      return [];
    }

    return groups
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((group, index) => {
        const groupId = group.id;
        const fields = (attributes[groupId] ?? [])
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map(attribute => {

            let editable = attribute.editable;
            const fieldId = attribute.fieldId;
            switch ( fieldId ) {
              case 'login':
              case 'pass':
              case 'password':

                if ( hideCredentials ) {
                  // hide credential inputs -> e.g. for self registration from SSO
                  return null;
                }

                if ( disableCredentials ) {
                  // keep login and password read-only in overview -> edit in dialog
                  editable = false;
                }
            }

            if ( processState?.[fieldId] ) {
              attribute.value = processState[fieldId];
              editable = false;
            }

            return UserProfileHelper.getAttributeAsProfileField(attribute, editable);
          })
          .filter(o => o != null);

        if ( !(fields.length > 0) ) {
          return null;
        }

        const column = index & 1;
        const title = group.title;
        return { column, divisionId: '', fields, title } as ProfileSectionTypes.ProfileSection;
      })
      .filter(o => o != null);
  }

  public static getAttributeAsProfileField(
    attribute: UserProfileAttribute | null,
    editable: boolean = attribute?.editable,
  ): ProfileFieldTypes.ProfileField | null {

    if ( attribute == null ) {
      return null;
    }

    const options = attribute.options
      ?.map(o => ({
        key: o.key,
        value: LanguageHelper.objectToText(o.value) }))
      .sort((a, b) => naturalCompare(a.value, b.value));

    return ({
      consentText: attribute.consentText,
      editable,
      fieldId: attribute.fieldId,
      label: LanguageHelper.objectToText(attribute.label),
      info: LanguageHelper.objectToText(attribute.info),
      required: attribute.required,
      setByInterface: attribute.setByInterface,
      type: attribute.type,
      value: attribute.value,
      options: options ?? null,
    });
  }

  public static getAttributeField(
    data: UserProfileResponse | null,
    fieldId: string,
  ): ProfileFieldTypes.ProfileField | null {

    for ( const attributes of Object.values(data?.attributes ?? {}) ) {
      const attribute = attributes.find((o: UserProfileAttribute) => o.fieldId === fieldId);
      if ( attribute != null ) {
        return UserProfileHelper.getAttributeAsProfileField(attribute);
      }
    }
    return null;
  }

  public static getErrorMessage(
    httpError: HttpErrorResponse | string | null,
  ): string | null {

    if ( (httpError == null) || (typeof (httpError) === 'string') ) {
      return httpError as string;
    }

    const error = httpError.error;
    if ( error == null ) {
      return null;
    }

    const errorCode = error.errorCode ?? (httpError as AnyObject).errorCode;

    if ( ((errorCode === 'gen3') || (errorCode === 'VALIDATION_ERROR')) && (error.validationErrors?.length > 0) ) {
      return (error.validationErrors as UserProfileValidationError[])
        .map(o => UserProfileHelper.getErrorMessageFromCode(o.message))
        .join('<br/>');

    } else if ( errorCode != null ) {
      return UserProfileHelper.getErrorMessageFromCode(errorCode);

    } else if ( typeof (error) === 'string' ) {
      return error;
    }

    return null;
  }

  public static getErrorMessageFromCode(
    errorCode: string | null,
  ): string | null {
    switch ( errorCode ) {
      case '#UOLD1':
        return $localize`:@@profile_component_login_not_empty:Login should not be empty.`;
      case '#UOLD2':
        return $localize`:@@profile_component_login_already_taken:This user already exists.`;
      case '#UOLD3':
        return $localize`:@@profile_component_min_eight_character:
          Password validation rules not matching, default at least 8 signs.`;
      case '#UOLD4':
        return $localize`:@@profile_component_same_new_old_password:
         Please enter a password that is different from the current one.`;
      case '#UOLD5':
      case '#UOLD7':
        return $localize`:@@profile_component_update_failed:Update failed. The new login data could not be persisted.`;
      case '#UOLD6':
        return $localize`:@@profile_component_current_password_wrong:Please enter your current password correctly.`;
    }
    return null;
  }

  public static hasEditableAttributes(data: UserProfileResponse) {

    if ( data?.attributes == null ) {
      return false;
    }

    return Object.values(data.attributes)
      // find any profile group that contains editable attributes
      .find(attributes => attributes
        // find any attribute that is editable
        ?.find((attribute: UserProfileAttribute) => attribute?.editable === true) != null,
      ) != null;
  }

  public static mergeFormGroup(
    data: UserProfileResponse,
    formGroup: FormGroup,
  ): void {

    const relevantControls = Object.keys(formGroup.controls).reduce((pV, key) => {
      pV[key] = false;
      return pV;
    }, {});

    Object.values(data?.attributes ?? {})
      .flatMap(o => o)
      .forEach((attribute: UserProfileAttribute) => {

        const fieldId = attribute.fieldId;
        // mark field as still relevant
        relevantControls[fieldId] = true;

        let formControl = formGroup.get(fieldId);
        if ( formControl != null ) {
          InputHelper.toggleEnabled(formControl, attribute.editable);
          InputHelper.clearStatus(formControl);
          formControl.setValue(ProfileFieldTypes.toControlValue(attribute.type, attribute.value));
          return;
        }

        formControl = new UntypedFormControl({
          value: ProfileFieldTypes.toControlValue(attribute.type, attribute.value),
          disabled: !attribute.editable,
        }, attribute.required ? [ Validators.required ] : []);
        formGroup.addControl(fieldId, formControl);
      });

    // remove any control that is no longer relevant
    Object.entries(relevantControls)
      .forEach(([ key, relevant ]) => {

        if ( relevant ) {
          // still relevant -> no further checks needed
          return;
        }

        const match = /^(.+)Repeat$/.exec(key);
        if ( (match != null) && Object.prototype.hasOwnProperty.apply(formGroup.controls, [match[1]]) ) {
          // this is a "repeat" control with existing "parent" -> preserve
          return;
        }

        // no longer relevant -> remove from form group
        formGroup.removeControl(key);
      });
  }

}
