import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtOauth2ListComponent } from './ext-oauth2-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TablesColumnRenderDefaultModule } from '../../../../../component/table/tables-column-render-default/tables-column-render-default.module';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { TableHeaderFilterDefaultModule } from '../../../../../component/table/table-header-filter-default/table-header-filter-default.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TableColumnMenuModule } from '../../../../../component/table/table-column-menu/table-column-menu.module';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { StickyScrollComponent } from 'src/app/component/sticky-scroll/sticky-scroll.component';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { TableFilterComponent } from '../../../../../component/table-filter/table-filter.component';


@NgModule({
  declarations: [
    ExtOauth2ListComponent,
  ],
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    PageHeaderComponent,
    PipesModule,
    StickyScrollComponent,
    TableColumnMenuModule,
    TableHeaderFilterDefaultModule,
    TablesColumnRenderDefaultModule,
    MatDividerModule,
    RouterLink,
    TableFilterComponent,
  ],
})
export class ExtOauth2ListModule {
}
