<rag-dialog-header>
  <span i18n="@@general_dialog_pleaseconfirm_title">Please confirm</span>
</rag-dialog-header>

<div mat-dialog-content>
  <p i18n="@@do_you_really_want_to_log_out">Do you really want to log out?</p>
</div>

<mat-dialog-actions>
  <button (click)="onStayClick()"  mat-stroked-button>
    <span i18n="@@stay_button">Stay</span>
  </button>
  <button (click)="onLogoutClick()" autofocus color="primary"  mat-flat-button>
    <span i18n="@@logout_button">Logout</span>
  </button>
</mat-dialog-actions>
