import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';
import { takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { EMAIL_PATTERN } from '../../../../core/core.types';
import { InputHelper } from '../../input.types';


@Component({
  selector: 'rag-profile-field-email',
  templateUrl: './profile-field-email.component.html',
  styleUrls: [ './profile-field-email.component.scss' ],
})
export class ProfileFieldEmailComponent
  extends AbstractProfileFieldComponent<string>
  implements OnInit {

  protected readonly MAIL_PATTERN = EMAIL_PATTERN;

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  getError(): string | null {

    const control = this.form.get(this.field.fieldId);
    if ( control == null ) {
      return null;
    }

    if ( control.hasError('required') ) {
      return $localize`:@@validation_required:This field is required`;
    }

    if ( control.hasError('email') || control.hasError('pattern') ) {
      return $localize`:@@validation_email_not_valid:Please enter a valid email address.`;
    }

    if ( control.hasError('not_match') ) {
      return $localize`:@@validation_email_not_match:The emails do not match!`;
    }

    return InputHelper.getError(control);
  }

  getErrorRepeat(): string | null {
    const control = this.form.get(this.field.fieldId + 'Repeat');
    if ( control == null ) {
      return null;
    }

    if ( control.hasError('not_match') ) {
      return $localize`:@@validation_email_not_match:The emails do not match!`;
    }

    return InputHelper.getError(control);
  }

  ngOnInit() {
    super.ngOnInit();

    this.checkRepeatControl();

    // clear repeat field if original changes
    this.form.get(this.field.fieldId).valueChanges
      .pipe(tap(() => this.checkDisabledRepeat()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  shouldShowEMailRepeat(): boolean {
    if (this.disabled === true) {
      return false;
    }

    const control = this.form.get(this.field.fieldId);
    return control?.dirty && control.valid;
  }

  protected checkDisabled() {
    super.checkDisabled();

    if ( this.isDisabled() ) {
      this.checkDisabledRepeat();
    }
  }

  private checkDisabledRepeat() {

    const control = this.form?.get(this.field.fieldId);
    if (control == null) {
      return;
    }

    this.checkRepeatControl();
    const repeatControl = this.form.get(this.field.fieldId + 'Repeat');

    // clear repeat value to force re-validation
    repeatControl.setValue(null);

    // unlock repeat control if original is not empty
    if ( !this.isDisabled() && control.dirty && (control.value != null) && (control.value !== '') ) {
      repeatControl.enable();
    } else {
      repeatControl.disable();
    }
  }

  private checkRepeatControl() {

    const fieldId = this.field.fieldId + 'Repeat';
    if ( this.form.contains(fieldId) ) {
      return;
    }

    // add repeat control with input comparison
    const validators = this.field.required ?
      [ Validators.required, this.compareInputs ] :
      [ this.compareInputs ];

      // TF-9169 addControl invalidates all controls errors.
      const controlErrors = new Map<string, any>();
      Object.keys(this.form.controls).forEach(controlId => {
        const control = this.form.get(controlId);
        if (control.errors !== null && Object.keys(control.errors).length > 0) {
          controlErrors.set(controlId, control.errors);
        }
      });

    const newControl = new FormControl({
      value: null,
      disabled: true,
    }, validators);

    this.form.addControl(fieldId, newControl, { emitEvent: false });

    newControl.markAsTouched();

    if (controlErrors.size > 0) {
      controlErrors.forEach((errors: any, controlId: string) => {
        this.form.get(controlId).setErrors(errors);
      });
    }
  }

  private compareInputs = (): ValidationErrors | null => {
    const value = this.form.get(this.field.fieldId)?.value;
    const valueRepeat = this.form.get(this.field.fieldId + 'Repeat')?.value;
    if ( (value?.length > 0) && (value !== valueRepeat) ) {
      return { not_match: true };
    } else {
      return null;
    }
  };
}
