import * as moment from 'moment';

window['moment'] = moment;

let timeZone: string;
try {
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Europe/Berlin';
} catch ( e ) {
  timeZone = 'Europe/Berlin';
}

// replace me with real state handling
export const hostName = document.location.hostname;
export const url = document.location.protocol + '//' + hostName;

export class AppState {

  timeZone = timeZone;
  private _language = 'en';

  get language(): string {
    return this._language;
  }

  set language(locale: string) {
    if ( !locale ) {
      return;
    } else if ( locale.length >= 2 ) {
      locale = locale.toLocaleLowerCase().substr(0, 2);
    }
    if ( locale === 'de' ) {
      this._language = 'de';
    } else if ( locale === 'fr' ) {
      this._language = 'fr';
    } else if ( locale === 'pl' ) {
      this._language = 'pl';
    } else {
      this._language = 'en';
    }
  }

  get botLanguage() {
    const language = this._language ?? navigator.language.substring(0,2);
    switch (language) {
      case 'en': return 'english';      
      case 'pl': return 'polish';        
      case 'fr': return 'french';
      default:
        return 'german';
    }
  }

}

export const State = new AppState();
