<a
  class="site-wrapper"
  [routerLink]="getDetailsLink()"
  tabindex="0"
  data-cy="cardDetails">

  <div class="card-wrapper" [ngClass]="{'card-wrapper-mobile': !editorMode, 'editor-width': editorMode}">
    <div class="newstyle-card">
      <div class="image-wrapper"
           [ngClass]="{'height-33':(height>50),'height-50':(height<=50)}"
           [ngStyle]="cardBackgroundStyles$ | async"
      >
        <div class="live" *ngIf="hasLiveEvent">
          <mat-icon class="live-content live-fade-in" svgIcon="circle-medium"></mat-icon>
          <span class="live-content">LIVE</span>
        </div>
        <div class="tag" *ngIf="offlineContentSubType as type">
          <mat-icon class="type icon" [svgIcon]="iconForType"></mat-icon>
          <span class="type font-medium">{{type}}</span>
        </div>
      </div>

      <div class="mat-card-content">

        <div>
          <p
            *ngIf="content?.title | localeText as title"
            class="mat-card-title font-medium letterspacing-min"
            [matTooltipDisabled]="title.length < 80"
            [matTooltip]="title">{{title}}</p>
          <div class="mat-card-subtitle-wrapper">
            <p class="mat-card-subtitle font-light letterspacing-more">{{objSubTypeAsText}}</p>
            <p class="mat-card-subtitle event-schedule" *ngIf="nextEvent != null">
              <span class="font-light">{{nextEvent?.eventDate | formatDate:'date'}}</span>
              <span class="point-centered">🞄</span>
              <span class="font-light">{{nextEvent?.eventDate | formatDate:'time'}}</span>
            </p>
          </div>
        </div>
        <div class="mat-card-bottom-wrapper">
          <a
            mat-flat-button
            color="primary"
            class="button"
            [routerLink]="getDetailsLink()"
            data-cy="buttonCardDetails"
          >
            <span i18n="@@global_details">Details</span>
          </a>
        </div>
      </div>
    </div>
  </div>

</a>
