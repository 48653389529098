import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import * as moment from 'moment';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { CachedSubject } from 'src/app/core/cached-subject';
import { CatalogService } from 'src/app/core/catalog/catalog.service';
import { Core, FileInfo, Imageable, ParentfulDistributable } from 'src/app/core/core.types';
import { ExtLoginsService } from 'src/app/core/ext-logins.service';
import { ImageUrlHelper } from 'src/app/core/image-url-helper';
import { InfoService } from 'src/app/core/info/info.service';
import { EventAccountStatusEnum, LearnerEventAccountView } from 'src/app/core/learner-account/learner-account.types';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { PostToBlankComponent } from '../../../../../component/post-to-blank/post-to-blank.component';
import { ApiUrls } from '../../../../../core/api.urls';
import { RagHttpParameterCodec } from '../../../../../core/rag-http-parameter-codec.class';
import { UserNameHelper } from '../../../../../core/user-name.helper';
import { ContentOfflineService } from '../content-offline.service';
import { OfflineEventViewData } from '../content-offline.types';
import { CatalogDetailsOfflineData } from '../../../../../component/catalog/catalog-booking-dialog/catalog-booking-dialog.types';

@Component({
  selector: 'rag-content-offline-view',
  templateUrl: './content-offline-view.component.html',
  styleUrls: ['./content-offline-view.component.scss']
})
export class ContentOfflineViewComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() offlineEvents: Array<OfflineContent.EventSchedule>;
  @Input() offlineEventAccounts?: Array<LearnerEventAccountView>;
  @Input() offlineContent: OfflineContent.Event;
  @Input() canChooseEventSchedules = false;
  @Output() payNow: EventEmitter<OfflineEventViewData>;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  offlineEventsViewData = new Array<OfflineEventViewData>();
  canActWithEventSchedules = false;
  payNowDisabled$: Observable<boolean>;
  blockEventDataLoading$: Observable<boolean>;
  selectedModuleId: number;
  selectedEvents: Map<number, Set<number>> = new Map();
  detailsOfflineParams: CatalogDetailsOfflineData;
  preSelection: Array<ParentfulDistributable>;

  private _payNowDisabled$ = new CachedSubject<boolean>(false);
  private _blockEventDataLoading$ = new CachedSubject<boolean>(false);

  constructor(
    private principalService: PrincipalService,
    private extLoginsService: ExtLoginsService,
    private contentOfflineService: ContentOfflineService,
    private catalogService: CatalogService,
    private infoService: InfoService
    ) {
      this.payNowDisabled$ = this._payNowDisabled$.asObservable();
      this.payNow = new EventEmitter();
      this.blockEventDataLoading$ = this._blockEventDataLoading$.asObservable();
  }

  ngOnInit(): void {
    // TF-10375 load blockEvent data for booking
    if (this.offlineContent.blockEvent &&
      (this.offlineEventAccounts === undefined || this.offlineEventAccounts.length === 0) )
    {
      this._blockEventDataLoading$.next(true);
      // deatilsOfflineParams
      forkJoin([
        this.catalogService.getCatalogEntryForContent(Core.DistributableType.lms_offlineCnt, this.offlineContent.id, null),
        this.catalogService.searchForEvents(this.offlineContent.id, Core.DistributableType.lms_offlineCnt, null)
      ])
      .pipe(catchError(errorResponse => {
        if (typeof errorResponse.error === 'object' && errorResponse.error.errors?.length > 0) {
          let message: string;
          const errorCat16 = errorResponse.error.errors.find(err => err.errorCode === 'ERR_CAT_016');
          if (errorCat16 != null) {
            // the content is in none catalog available
            message = $localize`:@@catalog_content_no_longer_available:Selected content is no longer available`;
          } else {
            message = $localize`:@@general_error:The last operation failed. Please try again later.`;
          }
          this.infoService.showAlert(message).subscribe();
          return EMPTY;
        }
      }))
      .pipe(map(([ catalogEntry, offlineContents ]) => this.detailsOfflineParams = ({
        catalogEntry,
        offlineContents,
      })))
      .pipe(tap(_ => this._blockEventDataLoading$.next(false)))
      .subscribe();
    }
  }

  accessPending(viewData: OfflineEventViewData) {
    const status = viewData.offlineEventAccount.status;
    return status === EventAccountStatusEnum.DESIRED || status === EventAccountStatusEnum.INVITED;
  }

  ngAfterViewInit() {
    if (this.offlineEventsViewData.length === 1) {
      this.accordion.openAll();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareViewData();
  }

  isReservation(eventData: OfflineEventViewData) {
    return eventData.reservation;
  }

  getEventPicture(value: FileInfo & Imageable) {
    return ImageUrlHelper.urlForPicture(value?.id, value?.uuid);
  }

  getOfflineEventAccount(offlineEventId: number): LearnerEventAccountView {
    return this.offlineEventAccounts.find(acc => acc.id === offlineEventId);
  }

  getTrainerName(trainer: OfflineContent.Trainer): string {
    return UserNameHelper.getFullNameWithTitle(trainer?.firstName, trainer?.lastName, trainer?.academicTitle);
  }

  hasAttachments(offlineEvent: OfflineContent.EventSchedule) {
    return offlineEvent.attachments && offlineEvent.attachments.length > 0;
  }

  hasCertificates(viewData: OfflineEventViewData): boolean {
    return this.hasUserAttendedAndTemplateIsDeposited(viewData);
  }

  hasUserAttendedAndTemplateIsDeposited(event: OfflineEventViewData): boolean {
    const offlineEvent = event?.offlineEvent;
    const eventInPast = moment().isAfter(offlineEvent?.eventDate);
    const hasParticipationConfirmationTemplate = (this.offlineContent?.participationConfirmationTemplateId > 0);
    const participationStatus = OfflineContent.offlineParticipationStatusFactory(event?.offlineEventAccount?.status);
    return eventInPast && hasParticipationConfirmationTemplate && (participationStatus === OfflineContent.OfflineParticipationStatus.PARTICIPATED);
  }

  dateFormatted(timestamp: number) {
    return moment(timestamp).format('ll');
  }

  offlineEventById(offlineEventId: number) {
    return this.offlineEvents.find(event => event.id === offlineEventId);
  }

  isExtLoginButtonAvailable(viewData: OfflineEventViewData) {
    return !viewData.isAdobeConnectServer ||
      (viewData.offlineEventAccount.status === EventAccountStatusEnum.ACCEPTED ||
      viewData.offlineEventAccount.status === EventAccountStatusEnum.PARTICIPATED);
  }

  isGeneralExtLoginButtonEnabled(viewData: OfflineEventViewData) {
    const offlineEvent = viewData.offlineEvent;
    return this.isButtonToBeEnabled(viewData,
      offlineEvent.eventDate,
      offlineEvent.eventDateUntil,
      offlineEvent.extLoginMinsPrefix,
      offlineEvent.extLoginMinsSuffix
      );
  }

  hasRecordingUrl(viewData: OfflineEventViewData) {
    const recordingUrl = viewData.offlineEvent.recordingUrl;
    return (recordingUrl != null && recordingUrl !== '');
  }

  getButtonLabel(viewData: OfflineEventViewData) {
    if ( this.isEventInPastAndOutsideGracePeriod(viewData) && this.hasRecordingUrl(viewData) ) {
      return $localize`:@@content_offline_view_show_recording:Show Recording`;
    } else {
      return $localize`:@@global_ac_enter_room:Enter room`;
    }
  }

  getConnectNotice(viewData: OfflineEventViewData, timeBlock: OfflineContent.TimeBlock): string | null {

    if (this.isExtLoginButtonEnabled(viewData, timeBlock)) {
      return $localize`:@@global_ac_room_availabe:The virtual event room has been opened. You can now enter the room.`;
    }

    if (this.accessPending(viewData)) {
      return $localize`:@@content_offline_view_access_pending:The request is still pending. Until then, you will not have access to the room.`;
    } else {
      return $localize`:@@global_ac_room_disabled:The virtual room is currently not available`;
    }
  }

  getNotice(viewData: OfflineEventViewData): string | null {
    const status = viewData.offlineEventAccount.status;
    if ( this.isJoinButtonDisabled(viewData) &&
      (status === EventAccountStatusEnum.DESIRED || status === EventAccountStatusEnum.INVITED) ) {
      return $localize`:@@content_offline_view_access_pending:The request is still pending. Until then, you will not have access to the room.`;
    }

    if ( this.isEventInPastAndOutsideGracePeriod(viewData) ) {
      if ( this.hasRecordingUrl(viewData) ) {
        return $localize`:@@content_offline_view_record_available:The recording of the schedule is available.`;
      } else {
        return $localize`:@@content_offline_view_event_expired:You can no longer enter the room because the schedule has expired.`;
      }
    } else {
      if ( this.isGeneralExtLoginButtonEnabled(viewData) ) {
        return $localize`:@@global_ac_room_availabe:The virtual event room has been opened. You can now enter the room.`;
      } else {
        return $localize`:@@global_ac_room_disabled:The virtual room is currently not available`;
      }
    }
  }

  isExtLoginButtonEnabled(viewData: OfflineEventViewData, timeBlock: OfflineContent.TimeBlock) {
    const offlineEvent = viewData.offlineEvent;
    return this.isButtonToBeEnabled(viewData,
      timeBlock.startDate,
      timeBlock.endDate,
      timeBlock.extLoginMinsPrefix || offlineEvent.extLoginMinsPrefix,
      timeBlock.extLoginMinsSuffix || offlineEvent.extLoginMinsSuffix
    );
  }

  isButtonToBeEnabled(viewData, startDate, endDate, minsPrefix, minsSuffix) {

    if (!this.isExtLoginButtonAvailable( viewData )) {
      return false;
    }

    const now = moment();

    let begin = moment(startDate);
    let end = moment(endDate);

    begin = begin.add(-minsPrefix, 'minutes');
    end = end.add(minsSuffix, 'minutes');
    return now.isBetween(begin, end);
  }

  isEventInPastAndOutsideGracePeriod(event: OfflineEventViewData): boolean {
    const endDate = moment(event.offlineEvent.eventDateUntil);
    if ( event.isAdobeConnectServer && (event.offlineEvent.extLoginMinsSuffix > 0) ) {
      endDate.add(event.offlineEvent.extLoginMinsSuffix, 'minutes');
    }
    const today = new Date().getTime();
    return moment(today).isAfter(endDate);
  }

  isJoinButtonDisabled(viewData: OfflineEventViewData) {
    const status = viewData.offlineEventAccount.status;
    if (status === EventAccountStatusEnum.DESIRED || status === EventAccountStatusEnum.INVITED) {
      return true;
    }

    if ( !this.isEventInPastAndOutsideGracePeriod(viewData) ) {
      return !this.isGeneralExtLoginButtonEnabled(viewData);
    } else if ( this.hasRecordingUrl(viewData) ) {
      return false;
    }
    return true;
  }

  sameDay(date1: number, date2: number) {
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment1.get('date') === moment2.get('date') &&
      moment1.get('month') === moment2.get('month') &&
      moment1.get('year') === moment2.get('year');
  }

  timeBlockUrl(viewData: OfflineEventViewData, timeBlock: OfflineContent.TimeBlock): Observable<string> {
    const offlineEvent = viewData.offlineEvent;
    if ( offlineEvent.extLogin != null ) {
      return this.extLoginsService.urlForExtLogin(offlineEvent.extLogin, timeBlock.extLoginPath);
    }
    return of(null);
  }

  timeBlockUrlAsGest(viewData: OfflineEventViewData, timeBlock: OfflineContent.TimeBlock): Observable<string> {

    const offlineEvent = viewData.offlineEvent;

    if (viewData.isAdobeConnectServer) {
      const user = this.principalService.currentUser;
      const name = `${encodeURIComponent(user.firstname)}%20${encodeURIComponent(user.lastname)}`;
      return of(`${offlineEvent.extLogin.url}${timeBlock.extLoginPath}?guestname=${name}&launcher=false`);
    } else {
      return this.extLoginsService.urlForExtLogin(offlineEvent.extLogin, timeBlock.extLoginPath);
    }
  }

  timeFormatted(timestamp: number) {
    return moment(timestamp).format('LT');
  }

  joinTimeBlock(viewData: OfflineEventViewData, timeBlock: OfflineContent.TimeBlock) {
    const offlineEvent = viewData.offlineEvent;
    this.timeBlockUrlAsGest(viewData, timeBlock)
      .pipe(take(1))
      .subscribe(url => {
        this.contentOfflineService.joinEvent(
          offlineEvent.contentId, offlineEvent.id, url, offlineEvent.eventDate);
      });
  }

  joinEvent(viewData: OfflineEventViewData) {
    const eventSchedule = viewData.offlineEvent;
    if ( this.isEventInPastAndOutsideGracePeriod(viewData) && this.hasRecordingUrl(viewData) ) {
      window.open(eventSchedule.recordingUrl, '_blank');
      return;
    }

    this.extLoginsService
      .urlAsGuest(eventSchedule.extLogin, eventSchedule.extLoginPath)
      .pipe(take(1))
      .subscribe(url => {
        this.contentOfflineService.joinEvent(eventSchedule.contentId, eventSchedule.id, url, eventSchedule.eventDate);
      });
  }

  shouldShowTimeBlockLink(viewData: OfflineEventViewData, timeBlock: OfflineContent.TimeBlock): boolean {

    const offlineEvent = viewData?.offlineEvent;
    if (offlineEvent?.extLogin == null) {
      // no external resource selected
      return false;
    }

    if (viewData.isAdobeConnectServer !== true) {
      // only adobe connect type allows selecting different rooms for each agenda item
      return false;
    }


    return timeBlock.extLoginPath != null;
  }

  showEventDescription(localizedEventDescription: string, localizedContentDescription: string): boolean {
    return localizedEventDescription?.length > 0 && localizedEventDescription !== localizedContentDescription;
  }

  onSelection($event: Array<ParentfulDistributable>) {
    this.preSelection = $event;
  }

  onGenerateRegistrationConfirmationPdf(eventId: number, postToBlank: PostToBlankComponent): void {
    const jsonData = {
      contentId: this.offlineContent.id,
      eventId: eventId,
      contentType: 'participationConfirmPdf',
      userIds: this.principalService.userId,
    };
    const url = ApiUrls.getKey('CtrlOfflineParticipantListExport');
    const params = new HttpParams({encoder: new RagHttpParameterCodec()})
      .append('json', JSON.stringify(jsonData));
    postToBlank.executePost(url, params);
  }

  onPayNow(eventData: OfflineEventViewData) {
    this._payNowDisabled$.next(true);
    this.payNow.emit(eventData);
  }

  private prepareViewData() {
    this.offlineEventsViewData = this.offlineEventAccounts?.map(offlineEventAccount => {
      const viewData: OfflineEventViewData = {
        isAdobeConnectServer: false,
        timeBlocksSorted: [],
        trainersAsText: '',
        isEventClosed: false,
        isEventInvitation: false,
        offlineEvent: null,
        offlineEventAccount,
        reservation: offlineEventAccount.reservation
      };
      const offlineEvent = this.offlineEventById(offlineEventAccount.offlineEventId);
      viewData.offlineEvent = offlineEvent;
      viewData.isAdobeConnectServer = offlineEvent.extLogin?.serverType === 'adobe_connect';


      viewData.timeBlocksSorted = offlineEvent.timeBlocks?.sort((tb1, tb2) => tb1.startDate - tb2.startDate);

      if ( offlineEvent.trainers?.length > 0 ) {
        viewData.trainersAsText = offlineEvent.trainers.reduce((pV, trainer, index) => {
          let trainerTitle = '';
          if (trainer.academicTitle != null) {
            if (trainer.academicTitle === '-') {
              trainerTitle = '';
            } else {
              trainerTitle = trainer.academicTitle;
            }
          }
          return pV + (index > 0 ? ', ' : '') + `${trainerTitle} ${trainer.firstName} ${trainer.lastName}`;
        }, '');
      }

      if ( OfflineContent.isComplete(offlineEvent.closedStatus) ) {
        viewData.isEventClosed = true;
        viewData.isEventInvitation = false;
      } else {
        viewData.isEventClosed = false;
        const eventDate = moment(offlineEvent.eventDate);
        viewData.isEventInvitation = eventDate.isValid() && eventDate.isAfter(moment());
      }

      this.canActWithEventSchedules = !viewData.isEventClosed;

      return viewData;
    }).sort((offlineEvent1, offlineEvent2) => offlineEvent1.offlineEvent.eventDate - offlineEvent2.offlineEvent.eventDate) ?? [];
  }

}
