import { Bots } from 'src/app/core/bots/bots.types';
import { Catalogs } from '../../core/catalog/catalog.types';
import moment from 'moment';
import { DateHelper } from 'src/app/core/date.helper';
import { TextHelper } from 'src/app/core/text.helper';
import { LanguageHelper } from 'src/app/core/language.helper';

export class CatalogBotHelper {

  static botifyCatalogBooking(booking: Catalogs.CatalogBooking): Bots.CatalogBooking {
    return {
      bookingUUID: booking.bookingUUID,
      bookedAt: DateHelper.formatDate(booking.whenCreated),
      reservation: booking.reservation,
      price: booking.price / 1000,
      title: LanguageHelper.objectToText(booking.title),
      description: TextHelper.cutTo( LanguageHelper.objectToText(booking.description), 150)
    }
  }
  
  /**
   * converts Catalog.Entry object into suitable for the bot one
   */
  static botifyCatalogEntry(entry: Catalogs.CatalogEntry): Bots.CatalogEntry {
    let price = 0;
    let paidPrice: number = null;
    let availablePlaces: number = null;
    // handling booking data
    const bookedAt = entry.catalogBooking !== undefined ? DateHelper.formatDate(entry.catalogBooking.whenCreated) : null;
    if (bookedAt !== undefined) {
      paidPrice = (entry.catalogBooking.price > 0) ? entry.catalogBooking.price / 100 : 0;
    } else {
      availablePlaces = Object.values(entry.availableModulePlaces)
        .reduce((pV, count) => Math.max(pV, count), 0);
      price = entry.priceForUserInCent !== undefined ? entry.priceForUserInCent / 100 : 0;
    }    
    return {
      id: entry.id,
      title: LanguageHelper.objectToText(entry.title),
      description: TextHelper.cutTo(LanguageHelper.objectToText(entry.description), 150),
      availablePlaces,
      price,
      currency: entry.priceCurrency,
      link: '',
      bookedAt,
      paidPrice,
      pictureUUID: entry.pictureUUID
    };
  }

  static addDetailPageUrls(
    contents: Catalogs.CatalogEntry[],
    getUrl: (content: Catalogs.CatalogEntry) => (string | null))
  {
    contents = contents.map(content => {
      const catalogUrl = getUrl(content);
      content['detailPageUrl'] = ((catalogUrl == null) ? '' : location.origin + '/#' + catalogUrl);
      return content;
    })
  }
}
