import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { CatalogHelper } from 'src/app/core/catalog/catalog.helpers';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { Assignable, Core, Statusable, Titleable, Translateable } from 'src/app/core/core.types';
import { InfoService } from 'src/app/core/info/info.service';
import { PreloadService } from 'src/app/core/preload.service';
import * as uuid from 'uuid';
import { CatalogService } from '../../../../core/catalog/catalog.service';
import { ContentService } from '../../../../core/content/content.service';
import { DisplayStatusHelper } from '../../../../core/display-status-helper';
import { DisplayStatus } from '../../../../core/display-status.enum';
import { LearnerAccountService } from '../../../../core/learner-account/learner-account.service';
import {
  LearnerAccountOfflineContentViewV2,
  LearnerCurriculumAccountView,
  LearnerCurriculumItemAccountView,
  LearnerEventAccountView,
  LearnerOfflineAccountView
} from '../../../../core/learner-account/learner-account.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { AccountDesignService } from '../../../admin/account-design/account-design.service';
import { StyleSettings } from '../../../admin/account-design/account-design.types';
import { ContentHelper } from '../content.helper';
import { ContentOfflineService } from './content-offline.service';
import { ViewHelper } from '../../../../core/view-helper';
import { LanguageHelper } from 'src/app/core/language.helper';
import { State } from 'src/app/app.state';
import { InfoType } from 'src/app/core/info/info.types';
import { OfflineEventViewData } from './content-offline.types';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { CatalogBookingHelper } from 'src/app/core/catalog/catalog-booking.helper';


@Component({
  selector: 'rag-content-offline',
  templateUrl: './content-offline.component.html',
  styleUrls: [ './content-offline.component.scss' ],
})
export class ContentOfflineComponent
  implements OnInit, OnDestroy {

  assignmentType: string;
  backUrl: string;
  curriculum: LearnerCurriculumAccountView;
  curriculumItem: LearnerCurriculumItemAccountView;
  contentTitle: Translateable;
  displayStatus: DisplayStatus;
  offlineContent: OfflineContent.Event;
  offlineEvents?: Array<OfflineContent.EventSchedule>;
  offlineEventAccountViews?: Array<LearnerEventAccountView>;
  styleSettings$: Observable<StyleSettings>;
  typeOfTraining: string;
  canChooseEventSchedules = false;
  bookingNotAllowed: boolean;
  bookableImpl: Catalogs.Bookable;
  catalogBooking: Catalogs.CatalogBooking;
  hasOutstandingPayments = false;
  offlineContentAccount: LearnerOfflineAccountView;
  shouldDisplayEventSchedules = true;

  constructor(
    private accountDesignService: AccountDesignService,
    private activatedRoute: ActivatedRoute,
    private contentOfflineService: ContentOfflineService,
    private learnerAccountService: LearnerAccountService,
    private catalogService: CatalogService,
    private router: Router,
    private infoService: InfoService,
    private preloadService: PreloadService,
    private principalService: PrincipalService
  ) {
    this.styleSettings$ = this.accountDesignService.getStyleSettings();

    if ( this.activatedRoute.snapshot.queryParamMap.has('invKey') ) {
      // remove invitation key from url to prevent duplicate actions
      this.router.navigate([ '.' ], { relativeTo: this.activatedRoute, queryParams: {} }).then();
    }

    this.activatedRoute.data
      .pipe(map(response => response.learnerResolver || {}))
      .pipe(map(this.updateData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.contentOfflineService.needsReload$
      .pipe(debounceTime(50))
      .pipe(tap(this.reloadData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.updateData(this.activatedRoute.snapshot.data.learnerResolver || {});
  }

  ngOnInit() {
    this.searchForOutstandingPayments();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  hasAssignedEventSchedules() {
    return this.offlineEventAccountViews?.length > 0;
  }

  isOnlyBookmark(): boolean {
    return this.offlineEventAccountViews === undefined || this.offlineEventAccountViews.length === 0;
  }

  onBookEvent($event: OfflineContent.EventSchedule) {

    this.preloadService.isPaymentModuleEnabled$
      .pipe(take(1))
      .subscribe(isPaymentModuleEnabled => {
        const isPayable = isPaymentModuleEnabled && ($event.priceForUserInCent ?? 0) > 0;
        this.bookEventSchedule(isPayable, $event);
    });
  }

  onCancelEvent($event: OfflineContent.EventSchedule) {
    const viewData = ViewHelper.getViewData($event);
    const isEventBooked = viewData.hasBooked;
    this.contentOfflineService.cancelBookingByEventSchedule(this.offlineContent.id, $event.id, isEventBooked)
      .pipe(take(1))
      .subscribe();
  }

  onCanceled($event: Catalogs.Bookable) {
    this.reloadData();
  }

  onPayEventScheduleNow(eventData: OfflineEventViewData) {
    const userId = this.principalService.currentUser.userId;
    this.catalogService.findBookingForContentAndUser(userId, Core.DistributableType.lms_offline_event, eventData.offlineEvent.id)
    .pipe(switchMap(booking => this.purchaseEventSchedule(booking.bookingUUID, eventData.offlineEvent)))
      .subscribe();
  }

  onPayEventNow() {
    this.purchaseEvent(this.catalogBooking.bookingUUID)
      .subscribe();
  }

  reloadData = (): void => {
    const offlineContentId = this.offlineContent && this.offlineContent.id;
    const rootCurriculumId = this.curriculum && this.curriculum.curriculumId;
    const curriculumItemId = this.curriculumItem && this.curriculumItem.curriculumItemId;
    if ( (rootCurriculumId > 0) && (curriculumItemId > 0) ) {
      this.learnerAccountService.fetchOfflineContentItem(rootCurriculumId, curriculumItemId)
        .pipe(take(1))
        .pipe(map(this.updateData))
        .subscribe();
    } else if ( offlineContentId > 0 ) {
      this.learnerAccountService.fetchOfflineContentDirect(offlineContentId, null, true)
        .pipe(catchError(err => {
          if (err?.status === 404) {
            // redirect to root, if content cannot be found
            this.router.navigateByUrl('/').then();
          }
          return throwError(() => err);
        }))
        .pipe(take(1))
        .pipe(map(this.updateData))
        .subscribe();
    }
  };

  private purchaseEvent(bookingUUID: string): Observable<void> {

    const price = this.offlineContent.priceForUserInCent ?? 0;

    const redirectUrl = CatalogBookingHelper.getDSBRedirect();

    const htmlRender = document.createElement('div');

    let description = LanguageHelper.objectToText(this.offlineContent.description, State.language);
    if (description?.length > 150) {
      description = description.substring(0, 150) + '...';
    }

    htmlRender.innerHTML = description;
    description = htmlRender.textContent;

    const lineItem: Catalogs.LineItem = {
      objectId: this.offlineContent.id,
      objectType: Core.DistributableType.lms_offlineCnt,
      title: LanguageHelper.objectToText(this.offlineContent.title, State.language),
      description,
      quantity: 1,
      price: price,
      priceFloat: price / 100.0,
    };

    if (this.offlineContent.priceComment !== undefined) {
      lineItem.comment = this.offlineContent.priceComment;
    }

    const purchaseRequest: Catalogs.PurchaseRequest = {
      bookingUUID: this.catalogBooking.bookingUUID,
      lineItems: [lineItem],
      redirectAfterErrorUrl: redirectUrl,
      redirectAfterSuccessUrl: redirectUrl
    };

    return this.catalogService.purchase(purchaseRequest).pipe(map(redirectUrl => {
      // redirect to payment gateway
      window.location.replace(redirectUrl);
    }))
    .pipe(catchError(this.handleError));
  }

  private purchaseEventSchedule(bookingUUID: string, $event: OfflineContent.EventSchedule): Observable<void> {
    const htmlRender = document.createElement('div');

    let title = LanguageHelper.objectToText($event.title, State.language);
    let description = LanguageHelper.objectToText($event.description, State.language);
    // handle to long description
    if (description?.length > 150) {
      description = description.substr(0, 150) + '...';
    }

    htmlRender.innerHTML = title;
    title = htmlRender.textContent;

    htmlRender.innerHTML = description;
    description = htmlRender.textContent;

    htmlRender.remove();

    const lineItem: Catalogs.LineItem = {
        objectId: $event.id,
        objectType: Core.DistributableType.lms_offline_event,
        parentObjId: $event.contentId,
        parentObjType: Core.DistributableType.lms_offlineCnt,
        title,
        description,
        quantity: 1,
        price: $event.priceForUserInCent,
        priceFloat: $event.priceForUserInCent / 100.0,
    };

    if ($event.extIdentity != null) {
      lineItem.extId = $event.extIdentity;
    }

    if ($event.priceComment != null) {
      lineItem.comment = $event.priceComment;
    }

    const lineItems = new Array<Catalogs.LineItem>();
    lineItems.push(lineItem);

    const purchaseRequest: Catalogs.PurchaseRequest = {
      redirectAfterSuccessUrl: window.location.href.replace('catalog', 'run'),
      redirectAfterErrorUrl: window.location.href,
      lineItems,
      bookingUUID
    };

    return this.catalogService.purchase(purchaseRequest)
    .pipe(map(redirectUrl => {
      // redirect to payment gateway
      window.location.replace(redirectUrl);
    }))
    .pipe(catchError(this.handleError));
  }

  private handleError = (error: any): Observable<void> => {
    let message: string;
    switch (error.status) {
      case 400:
        message = $localize`:@@global_error_not_authorized:You are not authorized to perform this operation. Please contact your system administrator for more information.`;
        break;
      case 412:
        // the payment module is not enabled
        message = $localize`:@@payment_module_not_enabled:Payments are currently not possible. Please contact the system administrator for assistance.`;
        break;

      default:
        message = $localize`:@@general_error:The last operation failed. Please try again later.`;
    }

    this.infoService.showMessage(message, {
      infoType: InfoType.Error
    });

    return EMPTY;
  }

  private searchForOutstandingPayments() {
    const isReservation = this.catalogBooking?.reservation ?? false;
    this.shouldDisplayEventSchedules = !this.offlineContent.blockEvent || !isReservation;
    this.hasOutstandingPayments = isReservation || this.offlineEventAccountViews.find(event => event.reservation) != null;
  }

  private bookEventSchedule(isPayable: boolean, $event: OfflineContent.EventSchedule) {
    const bookingUUID = uuid.v4();

    this.contentOfflineService.bookEventSchedule(this.offlineContent, $event, bookingUUID, isPayable)
      .pipe(switchMap(booking => {
        if (isPayable) {
          return this.purchaseEventSchedule( booking.bookingUUID, $event );
        }
        return of(void(0));
      }))
      .pipe(take(1))
      .subscribe();
  }

  private setAssignmentType(viewData: Assignable) {
    if ( viewData.assignmentMandatory === true ) {
      this.assignmentType = 'mandatory';
    } else if ( viewData.assignmentMandatory === false ) {
      this.assignmentType = 'voluntary';
    } else {
      this.assignmentType = null;
    }
  }

  private setBackUrl(curriculum: LearnerCurriculumAccountView) {
    // todo handle direct assignment
    let backUrl: string;
    console.log(curriculum);
    if ( curriculum ) {
      backUrl = ContentService.getContentHref({
        id: curriculum.curriculumId,
        type: Core.DistributableType.lms_curriculum,
        archived: undefined,
        assignmentType: undefined,
        description: undefined,
        displaystatus: undefined,
        hasDirectAssignment: undefined,
        hideInLearnerAccount: undefined,
        items: undefined,
        lastModified: undefined,
        objType: Core.DistributableType.lms_curriculum,
        sequentialCur: undefined,
        title: undefined
      }, false);
    }

    if ( backUrl ) {
      this.backUrl = backUrl;
    } else {
      this.backUrl = '/content-overview';
    }
  }

  private setDisplayStatus(curriculumItem: Statusable, content: Statusable) {
    if ( curriculumItem ) {
      // curriculumItem has precedence over offlineEventAccount!
      this.displayStatus = DisplayStatusHelper.toDisplayStatus(curriculumItem.displayStatus);
    } else if ( content ) {
      this.displayStatus = DisplayStatusHelper.toDisplayStatus(content.displayStatus);
    }
  }

  private setPageTitle(
    curriculum: LearnerCurriculumAccountView,
    curriculumItem: LearnerCurriculumItemAccountView,
    offlineEventAccount: Titleable) {

      if ( curriculum && curriculumItem ) {
        // curriculumItem has precedence over offlineEventAccount!
        const itemId = curriculumItem.curriculumItemId;
        // use title from curriculum for breadcrumbs
        this.contentTitle = curriculum.itemTitles && curriculum.itemTitles[itemId] || curriculumItem.title;
      } else if ( offlineEventAccount ) {
        this.contentTitle = offlineEventAccount.title;
      }
  }

  private updateData = (data: LearnerAccountOfflineContentViewV2) => {

    const {
      offlineContent,
      offlineEvents,
      booked,
      curriculum,
      curriculumItem,
      offlineEventAccountViews,
      offlineContentAccount,
      catalogBooking,
     } = data;

     this.offlineContentAccount = offlineContentAccount;
    this.offlineEvents = offlineEvents;
    this.offlineContent = offlineContent;
    this.offlineContent.offlineEvents = offlineEvents;
    this.curriculum = curriculum;
    this.curriculumItem = curriculumItem;
    this.offlineEventAccountViews = offlineEventAccountViews;
    this.catalogBooking = catalogBooking;
    this.bookableImpl = booked !== false ? CatalogHelper.bookableFromBooking(catalogBooking) : null;

    this.bookingNotAllowed =
      this.offlineContent?.assignmentModeForPrincipal === 'admin' && this.offlineEvents?.length > 0;

      // todo: fix this for blockEvents not having events accounts
    this.canChooseEventSchedules =
      !this.bookingNotAllowed &&
      this.offlineEvents?.length > 0 &&
      (
        this.offlineContent.allowedMaxSchedulesCount === 0 ||
        (this.offlineContent.allowedMaxSchedulesCount >= (this.offlineEventAccountViews?.length ?? 0))
      );

    this.setPageTitle(curriculum, curriculumItem, offlineContent);

    this.setAssignmentType(data);
    this.setBackUrl(curriculum);
    this.typeOfTraining = ContentHelper.getTypeOfEvent(offlineContent.eventType);
    this.setDisplayStatus(curriculumItem, offlineContentAccount);
  };

}
