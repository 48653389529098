<div class="container">
  <mat-checkbox
    *ngIf="tcFileUUID !== undefined"
    (change)="onChecked($event, 'tc')"
    [disabled]="tcCheckedDisabled$ | async"
    [color]="'accent'">
    <span i18n="@@global_accept_tc">I have read and accept the <a href="{{tcPdfURL}}" target="_blank">general terms and conditions</a> of the event: "{{eventTitle | localeText}}".</span>
  </mat-checkbox>

  <mat-checkbox
    *ngIf="privacyFileUUID !== undefined"
    (change)="onChecked($event, 'privacy')"
    [disabled]="privacyCheckedDisabled$ | async"
    [color]="'accent'">
    <span i18n="@@global_accept_privacy">I have read and accept the <a href="{{privacyPdfURL}}" target="_blank">privacy policy</a> of the event: "{{eventTitle | localeText}}".</span>
  </mat-checkbox>
</div>