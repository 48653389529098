<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@general_signatures">Signatures</span>
  </div>

  <div
    class="page-header-buttons"
  >
    <a
      color="primary"
      mat-flat-button
      data-cy="buttonCreate"
      [routerLink]="'/admin/notifications/signatures/details/0'">
      <span i18n="@@general_create">Create</span>
    </a>
  </div>
</rag-page-header>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="admin-signatures-list-columns">
</rag-table-column-menu>

<mat-menu #signaturesActionsMenu xPosition="before">
  <ng-template let-signature="signature" matMenuContent>
    <a
      mat-menu-item
      type="button"
      data-cy="menuItemDetails"
      [routerLink]="'/admin/notifications/signatures/details/' + signature.id"
    >
      <mat-icon svgIcon="magnify"></mat-icon>
      <span i18n="@@global_details">Details</span>
    </a>

    <a
      mat-menu-item
      type="button"
      data-cy="menuItemCopy"
      (click)="onCopy(signature)"
    >
      <mat-icon svgIcon="content-copy"></mat-icon>
      <span i18n="@@global_copy">Copy</span>
    </a>

    <mat-divider></mat-divider>

    <button
      type="button"
      mat-menu-item
      data-cy="menuItemDelete"
      (click)="onDelete(signature.id)"
    >
      <mat-icon color="warn" svgIcon="delete-outline"></mat-icon>
      <span i18n="@@global_delete">Delete</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<rag-table-filter
  (filterChange)="onFilterChange($event.filter, $event.column)"
  [menuData]="columnMenuData"
></rag-table-filter>

<ng-container
  *ngIf="isDataLoaded; else tplDataLoading">
  <rag-sticky-scroll [recalculate]="recalculateSticky$" [ngClass]="{'sticky-scroll-height': !isDataEmpty}">
    <ng-container
      *ngIf="isPaginatorSet && isColumnContextLoaded && (renderColumns?.length > 0); else tplDataLoading">
      <table
        *ngIf="!isDataEmpty; else tplDataEmpty"
        [dataSource]="dataSource"
        mat-table
        matSort
      >
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              [row]="row"
              [column]="column"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
            (click)="tableColumnMenu.openMenu($event)"
            mat-icon-button
            data-cy="buttonTableOptions"
            >
            <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let signature" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled"
              [matMenuTriggerData]="{signature: signature}"
              [matMenuTriggerFor]="signaturesActionsMenu"
              data-cy="hamTableRow"
              mat-icon-button>
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div *ngIf="isFilteredEmpty" class="no-data">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>
  <div [ngClass]="{paginatorInVisibility: isFilteredEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>
