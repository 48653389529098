import { Core, ImageableContentReference } from './core.types';

export class CourseTypeHelper {

  static asKeyword(value: Core.CourseType): string {
    switch (value) {
      case Core.CourseType.Learning: return 'learning';
      case Core.CourseType.Link: return 'link';
      case Core.CourseType.ScoDocument: return 'document';
      case Core.CourseType.Test: return 'test';
      case Core.CourseType.ToDo: return 'task';
      default:
        throw new Error('unsupported type : ' + value);
    }
  }

  static getTypeLabel(course: ImageableContentReference): string | null {
    switch (true) {
      case CourseTypeHelper.isCertification(course):
        return $localize`:@@global_distributable_subtype_certification:Certification`;
      case CourseTypeHelper.isSimpleConnect(course):
        return $localize`:@@global_distributable_subtype_simple_connect:SimpleConnect`;
      case CourseTypeHelper.isDocument(course):
        return $localize`:@@global_distributable_subtype_sco_document_multimedia:Document / Multimedia`;
      case CourseTypeHelper.isLink(course):
        return $localize`:@@global_distributable_subtype_link:Link`;
      case CourseTypeHelper.isTest(course):
        return $localize`:@@global_distributable_subtype_test:Test`;
      case CourseTypeHelper.isTodo(course):
        return $localize`:@@global_distributable_subtype_todo:ToDo`;
      case CourseTypeHelper.isLearning(course):
        return $localize`:@@global_distributable_subtype_wbt:WBT`;
    }
    return null;
  }

  static isCertification(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isCourse(item) && (Number(item.courseType) === 1);
  }

  static isCourse(item: ImageableContentReference): boolean {
    return item && (item.objType === Core.DistributableType.lms_course);
  }

  static isDocument(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isCourse(item) && (Number(item.courseType) === 8);
  }

  static isLearning(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isCourse(item) && (Number(item.courseType) === 3);
  }

  static isLink(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isCourse(item) && (Number(item.courseType) === 10);
  }

  static isOfflineContent(item: ImageableContentReference): boolean {
    return item && (item.objType === Core.DistributableType.lms_offlineCnt);
  }

  static isOfflineDocument(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isOfflineContent(item) && (Number(item.courseType) === 6);
  }

  static isRecording(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isCourse(item) && (Number(item.courseType) === 16);
  }

  static isReadAndSign(
    contentType: Core.DistributableType,
    courseType: Core.CourseType,
    hasConfirmation: boolean,
  ): boolean {
    return (hasConfirmation === true) &&
      (contentType === Core.DistributableType.lms_course) &&
      (Number(courseType) === Core.CourseType.ScoDocument);
  }

  static isSeminar(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isOfflineContent(item) && (Number(item.courseType) === 7);
  }

  static isSideBySide(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isOfflineContent(item) && (Number(item.courseType) === 5);
  }

  static isSimpleConnect(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isCourse(item) && (Number(item.courseType) === 12);
  }

  static isTalk(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isOfflineContent(item) && (Number(item.courseType) === 4);
  }

  static isTest(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isCourse(item) && (Number(item.courseType) === 2);
  }

  static isVirtualConference(item: ImageableContentReference): boolean {
    return CourseTypeHelper.isOfflineContent(item) && (Number(item.courseType) === 11);
  }

  static isTodo(item: ImageableContentReference): boolean {
    return item?.courseType === 13;
  }
}
